









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Media } from '@/types/contentServerContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
@Component({
    name: 'ResponsiveIconWrap',
    components: {
        ResponsiveIcon
    }
})
export default class ResponsiveIconWrap extends Vue {
    @Prop()
    image!:Media;

    @Prop() altText!:string;
}
