































































import Vue from 'vue';
import Component from 'vue-class-component';
import BrandHeader from '@/project/layout/page-header/BrandHeader.vue';
import ScrollTrigger, { ScrollTriggerOptions } from '@/core/scroll/ScrollTrigger.vue';
import SearchCtrl from '@/project/search/SearchCtrl.vue';
import MiniBasket from '@/project/basket/MiniBasket.vue';
import authService from '@/core/auth/auth.service';
import ImpersonationBanner from '@/project/shared/impersonation/ImpersonationBanner.vue';
import loginFormOverlayService from '@/project/modals/overlays/LoginFormOverlay.service';
import mobileMenuOverlayService from '@/project/modals/overlays/MobileMenuOverlay.service';
import themeService from '@/project/app/themes/theme.service';
import { hexToRgbValues } from '@/core/color/color.service';
import serverContextService from '@/core/serverContext.service';
import appStore from '@/core/app/app.store';
import urlHelperService from '@/core/urlHelper.service';

@Component({
    components: { MiniBasket, SearchCtrl, ScrollTrigger, BrandHeader, ImpersonationBanner }
})
export default class MobilePageHeader extends Vue {
    searchOverlayAnchorElement: Element | null = null;
    searchShown: boolean = false;

    mounted() {
        this.searchOverlayAnchorElement = this.$refs['search-bar'] as Element;
    }

    get isLoggedIn() {
        return authService.isLoggedIn();
    }

    login() {
        loginFormOverlayService.show('login');
    }

    showMobileMenu() {
        mobileMenuOverlayService.show();
    }

    get logoDimensions(): {width: string, height: string } {
        return {
            height: '60',
            width: serverContextService.isOprema ? '100' : '60'
        };
    }

    get isInWhiteTheme() {
        return themeService.isInWhiteTheme;
    }

    get topHeaderStyle(): Partial<CSSStyleDeclaration> {
        const rgb = themeService.theme?.topColor && hexToRgbValues(themeService.theme.topColor);

        return {
            ...(rgb && { backgroundColor: `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, ${themeService.takeOverOpacity})` }),
            ...(themeService.theme?.topColor && { borderColor: themeService.theme.topColor })
        };
    }

    get searchElementStyle(): Partial<CSSStyleDeclaration> {
        return {
            backgroundColor: themeService.theme?.topColor,
            ...(themeService.theme?.topColor && { borderColor: themeService.theme.topColor })
        };
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }

    get scrollTriggerOptions(): ScrollTriggerOptions {
        return this.showBrands
            ? { 40: 'header-fixed' }
            : { 1: 'header-fixed' };
    }

    get showBrands(): boolean {
        // not logged in on frontpage: false, else true
        if (this.isOnFrontpage && this.isLoggedIn === false) {
            return false;
        }
        return true;
    }

    get isOnFrontpage(): boolean {
        return appStore.isFrontPage;
    }

    get becomeACustomerPage() {
        return serverContextService?.settings?.becomeACustomerPage;
    }

    get showBecomeACustomer(): boolean {
        // Only show for users who has never been logged in - and only if page exists
        return ((!this.isLoggedIn && !authService.hasLoggedInBefore()) && !!this.becomeACustomerPage);
    }

    clickShowSearch() {
        if (this.searchShown) {
            urlHelperService.setTerm(''); // will make search overlay go away
            this.searchShown = false;
        } else {
            this.searchShown = true;
        }
    }

    closeMobileSearch() {
        this.searchShown = false;
    }

    openMobileSearch() {
        this.searchShown = true;
    }

    get searchCtrlSectionClass(): string {
        return `${this.isInWhiteTheme ? '' : 'bg-white'} px-20 u-trans u-trans-transform fixed left-0 right-0 top-0 z-3`;
    }

    get searchCtrlSectionStyling(): Partial<CSSStyleDeclaration> | null {
        return this.isInWhiteTheme ? this.searchElementStyle : null;
    }
}
