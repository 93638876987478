import { AbstractModalService } from '@/core/modals/AbstractModalService';
import { ModalOptions } from '@/core/modals/ModalState';

export const SHOW_SITE_PICKER = 'Show_Site_Picker';

class SitePickerOverlayService extends AbstractModalService {
    public show(options?: Partial<ModalOptions>) {
        return this.showModal({
            component: () => import(
                /* webpackPrefetch: true */
                '@/project/site/SitePickerOverlay.vue'),
            componentAttrs: {
            },
            options: options
        });
    }
}

export default new SitePickerOverlayService();
