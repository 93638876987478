const ImageSizePlaceholder = '_size_';
enum ImageSizePlaceholderValues {
    'W86' = '86',
    'W172' = '172',
    'W200' = '200',
    'W300' = '300',
    'W400' = '400',
    'W700' = '700',
    'W1400' = '1400',
    'WHIRES' = 'hires'
}
enum BrandLogoSizePlaceholderValues {
    FullSize = '',
    'W125' = '_125px',
    'W250' = '_250px'
}
function formatImageUrl(imageUrl: string, size: string): string {
    return imageUrl.replace(ImageSizePlaceholder, size);
}

function defaultImageUrl(imageUrl: string): string {
    // '700' is one of the sizes PIM has provided (see complete list in ResponsiveProductImage.vue)
    return formatImageUrl(imageUrl, ImageSizePlaceholderValues.W700);
}
function formatBrandLogoUrl(imageUrl: string, size: BrandLogoSizePlaceholderValues): string {
    return imageUrl.replace(ImageSizePlaceholder, size);
}

export default {
    ImageSizePlaceholderValues,
    BrandLogoSizePlaceholderValues,
    formatImageUrl,
    defaultImageUrl,
    formatBrandLogoUrl
};
