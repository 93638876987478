import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { BrandViewModel } from '@/types/apiServerContract';
import serverContextService from '@/core/serverContext.service';
export interface BrandSearchPages {
    brandName: string;
    links: {
        displayName:string;
        pageName:string;
        categoryIds:string[];
        url: string;
    }[]
}
export class BrandController {
    private readonly brandUrlFactory = createUrlFactory('Brand');

    public async overview(categoryId: string | null): Promise<BrandViewModel> {
        return httpService.get(this.brandUrlFactory('BrandOverview'), {
            categoryId
        });
    }

    public async brandSearchPages(brandName: string): Promise<BrandSearchPages> {
        return httpService.getRaw(`${serverContextService.contentHost}/umbraco/api/BrandSearchPagesApi/BrandSearchPages?culture=${serverContextService.siteInfo.culture}&brandName=${brandName}&siteName=${serverContextService.siteName}`);
    }
}
