import Vue from 'vue';
import makeStoreDebugAccessible from '@/core/store/storeDebug';
import { BreadcrumbItem } from '@/types/contentServerContract';
import { startCase } from 'lodash';
import dictionaryService from '@/core/translation/dictionary.service';
import serverContextService from '@/core/serverContext.service';

interface IAppState {
    breadcrumb: BreadcrumbItem[];
    isTunnelView: boolean;
    isFrontPage: boolean;
    hideBreadcrumb: boolean;
    hideMobileSearch: boolean;
    disableContainer: boolean;
    maintenanceMode: boolean;
    desktopMainNavigationFixed: boolean;
    desktopMegaMenuShown: boolean;
}

class AppStore {
    private state: IAppState = Vue.observable({
        breadcrumb: [],
        isTunnelView: false,
        isFrontPage: location.pathname === serverContextService.siteInfo.url,
        hideBreadcrumb: false,
        hideMobileSearch: false,
        disableContainer: false,
        maintenanceMode: window.servercontext.maintenance,
        desktopMainNavigationFixed: false,
        desktopMegaMenuShown: false
    });

    constructor() {
        makeStoreDebugAccessible('appStore', this.state);
    }

    public get breadcrumb(): BreadcrumbItem[] {
        return this.state.breadcrumb;
    }

    public get breadcrumbPrettified(): BreadcrumbItem[] {
        const crumbs = this.state.breadcrumb ? [...this.state.breadcrumb] : [];

        // Prepend home
        crumbs.unshift({
            name: dictionaryService.get('FrontPage.Home'),
            url: '/',
            id: '0'
        });

        // Provide dictionary keys for "Brands" and "Department" pages
        crumbs.find(item => {
            if (item.name?.toLowerCase() === 'brands') {
                item.name = dictionaryService.get('Breadcrumbs.Brands');
            }

            if (item.name?.toLowerCase() === 'departments') {
                item.name = dictionaryService.get('Breadcrumbs.Departments');
            }
        });

        return crumbs;
    }

    public setBreadcrumb(breadcrumb: BreadcrumbItem[]): void {
        this.state.breadcrumb = [...breadcrumb];
    }

    public addBreadcrumb(breadcrumbItem: BreadcrumbItem) {
        this.state.breadcrumb.push(breadcrumbItem);
    }

    public initPage({ breadcrumb, isTunnelView = false, hideBreadcrumb = false }: {
        breadcrumb?: BreadcrumbItem[], isTunnelView?: boolean, hideBreadcrumb?: boolean
    }) {
        if (breadcrumb) {
            this.state.breadcrumb = breadcrumb;
        }

        this.state.isTunnelView = isTunnelView;
        this.state.hideBreadcrumb = hideBreadcrumb;
    }

    public setTunnelView(isTunnelView: boolean) {
        this.state.isTunnelView = isTunnelView;
    }

    public get isTunnelView(): boolean {
        return this.state.isTunnelView;
    }

    public setDisableContainer(disableContainer: boolean) {
        this.state.disableContainer = disableContainer;
    }

    public get isContainerDisabled(): boolean {
        return this.state.disableContainer;
    }

    public get isFrontPage(): boolean {
        return this.state.isFrontPage;
    }

    public set isFrontPage(isFront:boolean) {
        this.state.isFrontPage = isFront;
    }

    public setHideBreadcrumb(hide: boolean) {
        this.state.hideBreadcrumb = hide;
    }

    public get hideBreadcrumb(): boolean {
        return this.state.hideBreadcrumb;
    }

    public get hideMobileSearch(): boolean {
        return this.state.hideMobileSearch;
    }

    public set hideMobileSearch(hide: boolean) {
        this.state.hideMobileSearch = hide;
    }

    public getBreadcrumbFromRoute(route: string): BreadcrumbItem[] {
        const breadcrumb = route.split('/')
            .filter(x => x.length > 0)
            .map(x => {
                const name = decodeURIComponent(x.split(/^(.*?)(#|\?)/gi).filter(x => x.length > 0)[0]);
                const url = decodeURIComponent(route.substring(0, route.indexOf(x) + x.length));
                return ({
                    name: startCase(name),
                    url: url
                } as BreadcrumbItem);
            });

        return breadcrumb;
    }

    public get isMaintenanceMode(): boolean {
        return this.state.maintenanceMode;
    }

    public set isMaintenanceMode(maintenanceMode: boolean) {
        const bypassMaintenance = location.search.toLowerCase().includes('bypassMaintenance=926665286'.toLowerCase());
        if (!bypassMaintenance) {
            this.state.maintenanceMode = maintenanceMode;
        }
    }

    public set desktopMainNavigationFixed(fixed: boolean) {
        this.state.desktopMainNavigationFixed = fixed;
    }

    public get desktopMainNavigationFixed(): boolean {
        return this.state.desktopMainNavigationFixed;
    }

    public set desktopMegaMenuShown(show: boolean) {
        this.state.desktopMegaMenuShown = show;
    }

    public get desktopMegaMenuShown(): boolean {
        return this.state.desktopMegaMenuShown;
    }
}

export default new AppStore();
