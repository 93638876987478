import Vue from 'vue';
import makeStoreDebugAccessible from '@/core/store/storeDebug';
import {
    AddProductsToBasketRequest,
    BasketQuoteViewObject,
    BasketViewModel,
    LineItem,
    LineItemViewObject
} from '@/types/apiServerContract';
import Api from '@/project/http/Api.service';
import { PartialUpdateBasketRequest } from '@/project/http/controllers/basketController';
import { debounce } from 'lodash';
import authService, { LOGGED_IN, TOKEN_DATA_CHANGED_MARKET } from '@/core/auth/auth.service';
import bus from '@/core/bus';
import Deferred from '@/core/async/Deferred';
import loggingService from '@/core/logging.service';

type JobFunc = () => Promise<void>;

interface JobData {
    job: JobFunc,
    deferred: Deferred<void>
}

interface PartialLineItem {
    lineItemId: string;
    bidId?: number | null;
    quantity?: number;
    reference?: string;
    quoteId?: string;
}

function lineItemIdEqual(lineItemId: string, bidId: number | undefined | null, quoteId?: string | undefined): (lineItem: LineItemViewObject | LineItem) => boolean {
    return (lineItem) => {
        // Dont simplify this. bideId can be null or undefined or have value.
        return lineItemId === lineItem.lineItemId &&
            ((bidId === lineItem.bidId || (!bidId && !lineItem.bidId)) &&
                ((quoteId === lineItem.quoteId) || (!quoteId && !lineItem.quoteId)));
    };
}
function lineItemWidEqual(wid: string, bidId: number | undefined | null, quoteId?: string | undefined): (lineItem: LineItemViewObject | LineItem) => boolean {
    return (lineItem) => {
        // Dont simplify this. bideId can be null or undefined or have value.
        return wid === lineItem.wid &&
            ((bidId === lineItem.bidId || (!bidId && !lineItem.bidId)) &&
                ((quoteId === lineItem.quoteId) || (!quoteId && !lineItem.quoteId)));
    };
}

class BasketService {
    basketState: {
        basket: BasketViewModel | null,
        loaded: boolean
    } = Vue.observable({ basket: null, loaded: false });

    productQueue: LineItem[] = [];
    jobQueue: JobData[] = [];
    debouncedSendToServer = debounce(this.sendToServer, 500);
    isSendingToServer = false;
    hasPendingProductUpdates = false;
    updateLineItemPrices = false;
    updateDeliveryPrice = false;

    constructor() {
        makeStoreDebugAccessible('basket-service', this.basketState);
        if (authService.isLoggedIn()) {
            this.getBasket();
        }

        bus.on(LOGGED_IN, () => this.getBasket());
        bus.on(TOKEN_DATA_CHANGED_MARKET, () => this.getBasket());
    }

    public addProduct(wid: string, bidId: number | undefined | null, quoteId: string | undefined, quantity: number, updateLineItemPrices: boolean, updateDeliveryPrice: boolean): void {
        this.updateLineItemPrices = updateLineItemPrices;
        this.updateDeliveryPrice = updateDeliveryPrice;
        this.queueForServer({
            quantity,
            wid,
            reference: '',
            lineItemId: '',
            bidId: bidId,
            quoteId: '',
            isDuplicated: false
        });
    }

    public deltaUpdateQuantity(lineItemId: string, bidId: number | undefined | null, quoteId: string | undefined, delta: number, updateLineItemPrices: boolean, updateDeliveryPrice: boolean): void {
        this.updateLineItemPrices = updateLineItemPrices;
        this.updateDeliveryPrice = updateDeliveryPrice;
        const existing = this.getLineItem(lineItemId, bidId, quoteId);
        if (existing) {
            this.setQuantity(existing.lineItemId, bidId, quoteId, existing.quantity + delta, updateLineItemPrices, updateDeliveryPrice);
        }
    }

    public deltaUpdateQuantityByWid(wid: string, bidId: number | undefined | null, quoteId: string | undefined, delta: number, updateLineItemPrices: boolean, updateDeliveryPrice: boolean): void {
        this.updateLineItemPrices = updateLineItemPrices;
        this.updateDeliveryPrice = updateDeliveryPrice;
        const lineItems = this.getLineItemsByWid(wid, bidId, quoteId);
        if (lineItems.length === 0) {
            this.addProduct(wid, bidId, quoteId, delta, updateLineItemPrices, updateDeliveryPrice);
        } else if (lineItems.length === 1) {
            this.setQuantity(lineItems[0].lineItemId, bidId, quoteId, lineItems[0].quantity + delta, updateLineItemPrices, updateDeliveryPrice);
        } else {
            // Can't update quantity based on wid if multiple lineItems exist
        }
    }

    public setQuantity(lineItemId: string, bidId: number | undefined | null, quoteId: string | undefined, quantity: number, updateLineItemPrices: boolean, updateDeliveryPrice: boolean): void {
        this.updateLineItemPrices = updateLineItemPrices;
        this.updateDeliveryPrice = updateDeliveryPrice;
        this.updateLineItemIntern({
            lineItemId,
            bidId,
            quoteId,
            quantity
        });
    }

    public setQuantityByWid(wid: string, bidId: number | undefined | null, quoteId: string | undefined, quantity: number, updateLineItemPrices: boolean, updateDeliveryPrice: boolean): void {
        this.updateLineItemPrices = updateLineItemPrices;
        this.updateDeliveryPrice = updateDeliveryPrice;

        const lineItems = this.getLineItemsByWid(wid, bidId, quoteId);

        if (lineItems.length === 1) {
            this.updateLineItemIntern({
                lineItemId: lineItems[0].lineItemId,
                bidId,
                quoteId,
                quantity
            });
        }
    }

    public updateLineItemReference(lineItemId: string, bidId: number | undefined, quoteId: string | undefined, reference: string): void {
        this.updateLineItemIntern({
            lineItemId,
            bidId,
            quoteId,
            reference
        });
    }

    public isProductInBasket(wid: string, bidId: number | undefined, quoteId: string | undefined): boolean {
        // console.log('isProductInBasket', this.getLineItemsByWid(wid, bidId, quoteId), bidId, this.getLineItemsByWid(wid, bidId, quoteId).length > 0);
        return this.getLineItemsByWid(wid, bidId, quoteId).length > 0;
    }

    public isProductDuplicated(wid: string, bidId: number | undefined, quoteId: string | undefined): boolean {
        // console.log('isProductDuplicated', this.getLineItemsByWid(wid, bidId, quoteId), this.getLineItemsByWid(wid, bidId, quoteId).length > 1);
        return this.getLineItemsByWid(wid, bidId, quoteId).length > 1;
    }

    public getQuantity(lineItemId: string, bidId: number | undefined, quoteId: string | undefined): number {
        return this.getLineItem(lineItemId, bidId, quoteId)?.quantity || 0;
    }

    public getQuantityByWid(wid: string, bidId: number | undefined, quoteId: string | undefined): number {
        const lineItems = this.getLineItemsByWid(wid, bidId, quoteId);
        if (lineItems.length === 0) return 0;

        return lineItems.reduce((a, b) => a + b.quantity, 0);
    }

    public get lineItems(): LineItemViewObject[] {
        return this.basketState?.basket?.lineItems ?? [];
    }

    public async clearBasket(): Promise<void> {
        if (this.basketState.basket?.quotes) {
            this.basketState.basket.quotes = [];
        }

        if (this.basketState.basket?.lineItems) {
            // Temporary local change before updated basket comes back
            this.basketState.basket.lineItems = [];
            this.productQueue = [];
        }

        return this.executeAsyncInSerial(async() => {
            this.basketState.basket = await Api.basket.clear();
        });
    }

    public async removeLineItem(lineItemId: string): Promise<void> {
        const existing = this.basketState.basket?.lineItems.find(lineItem => lineItem.lineItemId === lineItemId);
        if (!existing) return;
        this.updateLineItemPrices = true;
        this.updateDeliveryPrice = true;
        this.updateLineItemIntern({
            lineItemId: existing.lineItemId,
            bidId: existing.bidId,
            quantity: 0
        });
    }

    public async duplicateLineItem(lineItemId: string): Promise<void> {
        const existing = this.basketState.basket?.lineItems.find(lineItem => lineItem.lineItemId === lineItemId);
        if (!existing) return;
        this.updateLineItemPrices = true;
        this.updateDeliveryPrice = true;
        this.makeTemporaryLocalAdd(existing);
        this.queueForServer({
            wid: existing.wid,
            quantity: 1,
            reference: '',
            lineItemId: '',
            bidId: existing.bidId,
            quoteId: '',
            isDuplicated: true
        });
    }

    public get basket(): BasketViewModel | null {
        return this.basketState.basket;
    }

    public async addMultipleItemsToBasket(request: AddProductsToBasketRequest): Promise<void> {
        return this.executeAsyncInSerial(async() => {
            this.basketState.basket = await Api.basket.addMultipleItemsToBasket(request);
        });
    }

    public async updateBasket(update: PartialUpdateBasketRequest): Promise<void> {
        return this.executeAsyncInSerial(async() => {
            this.basketState.basket = await Api.basket.update(update);
        });
    }

    public async recalcBasket(): Promise<void> {
        return this.executeAsyncInSerial(async() => {
            this.basketState.basket = await Api.basket.updateDefault();
        });
    }

    public forceGetBasket() {
        this.getBasket();
    }

    public get basketWithCommittedLineItems(): BasketViewModel | null {
        const basket = this.basket;
        if (!basket) return basket;

        const committedLineItems = basket.lineItems.filter(l => !this.isTemporaryLineItemId(l.lineItemId));

        const commitedQuotes = basket.quotes.map(x => ({
            ...x,
            lineItems: x.lineItems.filter(l => !this.isTemporaryLineItemId(l.lineItemId))
        } as BasketQuoteViewObject));

        return {
            ...basket,
            lineItems: committedLineItems,
            quotes: commitedQuotes
        };
    }

    public get hasPendingServerUpdates(): boolean {
        return this.hasPendingProductUpdates;
    }

    public async addQuote(quoteId: string, shippingMethodLocked: boolean): Promise<void> {
        this.basketState.basket = await Api.basket.addQuote({ quoteId: quoteId, shippingMethodLocked: shippingMethodLocked });
        this.basketState.basket!.shippingMethodLocked = shippingMethodLocked;
    }

    public removeQuote(quoteId: string): Promise<void> {
        if (!this.basketState.basket) {
            throw new Error('Basketstate is undefined');
        }

        this.basketState.basket.quotes.splice(this.basketState.basket.quotes.findIndex(x => x.quoteId === quoteId), 1);

        return this.executeAsyncInSerial(async() => {
            this.basketState.basket = await Api.basket.removeQuote(quoteId);
        });
    }

    public isLineItemDuplicated(wid: string, bidId: number | undefined | null, quoteId: string | undefined) {
        return this.getLineItemsByWid(wid, bidId, quoteId).length > 1;
    }

    public quoteInBasket(quoteId: string): boolean {
        return !!(this.basketState.basket?.quotes?.find(q => q.quoteId === quoteId));
    }

    public noOfBasketItems(): number {
        const normalLineItemsCnt = this.basketState.basket?.lineItems?.reduce((previousValue, lineItem: LineItemViewObject) => {
            return previousValue + lineItem.quantity;
        }, 0) ?? 0;
        const quoteLineItemsCnt = this.basketState.basket?.quotes?.reduce((previousOuterValue, quote: BasketQuoteViewObject) => {
            return previousOuterValue + quote.lineItems.reduce((previousValue, lineItem: LineItemViewObject) => {
                return previousValue + lineItem.quantity;
            }, 0) ?? 0;
        }, 0) ?? 0;
        return normalLineItemsCnt + quoteLineItemsCnt;
    }

    public get isBasketLoaded() {
        return this.basketState.loaded;
    }

    private isTemporaryLineItemId(lineItemId: string | undefined): boolean {
        return !lineItemId;
    }

    private updateLineItemIntern(partialLineItem: PartialLineItem): void {
        const existing = this.getLineItem(partialLineItem.lineItemId, partialLineItem.bidId, partialLineItem.quoteId);
        if (!existing) {
            throw new Error('Tried to update for non-existing line-item. ID: ' + partialLineItem.lineItemId);
        }
        this.makeTemporaryLocalUpdate(partialLineItem.lineItemId, partialLineItem.bidId, partialLineItem.quoteId, partialLineItem.quantity);

        const lineItem = {
            ...existing,
            ...partialLineItem
        };

        this.queueForServer({
            lineItemId: lineItem.lineItemId,
            wid: lineItem.wid,
            quantity: lineItem.quantity,
            reference: lineItem.reference,
            bidId: lineItem.bidId,
            quoteId: lineItem.quoteId,
            isDuplicated: false
        });
    }

    private queueForServer(lineItem: LineItem) {
        const existingIx = this.productQueue.findIndex(lineItem.lineItemId ? lineItemIdEqual(lineItem.lineItemId, lineItem.bidId, lineItem.quoteId) : lineItemWidEqual(lineItem.wid, lineItem.bidId, lineItem.quoteId));
        if (existingIx !== -1) {
            this.productQueue[existingIx] = lineItem;
        } else {
            this.productQueue.push(lineItem);
        }
        this.hasPendingProductUpdates = true;
        if (lineItem.lineItemId) {
            // If product and lineItemId is known to basket, debounce changes.
            this.debouncedSendToServer();
        } else {
            // If product is not in basket, fire XHR now. This avoids timing issue with lineItemId being slow to update https://dev.azure.com/eet-project/eet-drift/_workitems/edit/3546
            this.sendToServer();
        }
    }

    private sendToServer() {
        this.executeAsyncInSerial(async() => {
            if (this.productQueue.length === 0) return;

            const toSend: LineItem[] = [];

            for (let i = 0; i < this.productQueue.length; i++) {
                const product = this.productQueue[i];
                toSend.push({
                    lineItemId: product.lineItemId,
                    wid: product.wid,
                    quantity: product.quantity,
                    reference: product.reference,
                    bidId: product.bidId,
                    quoteId: product.quoteId,
                    isDuplicated: product.isDuplicated
                });
            }

            this.productQueue = [];

            const basket = await Api.basket.addOrUpdateLineItems({
                lineItems: toSend,
                updateLineItemPrices: this.updateLineItemPrices,
                updateDeliveryPrice: this.updateDeliveryPrice
            });

            this.updateDeliveryPrice = false;
            this.hasPendingProductUpdates = false;
            this.basketState.basket = basket;
        });
    }

    private executeAsyncInSerial(func: JobFunc): Promise<void> {
        const deferred = new Deferred<void>();
        this.jobQueue.push({
            deferred,
            job: func
        });
        this.doExecuteAsyncInSerial();
        return deferred.promise;
    }

    private doExecuteAsyncInSerial() {
        if (this.isSendingToServer || this.jobQueue.length === 0) return;

        this.isSendingToServer = true;
        const nextJob = this.jobQueue.shift();
        nextJob!.job()
            .finally(() => {
                nextJob!.deferred.resolve();
                this.isSendingToServer = false;
                if (this.jobQueue.length > 0) {
                    setTimeout(() => this.doExecuteAsyncInSerial());
                }
            });
    }

    private makeTemporaryLocalAdd(existingProduct: LineItemViewObject) {
        if (!this.basketState.basket) return; // Don't have a basket yet

        // Add fake line-item until basket comes back
        const fakeLineItem: LineItemViewObject = {
            ...existingProduct,
            lineItemId: Math.random().toString(),
            quantity: 1,
            reference: '',
            unitPrice: {},
            total: {}
        } as LineItemViewObject;
        this.basketState.basket.lineItems.push(fakeLineItem);
    }

    private makeTemporaryLocalUpdate(lineItemId: string, bidId: number | undefined | null, quoteId: string | undefined, quantity?: number) {
        // Temporary local change before updated basket comes back
        if (quantity == null) return; // Could be reference update without quantity
        if (!this.basketState.basket) return; // Don't have a basket yet

        const existingProductIndex = this.getLineItemIndex(lineItemId, bidId, quoteId);
        if (existingProductIndex > -1) {
            if (quantity === 0) {
                // Remove product if quantity is 0
                this.basketState.basket.lineItems.splice(existingProductIndex, 1);
            } else {
                // Update existing product locally
                const updatedExisting = { ...this.basketState.basket.lineItems[existingProductIndex], quantity };
                Vue.set(this.basketState.basket.lineItems, existingProductIndex, updatedExisting);
            }
        }
    }

    private getLineItemIndex(lineItemId: string, bidId: number | undefined | null, quoteId: string | undefined): number {
        return this.basketState?.basket?.lineItems.findIndex(lineItemIdEqual(lineItemId, bidId, quoteId)) ?? -1;
    }

    private getLineItemsByWid(wid: string, bidId: number | undefined | null, quoteId: string | undefined): LineItemViewObject[] {
        return this.basket?.lineItems?.filter(lineItemWidEqual(wid, bidId, quoteId)) || [];
    }

    private getLineItem(lineItemId: string, bidId: number | undefined | null, quoteId: string | undefined): LineItemViewObject | undefined {
        let lineItem: LineItemViewObject | undefined;

        if (quoteId) {
            lineItem = this.basketState?.basket?.quotes.find(x => x.quoteId === quoteId)?.lineItems.find(lineItemIdEqual(lineItemId, bidId, quoteId));
        } else {
            lineItem = this.basketState?.basket?.lineItems.find(lineItemIdEqual(lineItemId, bidId));
        }

        return lineItem;
    }

    private async getBasket() {
        try {
            this.basketState.basket = await Api.basket.get();
        } catch (e) {
            loggingService.error(e);
        } finally {
            this.basketState.loaded = true;
        }
    }
}

export default new BasketService();
