




























import { Vue, Component, Watch } from 'vue-property-decorator';
import PageHeader from '@/project/layout/page-header/PageHeader.vue';
import PageFooter from '@/project/layout/page-footer/PageFooter.vue';
import BreadCrumb from '@/project/layout/breadcrumb/Breadcrumb.vue';
import appStore from '@/core/app/app.store';
import ErrorToaster from '@/core/messages/ErrorToaster.vue';
import MyAccountModule from '@/project/app/client-pages/my-account/MyAccountModule.vue';
import ModalsContainer from '@/project/modals/ModalsContainer.vue';
import SitePickerModule from '@/project/site/SitePickerModule.vue';
import AuthenticationModule from '@/project/auth/AuthenticationModule.vue';
import themeService from '@/project/app/themes/theme.service';
import authService from '@/core/auth/auth.service';
import maintenancePageOverlayService from '@/project/modals/overlays/MaintenancePageOverlay.service';
import cookieService from '@/core/cookie.service';
import siteService from '@/project/site/site.service';
import { PageName } from '@/router/routes/constants';

@Component({
    components: {
        ErrorToaster,
        PageHeader,
        PageFooter,
        BreadCrumb,
        ModalsContainer,
        SitePickerModule,
        AuthenticationModule,
        MyAccountModule
    }
})
export default class App extends Vue {
    pageTypeName: string = '';
    get isTunnelView(): boolean {
        return appStore.isTunnelView;
    }

    get isTakeOverEnabled() {
        return themeService.isTakeOverEnabledAndLoaded;
    }

    get hideMobileBrands(): boolean {
        // not logged in and on frontpage: true, else false
        if (this.isOnFrontpage && this.isLoggedIn === false) {
            return true;
        }
        return false;
    }

    get isLoggedIn(): boolean {
        return authService.isLoggedIn();
    }

    get isOnFrontpage(): boolean {
        return appStore.isFrontPage;
    }

    get isInMaintenanceMode(): boolean {
        return appStore.isMaintenanceMode;
    }

    @Watch('isInMaintenanceMode', { immediate: true })
    isInMaintenanceModeChange(isInMaintenanceMode: boolean) {
        if (isInMaintenanceMode) {
            maintenancePageOverlayService.show();
        }
    }

    get isProductDetailsPage(): boolean {
        return this.pageTypeName === PageName.PRODUCT_DETAILS;
    }

    get isSearchPage(): boolean {
        return this.pageTypeName === PageName.SEARCH;
    }

    get isProductListPage(): boolean {
        return this.pageTypeName === PageName.CATEGORY || this.pageTypeName === PageName.CATEGORY_2;
    }

    get showBreadcrumb(): boolean {
        return !appStore.hideBreadcrumb;
    }

    @Watch('$route', { immediate: true })
    onRouteChange() {
        this.pageTypeName = this.$router.currentRoute.name || '';
    }

    mounted() {
        cookieService.set({ name: 'latestSiteVisited', value: siteService.getCulture() || undefined, exdays: 180, domain: location.hostname, path: '/' });
    }
}
