
























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { isValidHexColor, RGBToHSLObject, siteThemeColorBasedOnBackgroundColor } from '@/core/color/color.service';
import SmartParagraph from '@/core/ui-components/SmartParagraph.vue';
import { isDevMode } from '@/core/environmentService';
import serverContextService from '@/core/serverContext.service';
import tailwind from '@/styling/tailwind.config';

@Component({
    components: { SmartParagraph }
})
export default class CallToAction extends Vue {
    @Prop({ type: String, required: false })
    text!: string;

    @Prop({ type: [String, Object], required: false })
    href!: RawLocation;

    @Prop({ type: String, required: false, default: '' })
    icon!: string;

    @Prop({ type: Boolean, required: false, default: false })
    iconIsAfter!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    iconFill!:boolean;

    @Prop({ type: String, required: false })
    target!: string;

    @Prop({ type: Boolean, required: false, default: false })
    autofocus!: boolean;

    @Prop({ type: String, required: false })
    hexColor!: string;

    @Prop({ type: Boolean, required: false, default: false })
    dropdown!: boolean;

    @Prop({ type: String, default: 'button' })
    type!: string;

    @Prop({ type: Number, default: -1 })
    maxCharacters!: number;

    @Prop({
        type: Function,
        default: undefined
    })
    onClickEvent!: () => {};

    dropdownActive: boolean = false;

    toggleDropdown() {
        this.dropdownActive = !this.dropdownActive;
    }

    openDropdown() {
        this.dropdownActive = true;
    }

    closeDropdown() {
        this.dropdownActive = false;
    }

    get hasIconBefore(): boolean {
        return this.icon !== '' && !this.iconIsAfter;
    }

    get hasIconAfter(): boolean {
        return this.icon !== '' && this.iconIsAfter;
    }

    handleClick() {
        if (this.onClickEvent) {
            this.onClickEvent();
        }
        this.$emit('click');

        if (this.dropdown && !this.dropdownActive) {
            this.openDropdown();
        }
    }

    get customBackgroundColor(): Partial<CSSStyleDeclaration> {
        if (!this.hexColor) return {};
        if (!isValidHexColor(this.hexColor)) return {};

        return {
            backgroundColor: this.hexColor
        };
    }

    mounted() {
        this.setForegroundAndHoverColor();
        if (this.$attrs?.to?.length && isDevMode()) {
            console.warn('Don\'t use attribute TO on <CallToAction>, use HREF instead.');
        }
    }

    setForegroundAndHoverColor() {
        const elementStyle = (this.$el as HTMLElement).style;
        const rawBackgroundColor = getComputedStyle(this.$el).backgroundColor;
        const [r, g, b] = rawBackgroundColor.replace(/[^\d,]/g, '').split(',');
        const { h, s, l } = RGBToHSLObject(r, g, b);

        // Hover and active - adjust luminosity
        const hoverL = l * 0.85;
        elementStyle.setProperty('--hover-active-color', `hsl(${h}, ${s}%, ${hoverL}%)`);
        const customBackgroundColor = this.customBackgroundColor?.backgroundColor?.toLowerCase();

        // Foreground
        if (customBackgroundColor) {
            const colorTheme = siteThemeColorBasedOnBackgroundColor(customBackgroundColor, tailwind, this.isOprema);
            elementStyle.color = colorTheme.textColor;
        }
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }
}
