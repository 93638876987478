import { pageResolver } from '@/core/app/componentResolver.service';
import FrontPage from './FrontPage.vue';
import ContentPage from './ContentPage.vue';
import ProductNotFoundPage from './ProductNotFoundPage.vue';
const NewsArticlePage = () => import(/* webpackChunkName: 'news' */'./NewsArticlePage.vue');
const NewsPage = () => import(/* webpackChunkName: 'news' */'./NewsPage.vue');
const CampaignOverviewPage = () => import('./CampaignOverviewPage.vue');
const BecomeACustomerPage = () => import('./BecomeACustomerPage.vue');
const RackConfiguratorPage = () => import('@/project/app/client-pages/rack-configurator/RackConfiguratorPage.vue');

// Content pages
pageResolver.register('frontpage', FrontPage);
pageResolver.register('newsPage', NewsPage, { updateOnQueryChange: true });
pageResolver.register('campaigns', CampaignOverviewPage, { updateOnQueryChange: true });
pageResolver.register('newsArticlePage', NewsArticlePage);
pageResolver.register('contentPage', ContentPage);
pageResolver.register('product404Page', ProductNotFoundPage);
pageResolver.register('becomeACustomer', BecomeACustomerPage);
pageResolver.register('rackConfiguratorPage', RackConfiguratorPage);

// No fallback - we prefer redirecting to 404
// Except in preview mode, just use contentpage
if (window.servercontext.preview) {
    pageResolver.registerFallback(ContentPage);
}
