import domService from '@/core/dom/dom.service';
import HttpStatus from 'http-status-codes';
import { isUrlAsExpected } from '@/core/urlStringHelper.isURLsEqual';

const allBots = /(headlesschrome|prerender|prerender.cloud|googlebot|googlebot-mobile|duckduckbot|bingbot|baiduspider|facebookexternalhit|twitterbot|yandex|rogerbot|linkedinbot|embedly|bufferbot|quora link preview|showyoubot|outbrain|bot|crawler|80legs|ia_archiver|voyager|curl|wget|yahoo! slurp|mediapartners-google|prtg|monitis|demandbase|shopit|spider)/i;
const prerenderBots = /(prerender|prerender.cloud)/i;

function uaIsBot() {
    const userAgent = navigator.userAgent;
    const result = allBots.test(userAgent);
    return result;
}

function uaIsPrerender() {
    const userAgent = navigator.userAgent;
    const result = prerenderBots.test(userAgent);
    return result;
}

// Handle if location.pathname is not the same a category url from elastic or umbraco. Query strings in url are ignored in comparison, just the path name is relevant.
function setPrerenderRedirectUrlsIfLocationIsWrong(expectedUrl: string | null) {
    if (!expectedUrl || !uaIsPrerender()) return undefined;
    const expectedUrlObject = new URL(expectedUrl, location.origin);
    const locationUrlObject = new URL(location.pathname, location.origin);

    const pathIsAsExpected = isUrlAsExpected(expectedUrlObject.toString(), locationUrlObject.toString());

    if (!pathIsAsExpected) {
        domService.setPrerenderHeader(`Location: ${expectedUrlObject.toString()}`);
        domService.setPrerenderStatusCode(HttpStatus.MOVED_PERMANENTLY);
    }
}

export default {
    uaIsBot,
    uaIsPrerender,
    setPrerenderRedirectUrlsIfLocationIsWrong
};
