import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    AdContentBlocksApiRequest,
    AdContentBlockViewModel,
    AdnuntiusPixelForwardRequest,
    BasketPageApiRequest,
    BrandLogosApiRequest,
    BrandLogoViewModel,
    CategoryItemViewModel,
    PartnerLogosApiRequest,
    PartnerLogoViewModel,
    ProductDetailsPageAdRequest,
    ProductListApiRequest,
    RequestBreakpoint
} from '@/types/apiServerContract';

export class PersonalizationController {
    private readonly urlFactory = createUrlFactory('Personalization');

    public async personalizedContent(adUnitIds: string[], currentUrl: string, contactKey: string, customerKey?: string): Promise<AdContentBlockViewModel[]> {
        return await httpService.post(this.urlFactory('PersonalizedContent'), {
            adUnitIds,
            currentUrl,
            contactKey,
            customerKey
        } as AdContentBlocksApiRequest);
    }

    public async categories(adUnitId: string, currentUrl: string, contactKey: string, customerKey?: string): Promise<CategoryItemViewModel[]> {
        return httpService.post(this.urlFactory('Categories'), {
            adUnitIds: [adUnitId],
            currentUrl,
            contactKey,
            customerKey
        } as AdContentBlocksApiRequest);
    }

    public async brandLogos(breakpoint: RequestBreakpoint, currentUrl: string, contactKey: string, customerKey?: string): Promise<BrandLogoViewModel[]> {
        return httpService.post(this.urlFactory('BrandLogos'), {
            breakpoint,
            currentUrl,
            contactKey,
            customerKey
        } as BrandLogosApiRequest);
    }

    public async recommendedBrands(brands: string[], currentUrl: string, contactKey: string, customerKey?: string): Promise<BrandLogoViewModel[]> {
        return httpService.post(this.urlFactory('RecommendedBrands'), {
            brands,
            currentUrl,
            contactKey,
            customerKey
        } as BrandLogosApiRequest);
    }

    public async partnerLogos(brandName: string, condition: string, department: string, mpnNumber: string, itemNo: string, pph: string, productCategory: string,
        productGroup: string, currentUrl: string, contactKey: string, customerKey?: string): Promise<PartnerLogoViewModel[]> {
        return httpService.post(this.urlFactory('PartnerLogos'), {
            brandName,
            condition,
            department,
            mpnNumber,
            itemNo,
            pph,
            productCategory,
            productGroup,
            currentUrl,
            contactKey,
            customerKey
        } as PartnerLogosApiRequest);
    }

    public async productListBanner(request: ProductListApiRequest): Promise<AdContentBlockViewModel> {
        return httpService.post(this.urlFactory('ProductListBanner'), request);
    }

    public async basketPageBanner(request: BasketPageApiRequest): Promise<AdContentBlockViewModel> {
        return httpService.post(this.urlFactory('BasketPageBanner'), request);
    }

    public async productDetailBanner(request: ProductDetailsPageAdRequest): Promise<AdContentBlockViewModel> {
        return httpService.post(this.urlFactory('productdetailspagebanner'), request);
    }

    public async forwardAdnuntiusPixelRequest(requests: AdnuntiusPixelForwardRequest): Promise<void> {
        return httpService.post(this.urlFactory('forwardAdnuntiusPixelRequest'), requests);
    }
}
