import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { PriceSimpleViewModel, PriceDetailedViewModel } from '@/types/apiServerContract';

export class PriceController {
    private readonly urlFactory = createUrlFactory('Price');

    public async priceSimple(itemIds: string[], cancelKey?: string): Promise<PriceSimpleViewModel> {
        return httpService.get(this.urlFactory('Simple'), {
            itemIds
        }, cancelKey);
    }

    public async priceDetailed(itemIds: string[], cancelKey?: string): Promise<PriceDetailedViewModel> {
        return httpService.get(this.urlFactory('Detailed'), {
            itemIds
        }, cancelKey);
    }
}
