import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { NotificationListViewModel } from '@/types/apiServerContract';

export class NotificationsController {
    private readonly urlFactory = createUrlFactory('Notifications');

    public async notifyOnStockReplenish(itemId: string): Promise<void> {
        return httpService.get(this.urlFactory('NotifyOnStockReplenish'), {
            itemId
        });
    }

    public async getNotifications(): Promise<NotificationListViewModel> {
        return httpService.get(this.urlFactory('Notifications'));
    }
}
