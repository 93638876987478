








































import { Vue, Component, Prop } from 'vue-property-decorator';
import TranslationWithLinks, { TranslationLink } from '@/core/translation/translation-with-links.vue';
/*
Inspired by this: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */
@Component({
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    components: { TranslationWithLinks }
})
export default class InputCheckbox extends Vue {
    @Prop({ type: String, default: '' })
    label!: string;

    @Prop(String)
    htmlLabel!: string;

    @Prop(String)
    name!: string;

    @Prop({ type: Array })
    links!: TranslationLink[];

    @Prop({ default: true })
    value;

    @Prop({ default: true })
    trueValue;

    @Prop({ default: false })
    falseValue;

    @Prop({ default: false })
    modelValue!: boolean | Array<string>;

    @Prop({
        type: [Object, String],
        default: ''
    })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: Boolean, default: false })
    withBorder!: boolean;

    @Prop({ type: Boolean, default: false })
    srOnly!: boolean;

    @Prop({ type: Boolean, default: false })
    styleSwitch!: boolean; // Shows "swipe" iOS style checkbox

    @Prop({ type: Boolean, default: false })
    solidBackground!: boolean;

    internalValue: string | object | number | null = null;

    get id(): string {
        return this.name + Math.random();
    }

    updateValue(event, errors) {
        const isChecked = event.target.checked;

        if (this.modelValue instanceof Array) {
            const newValue: any = [...this.modelValue];

            if (isChecked) {
                newValue.push(this.value);
            } else {
                newValue.splice(newValue.indexOf(this.value), 1);
            }

            // this.$emit('change', newValue);
            this.$emit('change', newValue);
        } else {
            // this.$emit('change', isChecked ? this.trueValue : this.falseValue);
            this.$emit('change', isChecked ? this.trueValue : this.falseValue);
        }
    }

    get shouldBeChecked() {
        if (this.modelValue instanceof Array) {
            return this.modelValue.includes(this.value);
        }
        // Note that `true-value` and `false-value` are camelCase in the JS
        return this.modelValue === this.trueValue;
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { change, ...listeners } = this.$listeners;
        return listeners;
    }

    created() {
        this.internalValue = this.value;
    }
}
