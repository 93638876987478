import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { StockInformationViewModel } from '@/types/apiServerContract';

export class StockController {
    private readonly urlFactory = createUrlFactory('Stock');

    public async stockProduct(itemId: string, cancelKey?: string): Promise<StockInformationViewModel> {
        return httpService.get(this.urlFactory('Product'), {
            itemId
        }, cancelKey);
    }

    public async stockProducts(itemIds: string[], cancelKey?: string): Promise<StockInformationViewModel> {
        return httpService.get(this.urlFactory('Products'), {
            itemIds
        }, cancelKey);
    }

    // Use a POST request, as the Series table can be too large for a GET request (100+ products in one series)
    public async stockProductsByPost(itemIds: string[], cancelKey?: string): Promise<StockInformationViewModel> {
        return httpService.post(this.urlFactory('Products'), itemIds, undefined, cancelKey);
    }
}
