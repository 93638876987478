import './public-path';
import './styling/main.less';
import Vue from 'vue';

import './core/config';
import { router } from './router';
import './project';
import App from './App.vue';

Vue.config.productionTip = false;

const vueDebugEnabled = location.search.includes('$enable-vue-devtools$=true');
if (vueDebugEnabled) {
    Vue.config.devtools = true;
}

// Vue.config.performance = true;

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    render: h => h(App)
});
