import siteService from '@/project/site/site.service';

const locale = siteService.getCulture();

export function localeNumberFilter(number: number | string) {
    if (typeof number !== 'number') return number;
    if (!locale) return number;

    return number.toLocaleString(locale);
}
export default {
    install(Vue) {
        Vue.prototype.$localeNumberFilter = { get: localeNumberFilter };
    }
};

declare module 'vue/types/vue' {
    interface Vue {
        $localeNumberFilter: { get: (obj?: number | string) => string };
    }
}
