const sizes: Array<number> = [];
let _baseUrl: string = '';

export function addSize(width: number) {
    sizes.push(width);
}

export function setBaseUrl(baseUrl: string) {
    _baseUrl = baseUrl;
}

function getBaseUrl() {
    return _baseUrl;
}

function getSizes() {
    return sizes.length ? sizes : [320, 640, 1024, 1600];
}

export default {
    getSizes,
    getBaseUrl
};
