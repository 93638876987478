























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InputFile extends Vue {
    @Prop({ required: true })
    value!: string;

    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: String, default: '' })
    infoLabel!: string;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: false })
    autofocus!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: String, required: false, default: '' })
    placeholder!: string;

    @Prop({ type: Boolean, required: false, default: false })
    invalid!: boolean;

    $refs!: {
        provider: any
    }

    get id() {
        return this.name + Math.random();
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, change, ...listeners } = this.$listeners;
        return listeners;
    }

    get listenersForFakeField() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }

    get labelText(): string {
        return this.isRequired ? `${this.label} *` : this.label;
    }

    get isRequired(): boolean {
        return 'required' in this.$attrs ||
            (typeof this.rules === 'string' && this.rules.includes('required')) ||
            (typeof this.rules === 'object' && !!(this.rules as any).required);
    }

    async changedInput(event, validationProviderValidate) {
        const validationResult = await validationProviderValidate;
        console.log('fileInput', event);
        if (validationResult.valid) {
            const fileList = event.target.files;
            const files: unknown[] = [];
            Array
                .from(Array(fileList.length).keys())
                .map(x => {
                    files.push({ file: fileList[x], fileName: fileList[x].name });
                });
            this.$emit('change', files);
        } else {
            console.log('invalid file!', event, validationResult);
        }
    }

    changedFakeInput(event) {
        console.log('fake field input', event.target.value);
        this.$emit('input', event.target.value);
    }
}
