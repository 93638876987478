import { RouteConfig } from 'vue-router';

export const ContentRoute: RouteConfig = {
    path: '*', // All non-client side routes we assume first to be a content route
    name: 'content',
    component: () => import('@/project/app/content-pages/ContentPageRenderer.vue'),
    meta: {
        isContent: true,
        isPublic: true
    }
};
