




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import PlusAccept from '@/project/basket/PlusAccept.vue';
import MinusCancel from '@/project/basket/MinusCancel.vue';

@Component({
    components: { PlusAccept, MinusCancel }
})
export default class InputNumber extends Vue {
    @Prop({ type: Number, required: true })
    value!: number;

    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: false })
    autofocus!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: Number, required: false, default: 1 })
    minValue!: number;

    @Prop({ type: Number, required: false, default: Infinity })
    maxValue!: number;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    editMode = false;
    internalValue: number = this.value;

    get minusDisabled(): boolean {
        return this.disabled || (this.value <= this.minValue && !this.editMode);
    }

    get plusDisabled(): boolean {
        return this.disabled || (this.value >= this.maxValue && !this.editMode);
    }

    inputFocus() {
        this.editMode = true;
        this.$refs.inputElem.select();
    }

    inputBlur() {
        // Need to delay until a possible click on accept/cancel arrives. This comes after blur from inputfield...
        setTimeout(() => {
            if (this.editMode) {
                // All blur events exept cancel will set quantity
                this.setQuantity();
                this.done();
            }
        }, 250);
    }

    inputEnterEscKeyUp(event) {
        if (event.key === 'Enter') {
            this.setQuantity();
        }
        this.$refs.inputElem.blur();
        this.done();
    }

    done() {
        this.editMode = false;
    }

    deltaAdjustQuantity(delta: number) {
        this.setQuantity(delta);
    }

    isAllowedValue(val: number): boolean {
        return val >= this.minValue && val <= this.maxValue;
    }

    setQuantity(delta: number = 0) {
        const inputValue = this.internalValue || this.value || this.minValue;
        let value = parseInt(inputValue + '', 10) + delta;
        if (!this.isAllowedValue(value)) {
            value = value < this.minValue ? this.minValue : value > this.maxValue ? this.maxValue : this.value;
        }
        this.internalValue = value;
        this.$emit('input', value);
        this.done();
    }

    get id() {
        return this.name + Math.random();
    }

    get hasValue(): boolean {
        return this.value != null;
    }

    get isAtMax() {
        return this.value === this.maxValue;
    }

    $refs!: {
        // Just to have type...
        inputElem: HTMLInputElement;
    };
}
