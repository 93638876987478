import Vue from 'vue';
import ResponsiveIconWrap from '@/project/shared/ResponsiveIconWrap.vue';
import ModalOverlay from '@/project/modals/ModalOverlay.vue';
import ProductTile from '@/project/product/product-tile/ProductTile.vue';

// Global components that we want to use on multiple pages
Vue.component('ResponsiveIconWrap', ResponsiveIconWrap);
Vue.component('ModalOverlay', ModalOverlay);

// Due to recursiveness
Vue.component('ProductTile', ProductTile);
