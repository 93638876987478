













import { Vue, Prop, Component } from 'vue-property-decorator';
import { CampaignViewObject, ProductTileViewObject } from '@/types/apiServerContract';
import campaignService from '@/project/shared/campaign.service';

@Component
export default class ProductTileCampaign extends Vue {
    @Prop({ required: true })
    product!: ProductTileViewObject;

    get activeCampaign(): CampaignViewObject | null {
        return this.product.campaigns[0];
    }

    get showCampaignIcon() {
        return !!this.activeCampaign?.showCampaignIcon && !!this.activeCampaign?.description;
    }

    get campaignIconUrl() {
        return this.activeCampaign?.campaignIconUrl ? campaignService.rewriteCampaignIconUrl(this.activeCampaign.campaignIconUrl) : undefined;
    }

    get defaultCampaignIcon() {
        return this.campaignIconUrl ? 'splash-campaign-no-icon' : 'splash-campaign';
    }
}
