// const screens = require('../../styling/screens');
// We get sporadic runtime-errors on the above. Possible a webpack error. che.

/* REMEMBER TO KEEP IN SYNC WITH screens.js */

import { defineBreakpoints } from '@/core/responsive/breakpoints/breakpoints.service';
import { map } from 'lodash';

export const XS = 'xs';
export const MIN_XS = 'min-xs';
export const SM = 'sm';
export const MAX_SM = 'max-sm';
export const MIN_SM = 'min-sm';
export const MD = 'md';
export const MIN_MD = 'min-md';
export const MAX_MD = 'max-md';
export const LG = 'lg';
export const XL = 'xl';
export const MIN_LG = 'min-lg';

const screens = {
    [XS]: '0px', // Pure mobile
    [SM]: '415px', // Mobile and tablet vertical
    [MD]: '769px', // Up to and including old tablet horizontal
    [LG]: '1025px', // Desktop,
    [XL]: '1280px' // Wide desktop
};

/*

// Lets use tailwind config as breakpoint-src
// PREMISE: tailwind config has 'px' units

  Converts something like this:
    screens: {
        sm: '0',
        md: '640px',
        lg: '960px',
        xl: '1025px',
    },

   To something like this:
   {
       sm: { min: 0, max: 639 },
       md: { min: 640, max: 959 },
       lg: { min: 960, max: 1024 },
       xl: { min: 1025, max: 999999 },
   }
*/

function removeUnit(width: string): number {
    if (width.endsWith('px')) {
        return Number(width.slice(0, width.indexOf('p')));
    } else {
        return Number(width);
    }
}

const orderedNormalizedBreakpoints = map(screens, (min, bp) => {
    return { name: bp, min: removeUnit(min) };
}).sort((a, b) => a.min - b.min);

const breakpointArrayWithMax = map(orderedNormalizedBreakpoints, (bp, ix) => {
    let max;
    if (ix < orderedNormalizedBreakpoints.length - 1) {
        max = orderedNormalizedBreakpoints[ix + 1].min - 1;
    } else {
        max = 999999;
    }
    return { ...bp, ...{ max } };
});

const finalBreakpoints = breakpointArrayWithMax.reduce((acc, bp) => {
    acc[bp.name] = { min: bp.min, max: bp.max };
    return acc;
}, {});

defineBreakpoints(finalBreakpoints);
