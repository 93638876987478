











import { Component, Vue } from 'vue-property-decorator';
import impersonationStore from '@/project/shared/impersonation/impersonation.service';

@Component
export default class ImpersonationBanner extends Vue {
    get isImpersonating(): boolean {
        return impersonationStore.isImpersonating;
    }

    get impersonateeName(): string {
        return impersonationStore.impersonateeName;
    }

    endImpersonation(): void {
        impersonationStore.endImpersonation();
    }
}
