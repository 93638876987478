import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import viewportEvents, { Viewport } from '@/core/responsive/viewport/viewportEvents.service';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

Vue.directive('fontContentSync', {
    inserted(el:HTMLElement, binding: DirectiveBinding) {
        if (!binding.value) {
            throw new Error(`Pass an array with breakpoint tuples with their min, max in px.
                Should be ordered from highest breakpoint and down.
                Example: [
                    [MIN_LG, { min: 7, max: 34 }],
                    [MIN_MD, { min: 6, max: 22 }],
                    [MIN_XS, { min: 5, max: 18, maxLines: 3 }]
                ]`);
        }
        const activeSizes = binding.value.find(bpTuple => {
            return breakpointsState.isBreakpointActive(bpTuple[0]);
        });
        if (!activeSizes) return;
        const sizes = activeSizes[1];
        const maxLines = sizes.maxLines || 2;
        function getLinesCount(element: HTMLElement) {
            const prevLineHeight = element.style.lineHeight;
            const factor = 1000;
            element.style.lineHeight = factor + 'px';
            const height = element.getBoundingClientRect().height;
            element.style.lineHeight = prevLineHeight;

            return Math.floor(height / factor);
        }

        function autoSizeText(el: HTMLElement) {
            let fontSize: number = parseInt(getComputedStyle(el).fontSize.replace('px', ''));
            const lines = getLinesCount(el);
            if (!isNaN(fontSize) && !isNaN(lines) && lines > maxLines && fontSize > sizes.min) {
                fontSize--;
                el.style.fontSize = fontSize + 'px';
                autoSizeText(el);
            }
        }
        function initFontSize() {
            // Start at max font size and go down with 1 until min
            el.style.fontSize = sizes.max + 'px';
            autoSizeText(el);
        }
        const viewportEventHandler = function(viewport: Viewport) {
            if (!viewport.sizeChange) return; // Not interested in scroll events
            initFontSize();
        };
        initFontSize();
        el['data-eet-event-handler'] = viewportEventHandler;
        viewportEvents.setCallback(viewportEventHandler);
    },
    unbind(el: HTMLElement) {
        viewportEvents.removeCallback(el['data-eet-event-handler']);
    }
});
