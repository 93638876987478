export function hexToRgb(hex: string, alpha: number | undefined = 1): string {
    const { red, green, blue } = hexToRgbValues(hex);

    if (alpha < 0 || alpha > 1) {
        throw new Error('Alpha should be between 0 and 1: ' + alpha);
    }

    if (alpha !== 1) {
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    } else {
        return `rgb(${red}, ${green}, ${blue})`;
    }
}

export function isValidHexColor(hex: string): boolean {
    if (!hex) return false;

    if ((hex.length === 7 || hex.length === 9) && hex[0] === '#') {
        return true;
    }

    return hex.length === 6;
}

export function hexToRgbValues(hex: string): {red: number, green: number, blue: number } {
    if (!isValidHexColor(hex)) throw new Error('Invalid color provided. ' + hex);

    if (hex.length === 7 || hex.length === 9) {
        hex = hex.substr(1);
    }

    const red = hex2ToDecimal(hex.substr(0, 2));
    const green = hex2ToDecimal(hex.substr(2, 2));
    const blue = hex2ToDecimal(hex.substr(4, 2));

    return {
        red,
        green,
        blue
    };

    function hex2ToDecimal(hexDigits: string): number {
        if (!hexDigits || hexDigits.length !== 2) {
            throw new Error('Can only convert 2 digit hex-string, but got: ' + hexDigits);
        }
        return parseInt(hexDigits, 16);
    }
}

export function luma(r: number|string, g: number|string, b: number|string): number {
    if (typeof r === 'string') r = parseInt(r);
    if (typeof g === 'string') g = parseInt(g);
    if (typeof b === 'string') b = parseInt(b);
    // Calculate the perceptive luminance (aka luma) - human eye favors green color...
    return ((0.299 * r) + (0.587 * g) + (0.114 * b)) / 255;
}

export function RGBToHSLObject(r: number|string, g: number|string, b: number|string): { h: number, s: number, l: number } {
    if (typeof r === 'string') r = parseInt(r);
    if (typeof g === 'string') g = parseInt(g);
    if (typeof b === 'string') b = parseInt(b);
    // Make r, g, and b fractions of 1
    r /= 255;
    g /= 255;
    b /= 255;

    // Find greatest and smallest channel values
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;

    // Calculate hue
    // No difference
    if (delta === 0) {
        h = 0;
    } else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
        h = (b - r) / delta + 2;
    } else { h = (r - g) / delta + 4; }

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0) { h += 360; }

    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
}
export function siteThemeColorBasedOnBackgroundColor(backgroundColor: string, tailwind, isOprema: boolean = false): { textColor: string, backgroundColor: string } {
    const returnColors = { textColor: '', backgroundColor: backgroundColor };
    const rgbBackground = isValidHexColor(backgroundColor) ? hexToRgb(backgroundColor) : backgroundColor;
    const [r, g, b] = rgbBackground.replace(/[^\d,]/g, '').split(',');
    const isDarkBackground = luma(r, g, b) <= 0.5;
    let textColor = isDarkBackground ? 'white' : 'black';
    if (isOprema) {
        if (backgroundColor === tailwind.theme.colors.opremablue['500']) {
            textColor = tailwind.theme.colors.opremayellow['500'];
        } else if (backgroundColor === tailwind.theme.colors.opremayellow['500']) {
            textColor = tailwind.theme.colors.opremablue['500'];
        } else if (backgroundColor === tailwind.theme.colors.opremagray['300']) {
            textColor = '#FFFFFE';
        } else if (backgroundColor === tailwind.theme.colors.opremablack) {
            textColor = '#FFFFFE';
        } else {
            textColor = isDarkBackground ? '#FFFFFE' : tailwind.theme.colors.opremablue['500'];
        }
    }
    returnColors.textColor = textColor;
    return returnColors;
}
