import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';

export class NewsletterController {
    private readonly urlFactory = createUrlFactory('Newsletter');

    public async unsubscribe(email: string, messageId: string): Promise<void> {
        return httpService.delete(this.urlFactory('Unsubscribe'), {
            email
        }, undefined, messageId);
    }
}
