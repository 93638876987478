import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
// Documentation https://github.com/nolimits4web/Swiper/blob/v5/API.md
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css';

// overwrites
import './swiper.less';

Vue.use(VueAwesomeSwiper);
