import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { AlternativeUrlViewModel } from '@/types/apiServerContract';

export class MetadataController {
    private readonly urlFactory = createUrlFactory('Metadata');

    public async getAlternativeCategoryUrls(categoryId?: string): Promise<AlternativeUrlViewModel> {
        return httpService.get(this.urlFactory('AlternativeCategoryUrls'), { categoryId });
    }
}
