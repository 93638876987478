import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { CountryOptionsViewModel, SwitchSiteRequest, SwitchSiteResponseViewModel, CountryPickerOptionsViewModel } from '@/types/apiServerContract';

export class SitesController {
    private readonly urlFactory = createUrlFactory('Sites');

    public async siteOptions(): Promise<CountryOptionsViewModel> {
        return httpService.get(this.urlFactory('SiteOptions'));
    }

    public async switchSite(options: SwitchSiteRequest, messagesId?: string): Promise<SwitchSiteResponseViewModel> {
        return httpService.post(this.urlFactory('SwitchSite'), options, messagesId);
    }

    public async countryOptions(): Promise<CountryPickerOptionsViewModel> {
        return httpService.get(this.urlFactory('CountryOptions'));
    }
}
