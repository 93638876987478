import Vue from 'vue';
import { router } from '@/router/index';
import loggingService from '@/core/logging.service';
import urlStringHelperService from '@/core/urlStringHelper.service';

// Fallback for matches for IE11
if (!Element.prototype.matches) {
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
}

Vue.directive('delegateHtmlLinks', {
    bind: function(el: HTMLElement, binding) {
        el.addEventListener('click', event => {
            const { target }: any = event;

            if (urlStringHelperService.isExternalScheme(target.href)) {
                return;
            }

            // only links that do not reference external resources
            if (target && target.href && !(isHrefExternal(target.href) || isHrefMedia(target.href))) {
                const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;

                if (metaKey || altKey || ctrlKey || shiftKey) {
                    return;
                }

                if (defaultPrevented) {
                    return;
                }

                if (button !== undefined && button !== 0) {
                    return;
                }

                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target');
                    if (/\b_blank\b/i.test(linkTarget)) {
                        return;
                    }
                }

                const url = new URL(target.href);
                if (window.location.pathname !== url.pathname && event.preventDefault) {
                    event.preventDefault();
                    // Handling link to frontpage
                    if (urlStringHelperService.isFrontpagePath(url.pathname)) {
                        router.push('/').catch(e => loggingService.debug(e));
                    } else {
                        router.push(urlStringHelperService.withoutCulturePrefix(urlStringHelperService.removeTrailingSlashAndLowercase(url.pathname).path + url.search + url.hash)).catch(e => loggingService.debug(e));
                    }
                }
            } else if (target && target.setAttribute && isHrefExternal(target.href)) {
                // Always open external links in new window and add rel="noopener" attribute.
                const relAttribute: string = (target.getAttribute('rel') || '') + ' noopener';
                target.setAttribute('rel', relAttribute.trim());
                target.setAttribute('target', '_blank');
            }
        });
    }
});

// returns true if href points to an external url
function isHrefExternal(href: string, host: string = location.host): boolean {
    const external = RegExp('^((f|ht)tps?:)?//(?!' + host + ')');
    return external.test(href);
}
// if a href points to some files in the Umbraco /media folder
function isHrefMedia(href: string, host: string = location.host): boolean {
    let pathname;
    if (href.indexOf('http') === 0) {
        const url = new URL(href);
        pathname = url.pathname;
    } else {
        pathname = href;
    }
    return pathname.indexOf('/media/') === 0;
}
