import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    AddOrUpdateFavoriteItemListRequest,
    CreateFavoriteListRequest,
    FavoriteListDetailsViewModel,
    FavoriteListPermission,
    FavoriteListViewModel,
    FavoritesItemsListedViewModel,
    RemoveItemsFromFavoriteListRequest,
    SearchShareFavoriteViewModel,
    UpdateFavoriteListRequest
} from '@/types/apiServerContract';
import { createCancellableGet } from '@/core/http/cancellableGet';
import { AxiosResponse } from 'axios';

export class FavoritesController {
    private readonly urlFactory = createUrlFactory('Favorites');

    // GET

    private cancellableSearchForCustomers = createCancellableGet<SearchShareFavoriteViewModel>();
    private cancellableSearchForUsers = createCancellableGet<SearchShareFavoriteViewModel>();

    public async getAllLists(): Promise<FavoriteListViewModel> {
        return httpService.get(this.urlFactory('All'));
    }

    public async byIds(listIds: number[], permission?: FavoriteListPermission): Promise<FavoriteListViewModel> {
        return httpService.get(this.urlFactory('ByIds'), {
            listIds,
            permission
        });
    }

    public async getDetails(id: number): Promise<FavoriteListDetailsViewModel> {
        return httpService.get(this.urlFactory('Details'), {
            id
        });
    }

    public async isListed(itemIds: string[]): Promise<FavoritesItemsListedViewModel> {
        return httpService.get(this.urlFactory('IsListed'), {
            itemIds
        });
    }

    public async searchUsers(term: string): Promise<SearchShareFavoriteViewModel> {
        return this.cancellableSearchForUsers(this.urlFactory('SearchUsers'), {
            term
        });
    }

    public async searchCustomers(term: string): Promise<SearchShareFavoriteViewModel> {
        return this.cancellableSearchForCustomers(this.urlFactory('SearchCustomers'), {
            term
        });
    }

    // POST

    public async create(request: CreateFavoriteListRequest): Promise<FavoriteListViewModel> {
        return httpService.post(this.urlFactory('Create'), request);
    }

    public async update(request: UpdateFavoriteListRequest): Promise<FavoriteListViewModel> {
        return httpService.post(this.urlFactory('Update'), request);
    }

    public async addOrUpdateItem(request: AddOrUpdateFavoriteItemListRequest): Promise<void> {
        return httpService.post(this.urlFactory('AddOrUpdateItem'), request);
    }

    public async removeItems(request: RemoveItemsFromFavoriteListRequest): Promise<void> {
        return httpService.post(this.urlFactory('RemoveItems'), request);
    }

    public async removeFromSharedList(id: number): Promise<FavoriteListViewModel> {
        return httpService.post(this.urlFactory('RemoveFromSharedList'), id);
    }

    public async export(id: number) {
        const result = await httpService.post<AxiosResponse>(this.urlFactory('export'),
            id,
            undefined,
            undefined,
            {
                responseType: 'blob'
            },
            true);

        const fileName = httpService.getContentDispositionFilename(result);
        const file = result.data as Blob;

        return {
            fileName,
            file
        };
    }

    // DELETE
    public async delete(id: number, messageId?: string): Promise<FavoriteListViewModel> {
        return httpService.delete(this.urlFactory('Delete'), {
            id
        }, undefined, messageId);
    }
}
