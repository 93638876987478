


























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import urlHelper from '@/core/urlHelper.service';
import { Route } from 'vue-router';
import themeService from '@/project/app/themes/theme.service';
import serverContextService from '@/core/serverContext.service';
import tailwind from '@/styling/tailwind.config';

const minNoOfCharacters = 2;
const debounceTime = 300;
@Component
export default class SearchCtrl extends Vue {
    term = '';
    debounceSetTerm = debounce(this.setTerm, debounceTime);
    showOverlay = this.shouldShowOverlay(this.term);
    typing = false;
    isSearchOverlayScrolledDown: boolean = false;

    @Prop({ type: HTMLElement })
    anchorElement!: HTMLElement;

    @Prop({ type: Boolean, default: false })
    showSearchField!: boolean;

    @Prop({ type: Object, required: false })
    sectionStyling!: Partial<CSSStyleDeclaration>;

    @Prop({ type: String, default: '' })
    sectionClass!: string;

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute?.path) {
            this.$emit('closeMobileSearch');
        }
        if (!this.showOverlay) {
            const term = urlHelper.getTerm();
            if (!term) {
                this.clickCloseMobileSearch();
                return;
            } else {
                this.onTermChanged(term);
                this.openMobileSearch();
            }
        }

        if (!urlHelper.getTerm()) {
            // We came to page without term - reset / close
            this.setTerm('', false);
        } else {
            // Term updated - for instance from translation (alternative) search
            this.setSyncedTerm(urlHelper.getTerm()!);
            this.openMobileSearch();
        }
    }

    @Watch('term')
    onTermChanged(term: string) {
        this.typing = true;

        this.debounceSetTerm(term);
    }

    @Watch('showSearchField')
    onShowSearchFieldChanged(showSearchField: boolean, oldShowSearchField: boolean) {
        if (showSearchField) {
            this.focusSearchInputField();
        }
    }

    setTerm(term: string, goBack: boolean = true) {
        this.setSyncedTerm(term);
        const oldShow = this.showOverlay;
        this.showOverlay = this.shouldShowOverlay(term);
        const justShown = this.showOverlay && !oldShow;

        if (this.showOverlay) {
            urlHelper.setTerm(term, justShown);
            if (!justShown) {
                urlHelper.clearFacets();
            }
        }
        this.typing = false;
    }

    reset() {
        this.setTerm('');
    }

    clickCloseMobileSearch() {
        urlHelper.setTerm('');
        this.$emit('closeMobileSearch');
    }

    openMobileSearch() {
        this.$emit('openMobileSearch');
        this.isSearchOverlayScrolledDown = false;
    }

    focusSearchInputField() {
        // To focus input field on iOS, here comes a hack:
        // create invisible dummy input to receive the focus first
        const fakeInput = document.createElement('input');
        fakeInput.setAttribute('type', 'text');
        fakeInput.style.position = 'absolute'; // hide fake input field
        fakeInput.style.opacity = '0';
        fakeInput.style.height = '0';
        fakeInput.style.fontSize = '16px'; // disable auto zoom
        document.body.prepend(fakeInput);
        // focus so that subsequent async focus will work
        fakeInput.focus();

        setTimeout(() => {
            // now we can focus on the target input
            const targetInput = this.$refs.inputElem;
            if (targetInput) {
                targetInput.style.fontSize = '16px'; // disable auto zoom
                targetInput.focus();
                targetInput.style.fontSize = 'inherit'; // reset font size
            }
            // cleanup
            fakeInput.remove();
        }, 300);
    }

    shouldShowOverlay(term: string): boolean {
        return term?.length >= minNoOfCharacters;
    }

    setSyncedTerm(term: string = '') {
        this.term = term;
        this.$refs.inputElem.value = term;
    }

    lowlevelInputHandler(term: string) {
        if (term.length > 0 && term.trim().length === 0) {
            this.$refs.inputElem.value = '';
        } else {
            this.term = term;
        }
    }

    $refs!: Vue['$refs'] & {
        inputElem: HTMLInputElement
    }

    mounted() {
        this.setSyncedTerm(urlHelper.getTerm());
    }

    get searchInputIconColor(): string {
        return themeService.isInWhiteTheme ? 'white' : this.isOprema ? 'black' : (tailwind.theme.colors.blue['500']);
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }

    get searchInputStyle(): Partial<CSSStyleDeclaration> {
        const isInWhiteTheme = themeService.isInWhiteTheme;

        return {
            ...(isInWhiteTheme && { color: '#fff' }),
            ...(isInWhiteTheme && { backgroundColor: 'transparent' }),
            ...(isInWhiteTheme && { borderColor: '#fff' })
        };
    }

    get searchWrapperStyle(): Partial<CSSStyleDeclaration> {
        return {
            ...this.sectionStyling,
            transform: `translateY(${this.showSearchField ? '0' : '-200px'})`
        };
    }

    get isInWhiteTheme() {
        return themeService.isInWhiteTheme;
    }

    get logoAltText():string {
        return serverContextService.footer?.companyDetails?.companyName || '';
    }

    get frontpageUrl():string {
        return serverContextService.siteInfo?.url || '/';
    }
}

