


















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InputText extends Vue {
    @Prop({ required: true })
    value!: string;

    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: String, default: '' })
    infoLabel!: string;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: String, default: 'text' })
    type!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: false })
    autofocus!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: String, required: false, default: '' })
    placeholder!: string;

    @Prop({ type: Boolean, required: false, default: false })
    invalid!: boolean;

    @Prop({ type: String, required: false, default: 'h-50' })
    inputClass!: string;

    @Prop()
    customMessages!: object;

    get id() {
        return this.name + Math.random();
    }

    internalType: string = this.type;

    get isPasswordVisible() {
        return this.internalType === 'text';
    }

    togglePasswordVisibility() {
        this.internalType = this.isPasswordVisible ? 'password' : 'text';
    }

    get isPassword() {
        return this.type === 'password';
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }

    get hasValue(): boolean {
        return this.value !== '' && this.value != null;
    }

    get labelText(): string {
        return this.isRequired ? `${this.label} *` : this.label;
    }

    get isRequired(): boolean {
        return 'required' in this.$attrs ||
            (typeof this.rules === 'string' && this.rules.includes('required')) ||
            (typeof this.rules === 'object' && !!(this.rules as any).required);
    }

    changedInput(event) {
        this.$emit('input', event.target.value);
    }
}
