import Vue from 'vue';
import tailwind from '@/styling/tailwind.config';
import { hexToRgbValues, luma } from '@/core/color/color.service';
import { Route } from 'vue-router';
import { router } from '@/router/index';

interface Theme {
    topColor: string;
    menuColor: string;
    takeoverTheme: string;
    layoutId: string;
}

const takeOverThemeLayoutId = 'yfrt26v3bfttmxn3';
const defaultTakeOverOpacity = 0.8;

class ThemeService {
    private activeTheme: {
        theme: Partial<Theme> | null;
        takeOverOpacity: number;
        themeLoaded: boolean;
    } = Vue.observable({
        theme: null,
        themeLoaded: false,
        takeOverOpacity: defaultTakeOverOpacity
    });

    public restoreDefaultTheme() {
        this.activeTheme.theme = null;
    }

    public applyTheme(theme: Partial<Theme>) {
        if (Object.keys(theme).length === 0) {
            this.activeTheme.theme = null;
            return;
        }

        this.activeTheme.themeLoaded = false;
        this.activeTheme.theme = theme;
    }

    public disableBackgroundOpacity() {
        return this.activeTheme.takeOverOpacity = 1.0;
    }

    public setThemeLoaded() {
        this.activeTheme.themeLoaded = true;
    }

    public restoreBackgroundOpacity() {
        return this.activeTheme.takeOverOpacity = defaultTakeOverOpacity;
    }

    public get isTakeOverEnabled() {
        return this.activeTheme.theme?.layoutId === takeOverThemeLayoutId;
    }

    public get isTakeOverEnabledAndLoaded() {
        return this.isTakeOverEnabled && this.activeTheme.themeLoaded;
    }

    public get isInWhiteTheme() {
        return this.activeTheme.theme?.takeoverTheme?.toLowerCase() === 'white';
    }

    public get takeOverOpacity() {
        return this.isTakeOverEnabled ? this.activeTheme.takeOverOpacity : 1.0;
    }

    public get theme() {
        return this.activeTheme.theme;
    }

    public getColor(hex: string) {
        const rgb = hexToRgbValues(hex);
        const isDarkBackground = luma(rgb.red, rgb.green, rgb.blue) <= 0.5;
        return isDarkBackground ? tailwind.theme.colors.white : tailwind.theme.colors.black;
    }
}

const themeService = new ThemeService();

router.afterEach((to: Route, from: Route) => {
    if (to.path !== from.path) {
        themeService.restoreDefaultTheme();
    }
});

export default themeService;
