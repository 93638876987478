import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { CountryOptionsPreselectViewModel, OfficesViewModel } from '@/types/apiServerContract';

export class BecomeAUserController {
    private readonly urlFactory = createUrlFactory('becomeacustomer');

    public async getCountryOptions(): Promise<CountryOptionsPreselectViewModel> {
        return httpService.get(this.urlFactory('countryoptions'));
    }

    public async getCountryOffices(): Promise<OfficesViewModel> {
        return httpService.get(this.urlFactory('offices'));
    }
}
