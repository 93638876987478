import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import authService from './auth.service';
import HttpStatus from 'http-status-codes';

enum CustomResponseHeaders {
    Token = 'x-eet-token',
    TokenInvalid = 'x-eet-token-invalid'
}

const responseHandler = async(response: AxiosResponse) => {
    const isTokenInvalid = response?.headers[CustomResponseHeaders.TokenInvalid] as unknown;
    if (isTokenInvalid) {
        authService.logOff();
        return response;
    }

    const token = response?.headers[CustomResponseHeaders.Token] as string;
    if (token) {
        authService.setToken(token);
    }

    if (response.status === HttpStatus.UNAUTHORIZED && token) {
        // We got a 401 but a new token has been provided, retry the rejected request
        const retryResponse = await axios.request(response.config);
        return retryResponse;
    }

    return response;
};

const requestHandler = (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (authService.getToken()) {
        config.headers = config.headers || {};
        config.headers.authorization = 'Bearer ' + authService.getToken();
    }
    return config;
};

export {
    requestHandler,
    responseHandler
};
