




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BidViewObject, ProductTileViewObject, StockInformationViewObject } from '@/types/apiServerContract';
import simplePriceFetchService, { PriceSimpleDataExtended } from '@/project/product/services/simplePriceFetch.service';
import stockFetchService from '@/project/product/services/stockFetch.service';
import productBidsFetchService from '@/project/product/services/productBidsFetch.service';

import authService from '@/core/auth/auth.service';

import ProductTileCampaign from './ProductTileCampaign.vue';
import ProductTileImage from './ProductTileImage.vue';
import ProductTileDetails from './ProductTileDetails.vue';
import GTMService, { ProductTrackingState, TrackingProduct } from '@/project/tracking/gtm/GTM.service';
import { TrackingProductAndElement } from '@/project/tracking/gtm/types';
import serverContextService from '@/core/serverContext.service';
import Api from '@/project/http/Api.service';
import bus from '@/core/bus';
import { ProductTrackerProcessBatchKey } from '@/project/tracking/gtm/ProductListTracker.vue';

const ProductTileMenu = () => import('@/project/product/product-tile/ProductTileMenu.vue');
const ProductTileFavorite = () => import(/* webpackChunkName: 'auth-feature' */'@/project/product/product-tile/ProductTileFavorite.vue');
const ProductTileCart = () => import(/* webpackChunkName: 'auth-feature' */'@/project/product/product-tile/ProductTileCart.vue');
const ProductTileDiscount = () => import(/* webpackChunkName: 'auth-feature' */'@/project/product/product-tile/ProductTileDiscounts.vue');
const ProductTileCondition = () => import(/* webpackChunkName: 'auth-feature' */'@/project/product/product-tile/ProductTileCondition.vue');

@Component({
    components: {
        ProductTileCampaign,
        ProductTileImage,
        ProductTileDetails,
        ProductTileFavorite,
        ProductTileCart,
        ProductTileMenu,
        ProductTileDiscount,
        ProductTileCondition
    }
})
export default class ProductTile extends Vue {
    @Prop({ required: true }) product!: ProductTileViewObject;
    @Prop({ required: false, default: false, type: Boolean }) nested!: boolean;
    @Prop({ required: false, default: false, type: Boolean }) isBid!: boolean;
    @Prop({ required: true, type: Function }) productClick!: (product: TrackingProduct) => void;
    @Prop({ required: false, type: String, default: '' }) trackingListName!: string;
    @Prop({ required: false, type: String, default: 'horizontal' }) mode!: 'vertical' | 'horizontal' | 'horizontal-tiny';
    @Prop({ required: false, type: String, default: '' }) tileClass!: string;
    @Prop({ required: false }) numberOfStockLabels!: number;
    @Prop({ required: false, type: Boolean, default: false }) hideAccessoriesInTiles!: boolean;
    @Prop({ required: false }) indexInProductList!: number;

    get isInVerticalMode() {
        return this.mode === 'vertical';
    }

    get isInHorizontalMode() {
        return this.mode === 'horizontal' || this.mode === 'horizontal-tiny';
    }

    priceData: PriceSimpleDataExtended | null = null;
    stockData: readonly StockInformationViewObject[] | null = null;
    simpleStockData: readonly StockInformationViewObject[] | null = null;
    bidsData: readonly ProductTileViewObject[] | null = null;
    showTileMenu: boolean = false;
    isERPServiceAvailable = true;

    pricesLoaded: boolean = false;
    stockLoaded: boolean = false;
    bidsLoaded: boolean = false;

    cancellationCallbacks: (() => void)[] = [];

    get bid(): BidViewObject | null | undefined {
        return this.isBid ? this.product as any as BidViewObject : this.product.bid;
    }

    get hasBids() {
        return this.bidsData && this.bidsData.length > 0 && this.$permission.canViewBids();
    }

    get hasAlternatives() {
        return !this.isBid && (this.product.hasSimilarAlternatives || this.product.hasExactAlternatives);
    }

    get hasAccessories() {
        return !this.isBid && (this.product.hasAccessories);
    }

    isInStock() {
        return this.stockData && this.stockData.length > 0;
    }

    async toggleMenu() {
        this.showTileMenu = !this.showTileMenu;
    }

    get isUserLoggedIn(): boolean {
        return authService.isLoggedIn();
    }

    // Loaded via IFrame
    get isExternalProductGuide() {
        return serverContextService.isExternalProductGuide;
    }

    get hasProductTileMenu(): boolean {
        return this.hideAccessoriesInTiles === false && this.isExternalProductGuide === false && this.isInHorizontalMode && !this.nested && !this.isBid && (this.hasBids || this.hasAlternatives || this.hasAccessories);
    }

    trackExternalProductGuideClick() {
        if (this.isExternalProductGuide && serverContextService.externalProductGuideSettings?.externalId) {
            Api.productGuide.trackclick({
                externalId: serverContextService.externalProductGuideSettings.externalId,
                productId: this.product.productId,
                destination: serverContextService.externalProductGuideSettings?.externalProductLink ? 1 : 0
            });
        }
    }

    @Watch('isUserLoggedIn')
    onLoginStateChange(now: boolean, before: boolean) {
        if (now && !before) {
            this.getPriceData();
            this.getStockData();
            this.getBidsData();
        }
    }

    async getPriceData() {
        if (this.isExternalProductGuide) {
            this.pricesLoaded = true;
            return;
        }

        this.pricesLoaded = false;

        try {
            if (!this.isUserLoggedIn) {
                return;
            }

            const request = simplePriceFetchService.requestData(this.product.itemId);
            this.cancellationCallbacks.push(request.cancel);
            const result = await request.promise;
            this.priceData = Object.freeze(result);
            this.isERPServiceAvailable = this.isERPServiceAvailable && result.isServiceAvailable;
        } catch {
        } finally {
            this.pricesLoaded = true;
            this.trackImpression();
        }
    }

    trackImpression(force = false): void {
        if ((!force && (!this.pricesLoaded || !this.stockLoaded)) || this.isExternalProductGuide) return;

        this.$emit('productImpression', {
            product: this.constructTrackingProduct(),
            el: this.$el
        } as TrackingProductAndElement);
    }

    async getStockData() {
        this.stockLoaded = false;

        try {
            const request = stockFetchService.requestData(this.product.itemId);
            this.cancellationCallbacks.push(request.cancel);
            const result = await request.promise;
            this.stockData = Object.freeze(result.stockInformation);
            this.simpleStockData = Object.freeze(result.simpleStockInformation);
            this.isERPServiceAvailable = this.isERPServiceAvailable && result.isServiceAvailable;
        } catch {
        } finally {
            this.stockLoaded = true;
            this.trackImpression();
        }
    }

    async getBidsData() {
        if (this.nested || this.bid || !this.isUserLoggedIn || !this.$permission.canViewBids()) {
            this.bidsLoaded = true;
            return;
        }

        this.bidsLoaded = false;

        try {
            const request = productBidsFetchService.requestData(this.product.wid);
            this.cancellationCallbacks.push(request.cancel);
            const result = await request.promise;
            this.bidsData = Object.freeze(result.productBids);
        } catch {
        } finally {
            this.bidsLoaded = true;
        }
    }

    addToBasketChange(deltaQuantity: number, quantity: number) {
        bus.emit(ProductTrackerProcessBatchKey);
        this.openMenuOnAddToCart(deltaQuantity, quantity);
        this.trackAddToBasketChange(deltaQuantity);
    }

    openMenuOnAddToCart(deltaQuantity: number, quantity: number) {
        // Open accessories if "first" add to cart (from 0 to 1 in quantity) and relation type is accessories
        if (!!this.product.accessories?.length && quantity === 1 && deltaQuantity > 0) {
            this.showTileMenu = true;
        }
    }

    trackAddToBasketChange(deltaQuantity: number) {
        GTMService.trackProductQuantityChange(
            this.constructTrackingProduct(),
            deltaQuantity,
            this.trackingListName
        );
    }

    constructTrackingProduct(): TrackingProduct {
        return {
            ...this.product,
            price: this.priceData?.amount,
            stockInfo: this.stockData,
            trackingState: this.getTrackingState
        };
    }

    get getTrackingState(): ProductTrackingState {
        if (!this.isUserLoggedIn) return ProductTrackingState.NotLoggedIn;
        if (!this.pricesLoaded || !this.stockLoaded) return ProductTrackingState.NoErpResponseYet;
        return this.isERPServiceAvailable ? ProductTrackingState.Complete : ProductTrackingState.ErpNotAvailable;
    }

    rawProductClick() {
        this.productClick(this.constructTrackingProduct());
        this.trackExternalProductGuideClick();
    }

    get showFavorite(): boolean {
        return this.$permission.canPunchOut() === false && this.isExternalProductGuide === false && this.isInHorizontalMode;
    }

    created() {
        this.getPriceData();
        this.getStockData();
        this.getBidsData();
    }

    mounted() {
        this.trackImpression(true);
    }

    // cancelOutstandingXHRRequests() {
    //     this.cancellationCallbacks.forEach(x => x && x());
    // }

    // destroyed() {
    //     this.cancelOutstandingXHRRequests();
    // }
}
