import Vue from 'vue';

Vue.directive('autofocus', {
    inserted(el, binding) {
        // Handle usage with and without binding to expression
        if (!binding.expression || binding.value) {
            el.focus();
        }
    }
});
