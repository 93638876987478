
import Vue, { CreateElement, VNodeData } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class RouterLinkConditional extends Vue {
    @Prop(String) to!: string;
    @Prop(String) tag!: string;

    get isExternal(): boolean {
        const external = RegExp('^((f|ht)tps?:)?//(?!' + location.host + ')');
        return external.test(this.to);
    }

    render(h: CreateElement) {
        let element = '';
        let childData: VNodeData = {};

        if (this.to) {
            if (this.isExternal) {
                element = 'a';
                childData = this.createLinkChildData('ExternalLink');
            } else {
                element = 'RouterLink';
                childData = this.createLinkChildData('InternalLink');
            }
        } else {
            element = this.tag ? this.tag : 'div';
            childData = this.createLinkChildData('PassThrough');
        }

        return h(element, childData, this.$slots.default);
    }

    createLinkChildData(type: 'ExternalLink' | 'InternalLink' | 'PassThrough'): VNodeData {
        switch (type) {
        case 'PassThrough':
            return {
                attrs: this.$attrs
            };
        case 'ExternalLink':
            return {
                attrs: {
                    href: this.to,
                    rel: 'noopener',
                    ...this.$attrs
                }
            };
        case 'InternalLink':
            return {
                attrs: {
                    to: this.to,
                    tag: this.tag,
                    ...this.$attrs
                }
            };
        }
    }
};
