












import { Vue, Component, Prop } from 'vue-property-decorator';
import { BaseContentModel } from '@/types/contentServerContract';
import urlHelperService from '@/core/urlHelper.service';
import { UrlQueryKey } from '@/core/urlQueryKey';

@Component
export default class ProductNotFoundPage extends Vue {
    @Prop({ required: true, type: Object }) content!: BaseContentModel;

    wid: string | undefined = urlHelperService.getQueryParam(UrlQueryKey.wid);
}
