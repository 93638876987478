type CableGuideName = 'cableGuide';

export enum CableGuideModes {
    stepByStep = 'step-by-step',
}

export enum CableGuideActions {
    selectType = 'selectType',
    selectCableA = 'selectCableA',
    selectCableB = 'selectCableB'
}

export interface CableGuideTrackingInformation {
    guideName: CableGuideName,
    guideMode: CableGuideModes,
    action: CableGuideActions,
    actionValue: string | undefined
}

interface CableGuideTrackingDataModel extends CableGuideTrackingInformation {
    event: string,
    actionValue: string
}

export function generateCableGuideTrackingData(model: CableGuideTrackingInformation): CableGuideTrackingDataModel | void {
    if (!model.actionValue) return;

    return {
        event: 'guide.selection',
        guideName: model.guideName,
        guideMode: model.guideMode,
        action: model.action,
        actionValue: model.actionValue
    };
}
