



















































































import { Vue, Prop, Component } from 'vue-property-decorator';
import { ProductTileViewObject, StockInformationViewObject, BidViewObject } from '@/types/apiServerContract';

import ProductTileInfo from './ProductTileInfo.vue';
import ProductTileCondition from './ProductTileCondition.vue';
import ProductTileStock from './ProductTileStock.vue';
import ProductTileBid from './ProductTileBid.vue';
import serverContextService from '@/core/serverContext.service';

@Component({
    components: { ProductTileInfo, ProductTileCondition, ProductTileStock, ProductTileBid }
})
export default class ProductTileDetails extends Vue {
    @Prop({ required: true }) product!: ProductTileViewObject;
    @Prop({ required: true }) bid!: BidViewObject | null;
    @Prop({ required: false }) numberOfStockLabels!: number;
    @Prop({ required: true }) isLoggedIn!: boolean;
    @Prop({ required: true }) stock!: { loaded: boolean, data: StockInformationViewObject[], simpleData: StockInformationViewObject[] };
    @Prop({ required: true }) show!: boolean;
    @Prop({ required: true }) hasBids!: boolean;
    @Prop({ required: true }) hasAlternatives!: boolean;
    @Prop({ required: true }) hasAccessories!: boolean;
    @Prop({ required: true }) nested!: boolean;
    @Prop({ required: true, type: Function }) productClick!: () => void;
    @Prop({ required: false, type: String, default: 'horizontal' }) mode!: 'vertical' | 'horizontal' | 'horizontal-tiny';
    @Prop({ required: false, type: Boolean }) hasProductTileMenu!: boolean;

    get isInVerticalMode() {
        return this.mode === 'vertical';
    }

    get isInHorizontalMode() {
        return this.mode === 'horizontal' || this.mode === 'horizontal-tiny';
    }

    toggleMenu() {
        this.$emit('toggleMenu');
    }

    get productDetailsUrl() {
        return serverContextService.productUrl(this.product);
    }

    get productDetailsUrlTarget() {
        return serverContextService.externalProductLinkTarget;
    }

    get isLimitedItem() {
        return this.isInHorizontalMode && this.product.blocking === 2; // LIMITED ITEM
    }

    // Loaded via IFrame
    get isExternalProductGuide() {
        return serverContextService.isExternalProductGuide;
    }

    get dropdownLabel() {
        const hasBids = this.hasBids;
        const hasAlt = this.hasAlternatives;
        const hasAcc = this.hasAccessories;

        const bidKey = 'bids';
        const altKey = 'alt';
        const accKey = 'rel';

        const possibleCombinations = {
            /* 1 */ [bidKey]: 'Product.Bids',
            /* 2 */ [altKey]: 'Product.Alternatives',
            /* 3 */ [accKey]: 'Product.Accessories',
            /* 1 + 2 */ [bidKey + altKey]: 'Product.BidsAndAlternatives',
            /* 1 + 3 */ [bidKey + accKey]: 'Product.BidsAndAccessories',
            /* 2 + 3 */ [altKey + accKey]: 'Product.AlternativesAndAccessories',
            /* 1 + 2 + 3 */ [bidKey + altKey + accKey]: 'Product.BidsAlternativesAccessories'
        };

        const key = `${hasBids ? bidKey : ''}${hasAlt ? altKey : ''}${hasAcc ? accKey : ''}`;
        return possibleCombinations[key];
    }

    get lineClampDisplayName(): number {
        return this.product.subLine ? 2 : 3;
    }
}
