import { RouteConfig } from 'vue-router';
import { PageName } from '@/router/routes/constants';
import { RouterMetaData } from '@/core/app/clientPageHandler';
import { isDevMode } from '@/core/environmentService';
import dictionaryService from '@/core/translation/dictionary.service';
import siteService from '@/project/site/site.service';
import serverContextService from '@/core/serverContext.service';
import { SiteName } from '@/types/apiServerContract';
import { hardRedirect } from '@/core/redirect.service';
import domService from '@/core/dom/dom.service';
import HttpStatus from 'http-status-codes';

const GeneralRoutes: RouteConfig[] = [
    {
        path: '/*wid-:id',
        name: PageName.PRODUCT_DETAILS,
        component: () => import(
            /* webpackPrefetch: true */
            '@/project/app/client-pages/product-details/ProductDetailsPage.vue'),
        meta: {
            headMeta: {
                index: serverContextService.siteName !== SiteName.EetRetail
            },
            isPublic: true,
            skipAutoPageTracking: true,
            skipAutoBreadcrumb: true,
            skipAutoSeoTitle: true
        } as RouterMetaData
    },
    {
        path: '/campaigns/:campaignCode',
        name: PageName.CAMPAIGN,
        component: () => import(
            /* webpackPrefetch: true */
            '@/project/app/client-pages/CampaignPage.vue'),
        props: (route) => ({
            campaignCode: route.params.campaignCode
        }),
        children: [{
            path: '/campaigns/*/:campaignCode',
            name: PageName.CAMPAIGN_2,
            component: () => import(
                /* webpackPrefetch: true */
                '@/project/app/client-pages/CampaignPage.vue'),
            props: (route) => ({
                campaignCode: route.params.campaignCode
            }),
            meta: {
                headMeta: {
                    index: serverContextService.siteName !== SiteName.EetRetail
                },
                isPublic: true,
                skipAutoPageTracking: true,
                skipAutoBreadcrumb: true,
                skipAutoSeoTitle: true
            } as RouterMetaData
        }],
        meta: {
            headMeta: {
                index: serverContextService.siteName !== SiteName.EetRetail
            },
            isPublic: true,
            skipAutoPageTracking: true,
            skipAutoBreadcrumb: true,
            skipAutoSeoTitle: true
        } as RouterMetaData
    },
    {
        path: '/brands/all',
        redirect: to => {
            domService.setPrerenderHeader(`Location: ${location.origin}/${siteService.getCulture()}/brands`);
            domService.setPrerenderStatusCode(HttpStatus.MOVED_PERMANENTLY);
            return { name: PageName.BRAND_OVERVIEW };
        }
    },
    {
        path: '/brands',
        name: PageName.BRAND_OVERVIEW,
        component: () => import(
            /* webpackChunkName: 'brands' */
            '@/project/app/client-pages/BrandsOverview.vue'),
        meta: {
            isPublic: true,
            headMeta: {
                index: serverContextService.siteName !== SiteName.EetRetail
            },
            breadcrumb: [
                {
                    name: dictionaryService.get('Brand.BrandOverview.Title'),
                    page: PageName.BRAND_OVERVIEW
                },
                {
                    name: dictionaryService.get('Brand.BrandOverview.AllBrands'),
                    page: PageName.BRAND_OVERVIEW
                }
            ]
        } as RouterMetaData
    },
    {
        path: '/brands/:brandName?',
        name: PageName.BRAND,
        component: () => import(
            /* webpackChunkName: 'brands' */
            '@/project/app/client-pages/BrandPage.vue'),
        props: (route) => ({
            brandName: route.params.brandName,
            mode: PageName.BRAND
        }),
        meta: {
            headMeta: {
                index: serverContextService.siteName !== SiteName.EetRetail
            },
            isPublic: true,
            skipAutoSeoTitle: true,
            skipAutoPageTracking: true
        } as RouterMetaData
    },
    {
        path: '/brands/:brandName/:brandSubContent',
        name: PageName.BRAND_SUBCONTENT,
        component: () => import(
            /* webpackChunkName: 'brands' */
            '@/project/app/client-pages/BrandPage.vue'),
        props: (route) => ({
            brandName: route.params.brandName,
            mode: PageName.BRAND_SUBCONTENT,
            brandSubContent: route.params.brandSubContent
        }),
        meta: {
            headMeta: {
                index: serverContextService.siteName !== SiteName.EetRetail
            },
            isPublic: true,
            skipAutoSeoTitle: true,
            skipAutoPageTracking: true
        } as RouterMetaData
    },
    {
        path: '/c-:categoryId',
        name: PageName.CATEGORY,
        component: () => import(/* webpackPrefetch: true */ '@/project/app/client-pages/CategoryPage.vue'),
        beforeEnter(to, from, next) {
            hardRedirect(to.fullPath);
        },
        meta: {
            isPublic: true
        } as RouterMetaData
    },
    {
        path: '/*/c-:categoryId',
        name: PageName.CATEGORY_2,
        component: () => import(/* webpackPrefetch: true */ '@/project/app/client-pages/CategoryPage.vue'),
        props: (route) => ({
            categoryId: route.params.categoryId,
            mode: PageName.CATEGORY
        }),
        meta: {
            headMeta: {
                index: serverContextService.siteName !== SiteName.EetRetail
            },
            isPublic: true,
            skipAutoPageTracking: true,
            skipAutoBreadcrumb: true,
            skipAutoSeoTitle: true
        } as RouterMetaData
    },
    {
        path: '/search/:term?',
        name: PageName.SEARCH,
        component: () => import(/* webpackPrefetch: true */ '@/project/app/client-pages/SearchPage.vue'),
        props: (route) => ({
        }),
        meta: {
            headMeta: {
                index: true
            },
            hideBreadcrumb: true,
            isPublic: true,
            skipAutoPageTracking: true,
            skipAutoBreadcrumb: true,
            skipAutoSeoTitle: true,
            transition: 'slide-right'
        } as RouterMetaData
    },
    {
        path: '/basket',
        name: PageName.BASKET,
        component: () => import(
            /* webpackPrefetch: true */
            '@/project/app/client-pages/basket-page/BasketPage.vue'),
        meta: {
            headMeta: {},
            isPublic: false,
            skipAutoPageTracking: true,
            breadcrumb: [
                {
                    name: dictionaryService.get('Basket'),
                    page: PageName.BASKET
                }
            ]
        } as RouterMetaData
    },
    {
        path: '/checkout',
        name: PageName.CHECKOUT,
        component: () => import(
            /* webpackPrefetch: true */
            '@/project/app/client-pages/checkout/CheckoutPage.vue'),
        meta: {
            isTunnelView: true,
            hideBreadcrumb: true,
            isPublic: false,
            skipAutoPageTracking: true
        } as RouterMetaData
    },
    {
        path: '/confirmation',
        name: PageName.CONFIRMATION,
        component: () => import(
            /* webpackPrefetch: true */
            '@/project/app/client-pages/confirmation/ConfirmationPage.vue'),
        meta: {
            hideBreadcrumb: true,
            isPublic: false,
            skipAutoPageTracking: true
        } as RouterMetaData
    },
    {
        path: '/404',
        name: 'notfound',
        component: () => import('@/project/app/content-pages/ContentPageRenderer.vue'),
        meta: {
            isContent: true,
            isPublic: true
        }

    },
    {
        path: '/500',
        name: 'errorpage',
        component: () => import('@/project/app/content-pages/ContentPageRenderer.vue'),
        meta: {
            isContent: true,
            isPublic: true
        }

    },
    {
        path: '/page/:pageId(\\d+)',
        name: 'pageRedirect',
        beforeEnter(to) { location.href = `/${siteService.getCulture()}/${to.params.pageId}`; },
        meta: {
            isPublic: true,
            skipAutoPageTracking: true
        } as RouterMetaData

    },
    {
        path: '/:pageId(\\d+)',
        name: 'pageIdRedirect',
        beforeEnter(to) { location.href = `/${siteService.getCulture()}/${to.params.pageId}`; },
        meta: {
            isPublic: true,
            skipAutoPageTracking: true
        } as RouterMetaData
    },
    {
        path: '/brand-product-guide-overlay',
        name: 'brandProductGuideOverlay',
        props: (route) => ({
            brandName: route.query.productGuideBrandName
        }),
        component: () => import('@/project/app/blocks/productguide/StepProductGuide/Overlay/BrandProductGuidePLPOverlay.vue'),
        meta: {
            isPublic: true,
            hideBreadcrumb: true,
            isTunnelView: true
        } as RouterMetaData
    }
];

if (isDevMode()) {
    GeneralRoutes.push({
        path: '/lokaltestside',
        name: PageName.KITCHEN_SINK,
        component: () => import(
            /* webpackChunkName: 'kitchensink' */
            '@/project/app/client-pages/KitchenSinkPage.vue'),
        children: [
            {
                path: '/lokaltestside/forms',
                name: PageName.KITCHEN_SINK_FORMELEMENTS,
                component: () => import('@/project/kitchensink/KitchenSinkForms.vue')
            },
            {
                path: '/lokaltestside/contentblocks',
                name: PageName.KITCHEN_SINK_CONTENTBLOCKS,
                component: () => import('@/project/kitchensink/KitchenSinkContentFAQ.vue')
            },
            {
                path: '/lokaltestside/productguide',
                name: PageName.KITCHEN_SINK_PRODUCTGUIDE_BRANDOVERVIEW,
                component: () => import('@/project/kitchensink/KitchenSinkNewProductGuideBrandBlocks.vue')
            },
            {
                path: '/lokaltestside/productguide/:brandName',
                name: PageName.KITCHEN_SINK_PRODUCTGUIDE_SEARCH,
                props: (route) => ({
                    brandName: route.params.brandName
                }),
                component: () => import('@/project/kitchensink/KitchenSinkNewProductGuideSearchBlocks.vue')
            }
        ]
    });
}

export { GeneralRoutes };
