const plugins = [
    // Tailwind themes
    require('./4-Objects/themes')(['responsive']),

    // Objects
    require('./4-Objects/aspect-ratio')(['responsive']),

    // Utilities
    require('./6-Utilities/anim-timing')(['responsive'])
];

module.exports = plugins;
