export enum PageName {
    FRONTPAGE = 'FrontPage',
    CONTENTPAGE = 'ContentPage',
    CATEGORY = 'Category',
    CATEGORY_2 = 'Category 2',
    CAMPAIGN = 'Campaigns',
    CAMPAIGN_2 = 'Campaign 2',
    CAMPAIGN_CATEGORY = 'Campaigns & category',
    CAMPAIGN_CATEGORY_2 = 'Campaigns & category 2',
    SEARCH = 'Search',
    PRODUCT_DETAILS = 'Productdetails',
    BASKET = 'Basket',
    BRAND = 'Brand',
    BRAND_SUBCONTENT = 'Brand Content',
    BRAND_OVERVIEW = 'Brands',
    CHECKOUT = 'Checkout',
    CONFIRMATION = 'Confirmation',
    RACK_CONFIGURATOR = 'Rack Configurator',
    KITCHEN_SINK = 'lokaltestside',
    KITCHEN_SINK_FORMELEMENTS = 'lokaltestside Forms',
    KITCHEN_SINK_PRODUCTGUIDE_BRANDOVERVIEW = 'lokaltestside Product Guide Brands',
    KITCHEN_SINK_PRODUCTGUIDE_SEARCH = 'lokaltestside Product Guide Search',
    KITCHEN_SINK_CONTENTBLOCKS = 'lokaltestside content blocks',
    MY_ACCOUNT = 'My account',
    MY_ACCOUNT_PROFILE = 'Profile',
    MY_ACCOUNT_ORDER_HISTORY = 'Order history',
    MY_ACCOUNT_ORDER_HISTORY_DETAIL = 'Order detail',
    MY_ACCOUNT_PRODUCT_BACKLOG = 'Product backlog',
    MY_ACCOUNT_FAVOURITES = 'Favourites',
    MY_ACCOUNT_FAVOURITES_DETAIL = 'Favourites detail',
    MY_ACCOUNT_QUOTES = 'Quotes',
    MY_ACCOUNT_QUOTES_DETAIL = 'Quotes detail',
    MY_ACCOUNT_BLANKET_ORDERS = 'Blanket orders',
    MY_ACCOUNT_BLANKET_DETAIL = 'Blanket detail',
    MY_ACCOUNT_BIDS = 'Bids',
    MY_ACCOUNT_RMA_RETURN = 'RMA & returns',
    MY_ACCOUNT_RMA_RETURN_HISTORY = 'RMA history',
    MY_ACCOUNT_RMA_RETURN_HISTORY_DETAIL = 'RMA history detail',
    MY_ACCOUNT_RMA_RETURN_CREATE = 'Create RMA',
    MY_ACCOUNT_LEDGER = 'Ledger',
    MY_ACCOUNT_ACCOUNT_SETTINGS = 'Account profile',
    MY_ACCOUNT_ADDRESS_BOOK = 'Address book',
    MY_ACCOUNT_USER_ADMINISTRATION = 'User administration',
    MY_ACCOUNT_IMPERSONATION = 'Impersonation',
    MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION = 'Product Guide Configurations',
    MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION_NEW = 'Product Guide Configurations New',
    MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION_EDIT = 'Product Guide Configurations Edit',
    MY_ACCOUNT_NEXT_DELIVERY = 'Next delivery',
    MY_ACCOUNT_NEXT_DELIVERY_COUNTRY = 'Next delivery administration',
    MY_ACCOUNT_RACK_CONFIGURATIONS = 'Rack configurations',
};

export const ContentPageRenderedEventKey = 'ContentPageRendered';
export const PageRenderedEventKey = 'PageRendered';
