










import { Vue, Component } from 'vue-property-decorator';
import { ModalState } from '@/core/modals/ModalState';
import ModalOverlay from './ModalOverlay.vue';

@Component({
    components: { ModalOverlay }
})
export default class ModalsContainer extends Vue {
    get modals() {
        return ModalState.modals;
    }
}
