






















import Vue from 'vue';
import { Component, Prop, Model } from 'vue-property-decorator';

/*
Inspired by this: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */
@Component
export default class InputRadio extends Vue {
    @Prop({
        type: String,
        default: ' '
    }) label!: string;

    @Prop(String) name!: string;
    @Prop(Boolean) white!: boolean;

    @Prop([String, Number, Boolean, Object]) value!: string | number | boolean | object;

    @Prop(String)
    icon!: string;

    @Prop({ type: Boolean, required: false, default: true })
    iconIsAfter!: boolean;

    @Prop(String)
    labelClass!: string;

    @Model('change')
    readonly modelValue!: string | number | boolean | object;

    get id(): string {
        return this.name + Math.random();
    }

    get iconName() {
        return this.icon?.toLowerCase();
    }

    get hasIconBefore(): boolean {
        return !!this.icon && !this.iconIsAfter;
    }

    get hasIconAfter(): boolean {
        return !!this.icon && this.iconIsAfter;
    }

    updateValue(e) {
        this.$emit('change', this.value);
    }

    get shouldBeChecked() {
        return this.modelValue === this.value;
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { change, ...listeners } = this.$listeners;
        return listeners;
    }
}
