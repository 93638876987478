

























































import { Vue, Component } from 'vue-property-decorator';
import megaMenuService from '@/project/shared/megaMenu.service';
import { MenuItem } from '@/types/apiServerContract';
import MainNavigationSlider from './MainNavigationSlider.vue';
import ReadMore from '@/core/ui-components/ReadMore.vue';
import scrollService from '@/core/scroll/scroll.service';
import escEventService from '@/core/esc-event.service';
import { debounce } from 'lodash';
import themeService from '@/project/app/themes/theme.service';

// Delay in ms controlling megamenu, so it won't be triggered too eager
const SHOW_DELAY = 50;
const CLOSE_DELAY = 100;
const CHANGE_DELAY = 350;

@Component({
    components: {
        MainNavigationSlider,
        ReadMore
    }
})
export default class MainNavigation extends Vue {
    megaMenuShown = false;
    menus: readonly MenuItem[] | null = [];
    activeMenu: MenuItem | null = null;

    openMegaMenuDebounce = debounce(this.open, SHOW_DELAY);
    closeMegaMenuDebounce = debounce(this.close, CLOSE_DELAY);
    changeMegaMenuDebounce = debounce(this.changeMegaMenu, CHANGE_DELAY);

    changeMegaMenu(menu: MenuItem) {
        this.activeMenu = menu;

        // If mega menu is hidden, no reason to debounce, show it instantly
        if (!this.megaMenuShown) {
            this.open();
        }
    }

    cancelMegaMenuNavigation() {
        this.changeMegaMenuDebounce.cancel();
    }

    cancelMegaMenuClosing() {
        this.closeMegaMenuDebounce.cancel();
    }

    toggleMegaMenu(show: boolean): void {
        if (show) {
            this.closeMegaMenuDebounce.cancel();
            this.openMegaMenuDebounce();
        } else {
            this.openMegaMenuDebounce.cancel();
            this.closeMegaMenuDebounce();
        }
    }

    open() {
        if (this.megaMenuShown) return;

        this.megaMenuShown = true;
        this.$emit('megaMenuShown', true);

        // megaMenuContainer needs to be in DOM first
        this.$nextTick(() => scrollService.disableBodyScroll(this.$refs.megaMenuContainer));

        escEventService.register(this.close);
    }

    close() {
        if (!this.megaMenuShown) return;

        scrollService.enableBodyScroll(this.$refs.megaMenuContainer);

        this.megaMenuShown = false;
        this.$emit('megaMenuShown', false);

        escEventService.unregister(this.close);
    }

    async getMegaMenuData() {
        this.menus = await megaMenuService.getMegaMenu();
    }

    megaMenuStyle(): object {
        const top = this.$refs.megaMenuAnchor.getBoundingClientRect().bottom;
        return ({
            maxHeight: `calc(100vh - ${top}px)`
        });
    }

    created() {
        this.getMegaMenuData();
    }

    $refs!: {
        megaMenuContainer: HTMLElement;
        megaMenuAnchor: HTMLElement;
    };

    get navigationStyle(): Partial<CSSStyleDeclaration> {
        return {
            ...(themeService.theme?.menuColor && { backgroundColor: themeService.theme.menuColor }),
            ...(themeService.theme?.menuColor && { color: themeService.getColor(themeService.theme.menuColor) })
        };
    }
}
