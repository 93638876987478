/**
 * All configuration, variables etc. is kept in tailwind.js
 * Global variables for use in both plugins, tailwind and less are defined in ./variables.js (this file) and used with the config('variables.variabelName') method provided by tailwind.
 *
 */

const getVariables = tailwind => {
    return {};
};

module.exports = getVariables;
