import { debounce } from 'lodash';
import Deferred from '@/core/async/Deferred';
import Api from '@/project/http/Api.service';
import { AdContentBlockViewModel } from '@/types/apiServerContract';
import { localStorageService } from '@/core/storage.service';
import UUID from '@/core/uuid.service';
import bus from '@/core/bus';
import { UserData } from '@/core/auth/token.service';
import { TOKEN_DATA_CHANGED } from '@/core/auth/auth.service';
import cookieService from '@/core/cookie.service';

interface AdnuntiusKeys {
    contactKey: string,
    customerKey?: string
}

const LOCAL_STORAGE_KEY = 'eetgroup-eet-adnuntius';
const debouncedGetPersonalizedContent = debounce(getPersonalizedContentFromServer, 200); // There should be time enough so all blocks on page has been created

let keys: AdnuntiusKeys = {
    contactKey: ''
};

init();

bus.on(TOKEN_DATA_CHANGED, (userData: UserData | null) => {
    if (userData) {
        setKeys(userData.contactHash, userData.customerKey); // Using contactHash instead of contactKey is correct. #2414. CHE.
    }
});

function init() {
    let tmpKeys = localStorageService.getItemAs<AdnuntiusKeys>(LOCAL_STORAGE_KEY);
    if (!tmpKeys || contactKeyIsAnonymousGuid(tmpKeys.contactKey)) {
        tmpKeys = {
            contactKey: getAnonymousContactKey()
        };
        setKeys(tmpKeys.contactKey);
    } else {
        keys = tmpKeys;
    }
}

function contactKeyIsAnonymousGuid(contactKey: string) {
    return contactKey.startsWith('temp');
}

function getAnonymousContactKey(): string {
    if (keys.contactKey && !contactKeyIsAnonymousGuid(keys.contactKey)) {
        // Probably lytics id already set
        return keys.contactKey;
    }

    // Else no value or temp value - try to get lytics cookie
    const lyticsCookie = cookieService.get('seerid'); // Lytics uid.
    if (lyticsCookie) {
        return lyticsCookie;
    }

    // If no key at all set to temporary - until we read lytics cookie
    if (!keys.contactKey) return 'temp_' + UUID();

    // Return already set temp key
    return keys.contactKey;
}

function setKeys(contactKey: string, customerKey?: string): void {
    keys = {
        contactKey,
        customerKey
    };
    localStorageService.setItemAs<AdnuntiusKeys>(LOCAL_STORAGE_KEY, keys);
}

function getContactKey(): string {
    if (contactKeyIsAnonymousGuid(keys.contactKey)) {
        // Need to update if we were using tmp guid and lytics cookie now present.
        const anonymousContactKey = getAnonymousContactKey();
        if (keys.contactKey !== anonymousContactKey) {
            setKeys(anonymousContactKey, keys.customerKey);
        }
    }
    return keys.contactKey;
}

function getCustomerKey(): string | undefined {
    return keys.customerKey;
}

// Get all adnuntius data for blocks for one url in one go.
let _currentUrl;
const outstandingRequests: Map<string, Deferred<AdContentBlockViewModel>[]> =
    new Map<string, Deferred<AdContentBlockViewModel>[]>();
function getBundledPersonalizedContent(adUnitId: string, currentUrl: string): Promise<AdContentBlockViewModel> {
    if (currentUrl !== _currentUrl) {
        _currentUrl = currentUrl;
        cleanUpOutstanding();
    }
    let deferreds = outstandingRequests.get(adUnitId);
    if (!deferreds) {
        deferreds = [];
        outstandingRequests.set(adUnitId, deferreds);
    }
    const deferred = new Deferred<AdContentBlockViewModel>();
    deferreds.push(deferred);
    debouncedGetPersonalizedContent();
    return deferred.promise;
}

function getPersonalizedContentFromServer(): void {
    // There shouldn't be requests for more than url at a time, but
    const ids = Array.from(outstandingRequests.keys());
    Api.personalization.personalizedContent(ids, _currentUrl, getContactKey(), getCustomerKey())
        .then(adnuntiusBlockData => {
            adnuntiusBlockData.forEach(adnuntiusBlock => {
                const deferredsForId = adnuntiusBlock.adUnitId && outstandingRequests.get(adnuntiusBlock.adUnitId);
                deferredsForId && deferredsForId.forEach(deferred => deferred.resolve(adnuntiusBlock));
            });
            // Will leave resolved promises resolved and reject rest.
            cleanUpOutstanding();
        })
        .catch(() => {
            cleanUpOutstanding();
        });
}

function cleanUpOutstanding() {
    outstandingRequests.forEach(deferreds => {
        deferreds.forEach(d => d.reject());
    });
    outstandingRequests.clear();
}

export default {
    getContactKey,
    getCustomerKey,
    getBundledPersonalizedContent
};
