import Vue from 'vue';
import InputText from './InputText.vue';
import InputNumber from './InputNumber.vue';
import InputTextArea from './InputTextArea.vue';
import InputSelect from './InputSelect.vue';
import InputRadio from './InputRadio.vue';
import InputCheckbox from './InputCheckbox.vue';
import InputFile from './InputFile.vue';
import CTA from './CallToAction.vue';

import './vee-validate.plugin';
Vue.component('CallToAction', CTA);
Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('InputTextArea', InputTextArea);
Vue.component('InputSelect', InputSelect);
Vue.component('InputRadio', InputRadio);
Vue.component('InputCheckbox', InputCheckbox);
Vue.component('InputFile', InputFile);
