import { RedirectError } from '@/core/http/RedirectError';
import { JL } from 'jsnlog';
import { isDevMode } from '@/core/environmentService';
const consoleLogsEnabled = location.search.includes('enable-console-logs=true') || isDevMode();
const serverLogsEnabled = location.search.includes('enable-server-logs=true') || window.servercontext?.siteChrome?.settings?.enableClientSideLogging;
const jl = JL();

class LoggingService {
    public debug(logObject: any): void {
        // eslint-disable-next-line
        this.consoleLogging(() => console.log(logObject));
        this.serverLogging(() => jl.debug(logObject));
    }

    public info(logObject: any) {
        // eslint-disable-next-line
        this.consoleLogging(() => console.info(logObject));
        this.serverLogging(() => jl.info(logObject));
    }

    public warn(logObject: any) {
        // eslint-disable-next-line
        this.consoleLogging(() => console.warn(logObject));
        this.serverLogging(() => jl.warn(logObject));
    }

    public error(logObject: any) {
        if (isWhitelisted(logObject)) return;

        // eslint-disable-next-line
        this.consoleLogging(() => console.error(logObject));
        this.serverLogging(() => jl.error(logObject));

        function isWhitelisted(logObject: any) {
            return !!(logObject && logObject instanceof RedirectError);
        }
    }

    public fatal(logObject: any) {
        // eslint-disable-next-line
        this.consoleLogging(() => console.error(logObject));
        this.serverLogging(() => jl.fatal(logObject));
    }

    public exception(logObject: any, e: any) {
        // eslint-disable-next-line
        this.consoleLogging(() => console.error(logObject, e));
        this.serverLogging(() => {
            try {
                jl.fatalException(logObject, e);
            } catch {
                // It has been seen that an object (e.g. internal vue object cannot be serialized due to circular refs.)
                jl.fatal(logObject);
            }
        });
    }

    private consoleLogging(func: () => void) {
        if (consoleLogsEnabled) {
            func();
        }
    }

    private serverLogging(func: () => void) {
        if (serverLogsEnabled) {
            func();
        }
    }
}

export default new LoggingService();
