export type UrlFacets = { [key: string]: string[] };
export enum UrlQueryKey {
    termKey = 'term',
    searchWithinResultKey = 'searchWithinResult',
    pageKey = 'page',
    sortByKey = 'sortBy',
    searchFilterKey = 'filter',
    searchPriceSortKey = 'priceSort',
    categoryKey = 'category',
    categoryIdKey = 'categoryId',
    categoryIdsKey = 'categoryIds',
    campaignKey = 'campaignCode',
    lastFacetKey = 'lastFacet',
    showScoreKey = 'showscore',
    showExplanationKey = 'explain',
    showRawKey = 'raw',
    labelsdebug = 'labelsdebug',
    productGuideGuideId = 'guideId',
    productGuideProductPageSize = 'productPageSize',
    productGuideModeKey = 'pg-mode',
    productGuideBrandKey = 'brand',
    productGuideSeriesKey = 'series',
    productGuideModelKey = 'model',
    productGuideSearchKey = 'search',
    productGuideExternalId = 'externalId',
    productGuideId = 'productGuideId',
    productGuideKey = 'productGuideKey',
    productGuideBrandName = 'productGuideBrandName',
    productGuideOverrideProductUrl = 'overrideProductUrl',
    productGuideExternalButtonText = 'buttonText',
    productGuideExternalButtonLink = 'buttonLink',
    cableGuideCableTypeKey = 'cableType',
    cableGuideCableEndAKey = 'cableEndA',
    cableGuideCableEndBKey = 'cableEndB',
    productGuideConfigurationStep = 'configStep',
    origin = 'origin',
    theme = 'theme',
    hideStock = 'hideStock',
    hideFacets = 'hideFacets',
    wid = 'wid',
    css = 'css',
    bidsKey = 'bids',
    bidsWebCategoriesKey = 'webcategories',
    bidsBrandKey = 'brands',
    language = 'language',
    PunchOutSession = 'punchOutSession',
    RackType = 'rackType',
    RackProductId = 'rackProductId',

    // External queries e.g. google and facebook
    // eslint-disable-next-line camelcase
    utm_source = 'utm_source',
    // eslint-disable-next-line camelcase
    utm_medium = 'utm_medium',
    // eslint-disable-next-line camelcase
    utm_campaign = 'utm_campaign',
    // eslint-disable-next-line camelcase
    utm_content = 'utm_content',
    // eslint-disable-next-line camelcase
    utm_id = 'utm_id',
    // eslint-disable-next-line camelcase
    utm_source_platform = 'utm_source_platform',
    // eslint-disable-next-line camelcase
    utm_campaign_id = 'utm_campaign_id',
    // eslint-disable-next-line camelcase
    utm_term = 'utm_term',
    // eslint-disable-next-line camelcase
    utm_creative_format = 'utm_creative_format',
    // eslint-disable-next-line camelcase
    utm_marketing_tactic = 'utm_marketing_tactic',
    fbclid = 'fbclid',
    gclid = 'gclid',
    rckh = 'rckh'
}
export const WID_REGEX_LOCATOR = /[wW][A-Za-z0-9]{9}/gi;
