import keyboardService from './keyboard.service';

type EscEventListener = (() => void);

class EscEventService {
    private _escStack: Set<EscEventListener> = new Set<EscEventListener>();

    public register(listener: EscEventListener) {
        this._escStack.add(listener);
    }

    public unregister(listener: EscEventListener) {
        this._escStack.delete(listener);
    }

    private handleEsc(e: KeyboardEvent) {
        if (keyboardService.isEscape(e)) {
            const lastListener = Array.from(this._escStack).pop();
            if (lastListener) {
                lastListener();
            }
        }
    }

    constructor() {
        document.addEventListener('keyup', (e) => this.handleEsc(e));
    }
}

export default new EscEventService();
