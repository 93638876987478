function optionalProperty(obj: any, ...args: string[]) {
    const res = args.reduce((obj, level) => obj && obj[level], obj);
    return res || '';
}
export default {
    install(Vue) {
        Vue.prototype.$optionalProperty = { get: optionalProperty };
    }
};
declare module 'vue/types/vue' {
    interface Vue {
        $optionalProperty: { get: (obj: any, ...args: string[]) => string };
    }
}
