import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { CompanySearchViewModel } from '@/types/apiServerContract';

export class CvrSearchController {
    private readonly urlFactory = createUrlFactory('CvrSearch');

    public async searchCompanies(vatNumber: string, countryCode: string, crnNumber?:string, messageId?:string): Promise<CompanySearchViewModel> {
        return httpService.get(this.urlFactory('SearchCompanies'), { vatNumber, crnNumber, countryCode }, undefined, undefined, messageId);
    }
}
