import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    AddressViewObject,
    CustomerBrandTilesViewModel,
    CustomerViewModel,
    ShipToCountriesViewModel
} from '@/types/apiServerContract';

export class CustomerController {
    readonly urlFactory = createUrlFactory('Customer');

    public async customer(): Promise<CustomerViewModel> {
        return httpService.get(this.urlFactory('Customer'));
    }

    public async shipToCountries(): Promise<ShipToCountriesViewModel> {
        return httpService.get(this.urlFactory('ShipToCountries'));
    }

    public async brandTiles(department: string | null): Promise<CustomerBrandTilesViewModel> {
        return httpService.post(this.urlFactory('BrandTiles'), {
            department
        });
    }

    public async getInvoiceAddressForCustomer(customerNo: string): Promise<AddressViewObject> {
        return httpService.get(this.urlFactory('GetInvoiceAddressForCustomer'), {
            customerNo
        });
    }
}
