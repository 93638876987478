import Vue from 'vue';
import domService from '@/core/dom/dom.service';
import botService from '@/project/site/bot.service';

const timeout = 1000;

Vue.directive('prohibit-zoom', {
    bind: function(el: HTMLElement) {
        // For SEO purpose, dont bind if bot or prerender
        if (botService.uaIsPrerender() || botService.uaIsBot()) return;

        let touchTimer;
        let blurTimer;
        const standardMeta = domService.getMetaViewport();
        const unscalableMeta = standardMeta + ', maximum-scale=1.0, user-scalable=0';

        el.ontouchstart = touchstartHandler;
        el.onfocus = focusHandler;
        el.onblur = blurHandler;

        function touchstartHandler() {
            clearTimeout(touchTimer);
            domService.setMetaViewport(unscalableMeta);
            touchTimer = setTimeout(() => {
                domService.setMetaViewport(standardMeta);
            }, timeout);
        }

        function focusHandler() {
            clearTimeout(touchTimer);
            domService.setMetaViewport(unscalableMeta);
        }

        function blurHandler() {
            clearTimeout(blurTimer);
            domService.setMetaViewport(unscalableMeta);
            blurTimer = setTimeout(() => {
                domService.setMetaViewport(standardMeta);
            }, timeout);
        }
    }
});
