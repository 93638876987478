import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { CheckoutViewModel, ReceiptViewModel, OrderViewModel } from '@/types/apiServerContract';

export class CheckoutController {
    private readonly urlFactory = createUrlFactory('Checkout');

    public async get(): Promise<CheckoutViewModel> {
        return httpService.get(this.urlFactory('Get'));
    }

    async receipt(uniqueIdentifier: string): Promise<ReceiptViewModel> {
        return httpService.get(this.urlFactory('Receipt'), { uniqueIdentifier });
    }

    public async submitOrder(terminal: string): Promise<OrderViewModel> {
        return httpService.post(this.urlFactory('SubmitOrder'), { terminal });
    }
}
