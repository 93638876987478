import domMutation from './domMutation.service';
import $ from 'cash-dom';

export interface Viewport {
    width: number;
    height: number;
    scrollX: number;
    scrollY: number;
    scrollChange: boolean,
    sizeChange: boolean
}

class ViewportEventsService {
    private ticking = false;
    private tickCallbacks: Array<(viewport: Viewport, timestamp: number) => void> = [];
    public viewport: Viewport = {
        width: this.getWindowWidth(),
        height: window.innerHeight,
        scrollX: this.getPageOffset('x'),
        scrollY: this.getPageOffset('y'),
        scrollChange: true,
        sizeChange: true
    };

    constructor() {
        $(window)
            .on('scroll', this.scrollHandler.bind(this))
            .on('resize', this.resizeHandler.bind(this))
            .on('orientationchange', this.resizeHandler.bind(this));

        domMutation.observe(document, this.requestTick.bind(this));
        this.requestTick();
    }

    public setCallback(callback: (viewport: Viewport, timestamp: number) => void) {
        this.tickCallbacks.push(callback);
        return callback;
    }

    public removeCallback(callback: (viewport: Viewport, timestamp: number) => void) {
        const index = this.tickCallbacks.indexOf(callback);
        this.tickCallbacks.splice(index, 1);
    }

    public getPageOffset(axis) {
        return axis === 'y'
            ? window.pageYOffset || document.documentElement!.scrollTop
            : window.pageXOffset || document.documentElement!.scrollLeft;
    }

    private tick(timestamp: number) {
        this.ticking = false;
        this.tickCallbacks.forEach(callback => callback && callback.call(callback, this.viewport, timestamp));
    }

    private requestTick() {
        if (!this.ticking) {
            requestAnimationFrame(this.tick.bind(this));
        }
        this.ticking = true;
    }

    private scrollHandler() {
        this.viewport.scrollX = this.getPageOffset('x');
        this.viewport.scrollY = this.getPageOffset('y');
        this.viewport.scrollChange = true;
        this.viewport.sizeChange = false;
        this.requestTick();
    }

    private resizeHandler() {
        this.viewport.width = this.getWindowWidth();
        this.viewport.height = window.innerHeight;
        this.viewport.scrollChange = false;
        this.viewport.sizeChange = true;
        this.requestTick();
    }

    private getWindowWidth() {
        return Math.max(window.innerWidth, window.document.documentElement!.clientWidth);
    }
}

export default new ViewportEventsService();
