







import { Vue, Prop, Component } from 'vue-property-decorator';
import Api from '@/project/http/Api.service';

const CLEARANCE_CODE = 'clearance';

@Component
export default class CampaignIcon extends Vue {
    @Prop({ required: true, type: String })
    campaignCode!: string;

    campaignIconUrl: string | null = null;

    async getCampaignWithIcon() {
        // This icon is a local SVG
        if (!this.campaignCode || this.campaignCode.toLowerCase() === CLEARANCE_CODE) return;

        try {
            const campaign = await Api.product.getCampaignWithIcon(this.campaignCode);
            this.campaignIconUrl = campaign.campaignIconUrl ?? null;
        } catch {
            // not found
        }
    }

    created() {
        this.getCampaignWithIcon();
    }
}
