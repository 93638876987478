import { ProductGuideConfiguration } from '@/types/apiServerContract';
import { UrlQueryKey } from '@/core/urlQueryKey';

type ProductGuideName = 'productGuide' | 'dellGuide' | 'lampGuide' | 'memoryGuide' | 'mobileGuide' | 'posGuide' | 'samsungGuide';

export enum ProductGuideModes {
    StepByStep = 'Step-by-step',
    Search = 'Search'
}

export enum ProductGuideActions {
    selectBrand = 'selectBrand',
    selectSeries = 'selectSeries',
    selectModel = 'selectModel'
}

interface ProductGuideTracking {
    guideMode: ProductGuideModes,
    action: ProductGuideActions,
    actionValue: string | undefined
}

export interface ProductGuideTrackingInformation extends ProductGuideTracking {
    guideId: string
}
export enum ProductGuideType {
    'All'= 'All',
    'Cable' = 'Cable'
}
export enum ProductGuideTheme {
    'Blue'= 'Blue',
    'Black' = 'Black',
    'Red' = 'Red',
    'Green' = 'Green'
}
interface ProductGuideTrackingDataModel extends ProductGuideTracking {
    event: string,
    guideName: ProductGuideName,
    actionValue: string
}
export interface ProductGuideConfigurationFrontendType extends ProductGuideConfiguration {
    type: ProductGuideType,
    startPage: 'search' | 'step-by-step',
    theme: ProductGuideTheme,
    externalId: string,
    name: string,
    fullName: string,
    email: string,
    websiteUrl: string,
    allowedBrands:string[]
}

function getGuideNameById(guideId: string): ProductGuideName | null {
    switch (guideId) {
    case 'All':
        return 'productGuide';
    case 'Dell':
        return 'dellGuide';
    case 'Lamp1':
        return 'lampGuide';
    case 'mm1582d':
        return 'memoryGuide';
    case 'Mobile':
        return 'mobileGuide';
    case 'Pos':
        return 'posGuide';
    case 'Samsung':
        return 'samsungGuide';
    default:
        return null;
    }
}

export function generateProductGuideTrackingData(model: ProductGuideTrackingInformation): ProductGuideTrackingDataModel | void {
    if (!model.actionValue) return;
    const guideNameFromId = getGuideNameById(model.guideId);

    if (!guideNameFromId) return;
    return {
        event: 'guide.selection',
        guideName: guideNameFromId,
        guideMode: model.guideMode,
        action: model.action,
        actionValue: model.actionValue
    };
}
export function baseIframeUrl() {
    const origin = document.location.origin;
    return new URL(`${origin}${window.servercontext.url}/external-guides/productguide`);
}
export function generateIframeURLFromConfiguration(configuration: ProductGuideConfigurationFrontendType, iframe?: HTMLIFrameElement): string {
    const url = baseIframeUrl();

    if (iframe && iframe.contentWindow) {
        const currentParams = new URL(iframe.contentWindow.location.href).searchParams;
        currentParams.forEach((v, k) => {
            url.searchParams.append(k, v);
        });
    }

    url.searchParams.set(UrlQueryKey.productGuideGuideId, configuration.type);

    const isProductGuide = configuration.type === 'All';
    if (isProductGuide) {
        url.searchParams.set(UrlQueryKey.productGuideModeKey, configuration.startPage);
    }

    if (configuration.language) {
        url.searchParams.set('language', configuration.language);
    } else {
        url.searchParams.delete('language');
    }

    if (configuration.theme) {
        const theme = configuration.invertTheme ? `invert-${configuration.theme}` : configuration.theme;
        url.searchParams.set(UrlQueryKey.theme, theme.toLowerCase());
    } else {
        url.searchParams.delete(UrlQueryKey.theme);
    }

    if (configuration.hideStock) {
        url.searchParams.set(UrlQueryKey.hideStock, 'true');
    } else {
        url.searchParams.delete(UrlQueryKey.hideStock);
    }

    if (!configuration.showFacets) {
        url.searchParams.set(UrlQueryKey.hideFacets, 'true');
    } else {
        url.searchParams.delete(UrlQueryKey.hideFacets);
    }

    if (configuration.externalStylesheet) {
        url.searchParams.set('css', configuration.externalStylesheet);
    } else {
        url.searchParams.delete('css');
    }

    if (configuration.externalProductLink) {
        url.searchParams.set('buttonLink', configuration.externalProductLink);
    } else {
        url.searchParams.delete('buttonLink');
    }

    if (configuration.externalProductLinkText) {
        url.searchParams.set('buttonText', configuration.externalProductLinkText);
    } else {
        url.searchParams.delete('buttonText');
    }

    if (configuration.externalId) {
        url.searchParams.set('externalId', configuration.externalId);
    } else {
        url.searchParams.delete('externalId');
    }

    return url.toString();
}
export function finalIframeUrl(configuration: ProductGuideConfigurationFrontendType):string {
    const url = baseIframeUrl();

    if (configuration.externalId) {
        url.searchParams.set('externalId', configuration.externalId);
    }

    url.searchParams.set(UrlQueryKey.productGuideGuideId, configuration.type.toLowerCase());

    return url.toString();
}
export function iframeHtml(url): string {
    return `<iframe src="${url}" width="100%" height="800"></iframe>`;
}
