import httpService from '@/core/http/http.service';
import { createUrlFactory, shouldExclude } from '@/project/http/ApiHelper.service';
import { CategoryTreeViewModel, FacetsViewModel, ProductExportRequest, ProductIdsViewModel, SearchRequest, SearchViewModel } from '@/types/apiServerContract';
import { isString, omitBy } from 'lodash';

import { UrlFacets } from '@/core/urlQueryKey';
import serverContextService from '@/core/serverContext.service';
import dictionaryService from '@/core/translation/dictionary.service';
import { SearchBlockViewModel } from '@/types/contentServerContract';

export class SearchController {
    readonly urlFactory = createUrlFactory('Search');

    public async search(searchRequest: SearchRequest, selectedFacets: UrlFacets, contextKey: string): Promise<SearchViewModel> {
        // Remove parameters that are empty, null or undefined
        const params = omitBy({ ...searchRequest, ...selectedFacets }, shouldExclude);
        return httpService.get(this.urlFactory('Search'), params, contextKey);
    }

    public async facets(searchRequest: SearchRequest, selectedFacets: UrlFacets, contextKey: string): Promise<FacetsViewModel> {
        // Remove parameters that are empty, null or undefined
        const params = omitBy({ ...searchRequest, ...selectedFacets }, shouldExclude);
        return httpService.get(this.urlFactory('Facets'), params, contextKey);
    }

    public async raw(searchRequest: SearchRequest, selectedFacets: UrlFacets): Promise<string> {
        // Remove parameters that are empty, null or undefined
        const params = omitBy({ ...searchRequest, ...selectedFacets }, shouldExclude);
        return httpService.get(this.urlFactory('Raw'), params);
    }

    public async clearBoostCache(): Promise<string> {
        return httpService.get(this.urlFactory('ClearBoostCache'));
    }

    public async productIds(searchRequest: SearchRequest, selectedFacets: UrlFacets): Promise<ProductIdsViewModel> {
        // Remove parameters that are empty, null or undefined
        const params = omitBy({ ...searchRequest, ...selectedFacets }, shouldExclude);
        return httpService.get(this.urlFactory('GetProductIds'), params);
    }

    public async categories(searchRequest: SearchRequest, selectedFacets: UrlFacets, contextKey: string): Promise<CategoryTreeViewModel> {
        // Remove parameters that are empty, null or undefined
        const params = omitBy({ ...searchRequest, ...selectedFacets }, shouldExclude);
        return httpService.get(this.urlFactory('Categories'), params, contextKey);
    }

    public async requestProductExport(searchRequest: SearchRequest, productExportRequest: ProductExportRequest, selectedFacets: UrlFacets): Promise<void> {
        // Remove parameters that are empty, null or undefined
        const params = omitBy({ ...searchRequest, ...selectedFacets, ...productExportRequest }, shouldExclude);
        return httpService.get(this.urlFactory('RequestProductExport'), params);
    }

    public async zeroSearchResultContent(searchTerm: string): Promise<SearchBlockViewModel> {
        const url = `${serverContextService.contentHost}/umbraco/api/ZeroSearchPageApi/GetZeroSearchPage?culture=${serverContextService.siteInfo.culture}&searchTerm=${searchTerm}&siteName=${serverContextService.siteName}`;
        const request: Promise<SearchBlockViewModel> = httpService.getRaw(url);

        // Replace search term in content
        request.then((response) => {
            if (response && response?.heading) {
                response.heading = dictionaryService.formatNamed(response.heading, { searchTerm: response.sanitizedSearchTerm });
            }
            if (response && response?.contentBlocks) {
                response.contentBlocks.forEach((contentBlock: any) => {
                    const contentFieldsKeysAllowed = [
                        // Simple types
                        'heading', 'header', 'headline', 'caption', 'subHeader', 'subheader', 'name', 'description', 'shortText', 'bodyText',
                        // Links
                        'callToAction', 'link',
                        // Blocks / arrays
                        'uniqueSellingPointItems', 'halfWidthBlocks', 'content', 'tabbedContentBlock', 'categoryTileBlock',
                        'tabs', 'tiles', 'blocks'
                    ];
                    if (contentBlock?.content) {
                        Object.keys(contentBlock.content).forEach((key) => {
                            if (contentFieldsKeysAllowed.includes(key)) {
                                contentBlock.content[key] = formatContentFieldWithSearchTerm(contentBlock.content[key], response.sanitizedSearchTerm, contentFieldsKeysAllowed);
                            }
                        });
                    }
                });
            }
        });
        function formatContentFieldWithSearchTerm(contentField: unknown, searchTerm, allowedKeys: string[]): any {
            if (!contentField || !searchTerm) return contentField;
            if (Array.isArray(contentField)) {
                return contentField.map((item) => formatContentFieldWithSearchTerm(item, searchTerm, allowedKeys));
            } else if (typeof contentField === 'object' && contentField !== null) {
                const contentFieldObject = contentField as any;
                Object.keys(contentFieldObject).forEach((key) => {
                    if (allowedKeys.includes(key) && contentFieldObject[key]) {
                        contentFieldObject[key] = formatContentFieldWithSearchTerm(contentFieldObject[key], searchTerm, allowedKeys);
                    }
                });
                return contentFieldObject;
            } else if (isString(contentField)) {
                return dictionaryService.formatNamed(contentField, { searchTerm });
            }
            return contentField;
        }
        return request;
    }
}
