import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { SearchImpersonationViewModel, BeginImpersonationRequest, ImpersonationViewModel } from '@/types/apiServerContract';

export class ImpersonationController {
    private readonly urlFactory = createUrlFactory('Impersonation');

    public async beginImpersonation(userId: number, messagesId: string): Promise<ImpersonationViewModel> {
        return httpService.post(this.urlFactory('BeginImpersonation'), {
            userId
        } as BeginImpersonationRequest, messagesId);
    }

    public async endImpersonation(): Promise<ImpersonationViewModel> {
        return httpService.post(this.urlFactory('EndImpersonation'));
    }

    public async search(term: string): Promise<SearchImpersonationViewModel> {
        return httpService.get(this.urlFactory('Search'), {
            term
        }, 'impersonate');
    }
}
