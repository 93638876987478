













import { Vue, Prop, Component } from 'vue-property-decorator';
import { ImageViewObject } from '@/types/apiServerContract';
import ResponsiveProductImage from '@/project/product/image/ResponsiveProductImage.vue';
import serverContextService from '@/core/serverContext.service';

@Component({
    components: { ResponsiveProductImage }
})
export default class ProductTileImage extends Vue {
    @Prop({ required: true }) product!: { image?: ImageViewObject, url?: string, mpn?: string, itemId: string, displayName?: string };
    @Prop({ required: false, type: Function, default: () => {} }) productClick!: () => void;
    @Prop({ required: false, type: String, default: 'vertical' }) mode!: 'vertical' | 'horizontal' | 'horizontal-tiny';
    @Prop({ required: false }) indexInProductList!: number;

    get imageSize(): string {
        switch (this.mode) {
        case 'horizontal': return '86';
        case 'horizontal-tiny': return '86';
        default: return '200';
        }
    }

    get thumbnailImage(): string {
        return this.product?.image?.uri ?? serverContextService.imageNotFound;
    }

    get productDetailsUrl() {
        return serverContextService.productUrl(this.product);
    }

    get productDetailsUrlTarget() {
        return serverContextService.externalProductLinkTarget || undefined;
    }
}
