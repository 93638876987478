import { LanguageAndUrl, Media, Metadata } from '@/types/contentServerContract';
import { AlternativeUrlViewObject } from '@/types/apiServerContract';
import $ from 'cash-dom';
import botService from '@/project/site/bot.service';
import urlHelperService from '@/core/urlHelper.service';
import { UrlQueryKey } from '@/core/urlQueryKey';
import loggingService from '@/core/logging.service';

class DomService {
    private static VIEWPORT = 'viewport';
    private static TITLE = 'title';
    private static KEYWORDS = 'keywords';
    private static DESCRIPTION = 'description';
    private static ROBOTS = 'robots';
    private static PRERENDER_STATUS_CODE = 'prerender-status-code';
    private static PRERENDER_HEADER = 'prerender-header';
    private static OPENGRAPHTITLE = 'og:title';
    private static OPENGRAPHURL = 'og:url';
    private static OPENGRAPHDESCRIPTION = 'og:description';
    private static OPENGRAPHIMAGE = 'og:image';
    private static OPENGRAPHIMAGEALT = 'og:image:alt';
    private static OPENGRAPHIMAGEWIDTH = 'og:image:width';
    private static OPENGRAPHIMAGEHEIGHT = 'og:image:height';

    public updateMetaData(metadata: Metadata, relativeUrls: boolean = false) {
        if (!metadata) return;
        this.setCanonicalUrl(metadata.url, relativeUrls);
        this.setOpenGraphUrl(metadata.url, relativeUrls);
        this.setDescription(metadata.seoDescription || '');
        this.setMetaRobots(metadata.index);
        this.setTitle(metadata.seoTitle || '');
        this.setHreflang(metadata.languages, relativeUrls);
        this.setOpenGraphImage(metadata.seoImage || null);
    }

    public setTitle(title: string): void {
        this.setPageTitle(title);
        this.setOpenGraphTitle(title);
    }

    public setPrerenderStatusCode(statusCode: number) {
        if (!botService.uaIsPrerender()) return;
        // See https://prerender.io/documentation/best-practices
        this.setContent(DomService.PRERENDER_STATUS_CODE, null, statusCode + '');

        // Set prerenderReady to make prerender redirect as fast as possible
        window.prerenderReady = true;
    }

    public setPrerenderHeader(content: string) {
        if (!botService.uaIsPrerender()) return;
        // See https://prerender.io/documentation/best-practices
        this.setContent(DomService.PRERENDER_HEADER, null, content);
    }

    public setPageTitle(title: string): void {
        if (title) {
            document.title = title;
        }
    }

    public setDescription(description: string): void {
        this.setMetaDescription(description);
        this.setOpenGraphDescription(description);
    }

    public setOpenGraphTitle(title: string) {
        return this.setContent(null, DomService.OPENGRAPHTITLE, title);
    }

    public setMetaDescription(description: string) {
        return this.setContent(DomService.DESCRIPTION, null, description);
    }

    public setOpenGraphDescription(description: string) {
        return this.setContent(null, DomService.OPENGRAPHDESCRIPTION, description);
    }

    public setMetaRobots(index: boolean) {
        const content = index ? '' : 'noindex, follow';

        return this.setContent(DomService.ROBOTS, null, content);
    }

    public setMetaKeywords(keywords: string) {
        return this.setContent(DomService.KEYWORDS, null, keywords);
    }

    public getMetaViewport() {
        return this.getMetaElement(DomService.VIEWPORT).content;
    }

    public updateCheckoutView(isCheckoutView: boolean): void {
        $('html').toggleClass('checkout-view', !!isCheckoutView);
    }

    public setMetaViewport(content: string) {
        return this.setContent(DomService.VIEWPORT, null, content);
    }

    private formatUrlWithPageAndAdditionalQueryParams(urlString: string, additionalUrlStringToBuildQueryFrom?: string): string {
        try {
            const isRelativeUrl: boolean = !urlString.startsWith('http');
            const url = new URL(urlString, isRelativeUrl ? location.origin : undefined);
            const searchParams = new URLSearchParams();

            // Set array of equally named keys (e.g. categoryIds=a&categoryIds=b) to URLSearchParams
            for (const [key, value] of (url.searchParams)) {
                searchParams.append(key, value as string);
            }

            if (additionalUrlStringToBuildQueryFrom) {
                const additionalUrl = new URL(additionalUrlStringToBuildQueryFrom);
                for (const [key, value] of (additionalUrl.searchParams)) {
                    searchParams.append(key, value as string);
                }
            }

            // Set page-number if not already included in urlString
            const pageNumberFromQuery = urlHelperService.getPage();
            if (pageNumberFromQuery > 1) {
                searchParams.set(UrlQueryKey.pageKey, pageNumberFromQuery.toString());
            }

            url.search = searchParams.toString();
            return url.toString();
        } catch (e) {
            loggingService.warn(e);
            return urlString;
        }
    }

    public setCanonicalUrl(url: string, relativeUrl: boolean = false): void {
        const canonicalUrlElement = this.getCanonicalUrlElement();
        const sanitizedUrl = relativeUrl ? this.buildAbsoluteUrlFromRelativePath(url) : this.sanitizeInput(url);
        const urlWithParams = this.formatUrlWithPageAndAdditionalQueryParams(sanitizedUrl);
        canonicalUrlElement.href = urlWithParams;
    }

    public setOpenGraphUrl(url: string, relativeUrl: boolean = false): void {
        const sanitizedUrl = relativeUrl ? this.buildAbsoluteUrlFromRelativePath(url) : this.sanitizeInput(url);
        const urlWithParams = this.formatUrlWithPageAndAdditionalQueryParams(sanitizedUrl);
        this.setContent(null, DomService.OPENGRAPHURL, urlWithParams);
    }

    public setOpenGraphImage(image: Media | null): void {
        if (image) {
            // TODO: Optimal size would be 1200 x 627. Product images does not have a size
            const imageUrl = image.url;
            this.setContent(null, DomService.OPENGRAPHIMAGE, imageUrl);
            if (image.width) {
                this.setContent(null, DomService.OPENGRAPHIMAGEWIDTH, image.width.toString());
            }
            if (image.height) {
                this.setContent(null, DomService.OPENGRAPHIMAGEHEIGHT, image.height.toString());
            }
            this.setContent(null, DomService.OPENGRAPHIMAGEALT, document.title);
        } else {
            const metaTags = $('meta[property^="og:image"]');
            metaTags.each((i, e) => $(e).remove());
        }
    }

    public setHreflang(languages: LanguageAndUrl[] | AlternativeUrlViewObject[], relativeUrls: boolean = false, additionalUrlStringToBuildQueryFrom?: string) {
        // First delete all existing hreflang elements, then there will be left no one behind.
        const existingLinkElements = Array.from(document.querySelectorAll('link[rel=alternate][hreflang]'));
        existingLinkElements.forEach(e => e && e.parentNode && e.parentNode.removeChild(e));

        if (languages.length === 1) {
            return;
        }
        const headElement = this.getHeadElement();

        // Now for all languages, make a hreflang link element.
        languages.map((language) => {
            const element = document.createElement('link');
            element.rel = 'alternate';
            element.hreflang = language.language.toLowerCase();
            let url = relativeUrls ? this.buildAbsoluteUrlFromRelativePath(language.url) : language.url;
            url = this.formatUrlWithPageAndAdditionalQueryParams(url, additionalUrlStringToBuildQueryFrom);
            element.href = url;
            if (headElement) {
                headElement.appendChild(element);
            }
        });

        // Add x-default link element to point to site root
        const xDefaultElement = document.createElement('link');
        xDefaultElement.rel = 'alternate';
        xDefaultElement.setAttribute('x-default', '');
        const defaultUrl = '';
        xDefaultElement.href = this.buildAbsoluteUrlFromRelativePath(defaultUrl);
        if (headElement) {
            headElement.appendChild(xDefaultElement);
        }
    }

    public getHreflang(culture: string) {
        const link = document.querySelector(`link[hreflang="${culture}"]`);
        return link?.getAttribute('href');
    }

    private setContent(nameAttribute: string | null = null, propertyAttribute: string | null = null, content: string): HTMLMetaElement {
        const tag = this.getMetaElement(nameAttribute, propertyAttribute);
        tag.content = this.sanitizeInput(content);
        return tag;
    }

    public buildAbsoluteUrlFromRelativePath(path: string) {
        return this.removeDoubleSlashesInUrl(`${location.protocol}//${location.host}/${this.sanitizeInput(path)}`).toLowerCase();
    }

    public buildCanonicalUrl(path: string) {
        return this.removeDoubleSlashesInUrl(`${location.protocol}//${location.host}/${window.servercontext.culture}/${this.sanitizeInput(path)}`).toLowerCase();
    }

    public removeDoubleSlashesInUrl(url: string) {
        return url.replace(/([^:]\/)\/+/g, '$1');
    }

    private sanitizeInput(input: string): string {
        return input || '';
    }

    private getCanonicalUrlElement(): HTMLLinkElement {
        return this.getOrCreate<HTMLLinkElement>('link[rel=canonical]', () => {
            const element = document.createElement('link');
            element.rel = 'canonical';
            const headElement = this.getHeadElement();
            if (headElement) {
                headElement.appendChild(element);
            }
            return element;
        });
    }

    private getHeadElement(): HTMLHeadElement | null {
        return document.head;
    }

    private getMetaElement(nameAttribute: string | null = null, propertyAttribute: string | null = null): HTMLMetaElement {
        return this.getOrCreate<HTMLMetaElement>('meta[' + (nameAttribute ? 'name="' + nameAttribute + '"' : '') + (propertyAttribute ? 'property="' + propertyAttribute + '"' : '') + ']', () => {
            const element = document.createElement('meta');
            if (nameAttribute) {
                element.name = nameAttribute;
            }
            if (propertyAttribute) {
                element.setAttribute('property', propertyAttribute);
            }
            const headElement = this.getHeadElement();
            if (headElement) {
                headElement.appendChild(element);
            }
            return element;
        });
    }

    private getOrCreate<TElement extends HTMLElement>(selector: string, create?: () => TElement): TElement {
        const element = document.querySelector(selector) as TElement;
        if (element) {
            return element;
        }
        if (create) {
            return create();
        }
        throw new Error(`Provide create param when element ${selector} cannot be found.`);
    }
}

export default new DomService();
