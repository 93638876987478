import log from '@/core/logging.service';
import { router } from '@/router/index';

export function hardReload() {
    log.info('Hard reload');
    window.location.reload();
}

export function softRedirect(url: string) {
    log.info(`Soft redirect. Url: ${url}`);
    router.replace(url);
}

export function hardRedirect(url: string) {
    log.debug(`Hard redirect. Url: ${url}`);
    window.location.href = url;
}
