











import { Component, Prop, Vue } from 'vue-property-decorator';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import responsiveProductImageService from './responsiveProductImage.service';

const supportedSizes = [responsiveProductImageService.ImageSizePlaceholderValues.W86, responsiveProductImageService.ImageSizePlaceholderValues.W200, responsiveProductImageService.ImageSizePlaceholderValues.W300, responsiveProductImageService.ImageSizePlaceholderValues.W700, responsiveProductImageService.ImageSizePlaceholderValues.W1400, responsiveProductImageService.ImageSizePlaceholderValues.WHIRES];
const supportedRetinaSizes = [responsiveProductImageService.ImageSizePlaceholderValues.W86, responsiveProductImageService.ImageSizePlaceholderValues.W200];

@Component
export default class ResponsiveProductImage extends Vue {
    @Prop({ type: String, default: '' })
    imageUrl!: string;

    @Prop({
        type: [String, Object],
        required: true,
        validator: (value: string | object) => {
            const valuesToCheck = typeof value === 'string' ? [value] : Object.values(value);
            return valuesToCheck.every(val => supportedSizes.includes(val));
        }
    })
    size!: string | { [breakpoint: string]: string};

    @Prop({ type: Number, default: 200 })
    offset!: number;

    @Prop({ type: Boolean, default: false })
    retina!: boolean;

    @Prop({ type: String, default: 'transparent' })
    bgColor!: string;

    @Prop({ type: String, default: '' })
    alt!: string;

    @Prop({ type: String })
    imgClass!: string;

    @Prop({
        type: Boolean,
        default: false
    }) isFirst!: boolean;

    @Prop({ required: false, type: Number, default: -1 }) index!: number;

    lazyInitialized: boolean = false;
    lazyLoaded: boolean = false;
    loadEventFired = true;

    observer: IntersectionObserver | undefined;

    get src(): string {
        return this.formatImageUrl();
    }

    get computedLoading() {
        if (this.index !== -1) {
            const minIndex = breakpointsState.isBreakpointActive('max-md') ? 3 : 6;
            return this.index < minIndex ? undefined : 'lazy';
        }
        return this.isFirst ? 'eager' : 'lazy';
    }

    get computedFetchpriority() {
        if (this.index !== -1) {
            const minIndex = breakpointsState.isBreakpointActive('max-md') ? 3 : 6;
            return this.index < minIndex ? 'high' : 'low';
        }
        return this.isFirst ? 'high' : 'low';
    }

    get srcSet(): string | undefined {
        // Only use src-set for retina setting
        if (!this.retina) return undefined;
        // @ts-ignore
        if (this.retina && !supportedRetinaSizes.includes(this.activeSize)) {
            throw new Error('Retina can only be used for these sizes' + supportedRetinaSizes);
        }
        const retinaSize = (parseInt(this.activeSize) * 2) + '';
        return `${encodeURI(this.src)}, ${encodeURI(this.formatImageUrl(retinaSize))} 2x`;
    }

    formatImageUrl(size?: string): string {
        return responsiveProductImageService.formatImageUrl(this.absoluteImageUrl, size || this.activeSize);
    }

    get activeSize(): string {
        if (typeof this.size === 'string') {
            return this.size;
        }

        // Else - object with size pr. breakpoint-def.
        // Find first active breakpoint-def
        const activeBpDef = Object.keys(this.size).find(bpDef => breakpointsState.isBreakpointActive(bpDef));
        // Use active or default
        const result = activeBpDef ? this.size[activeBpDef] : this.size.default;
        if (!result) {
            throw new Error('Provide a breakpoint-definition for all cases or a default');
        }
        return result;
    }

    handleLoad(): void {
        this.loadEventFired = true;
        this.handleLoadedAndLazyInitialized();
    }

    handleLoadedAndLazyInitialized(): void {
        if (this.lazyInitialized && this.loadEventFired) {
            this.lazyLoaded = true;
        }
    }

    get normalizedBgColor(): string {
        return this.bgColor.replace('#', '');
    }

    observe(): void {
        const options = { rootMargin: `${this.offset}px` };
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.lazyInitialized = true;
                    this.handleLoadedAndLazyInitialized();
                    this.unObserve();
                }
            });
        }, options);
        this.observer.observe(this.$el);
    }

    get absoluteImageUrl(): string {
        return this.imageUrl;
    }

    unObserve(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    mounted(): void {
        this.observe();
    }

    destroyed() {
        this.unObserve();
    }
}
