import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    NextDeliveryHolidayDisplayRuleViewModel,
    NextDeliveryWeekDisplayRuleViewModel,
    NextDeliverySettingsViewModel,
    AddOrUpdateNextDeliveryWeekTimeRequest,
    AddOrUpdateNextDeliveryWeekDisplayRuleRequest,
    AddOrUpdateNextDeliveryHolidayDisplayRuleRequest,
    AddOrUpdateNextDeliverySettingsRequest,
    DeleteNextDeliveryWeekTimeRequest,
    DeleteNextDeliveryWeekDisplayRuleRequest,
    DeleteNextDeliveryHolidayDisplayRuleRequest,
    TimeZoneViewModel,
    NextDeliveryCountriesViewModel,
    NextDeliveryWeekTimeViewModel,
    DeleteNextDeliveryHolidayTimeRequest,
    AddOrUpdateNextDeliveryHolidayTimeRequest,
    NextDeliveryHolidayTimeViewModel,
    NextDeliveryHolidayConfigurationViewModel, AddOrUpdateNextDeliveryHolidayConfigurationRequest
} from '@/types/apiServerContract';

export class NextDeliveryConfigurationController {
    private readonly urlFactory = createUrlFactory('nextDeliveryConfiguration');

    public async getTimeZones(): Promise<TimeZoneViewModel> {
        return httpService.get(this.urlFactory('timeZones'));
    }

    public async getNextDeliveryCountries(): Promise<NextDeliveryCountriesViewModel> {
        return httpService.get(this.urlFactory('nextDeliveryCountries'));
    }

    public async getNextDeliveryWeekTimesByCountryId(countryId: number): Promise<NextDeliveryWeekTimeViewModel> {
        return httpService.get(this.urlFactory('nextDeliveryWeekTimesByCountry'), { countryId: countryId });
    }

    public async getNextDeliveryWeekDisplayRulesByCountryId(countryId: number): Promise<NextDeliveryWeekDisplayRuleViewModel> {
        return httpService.get(this.urlFactory('nextDeliveryWeekDisplayRulesByCountryId'), { countryId: countryId });
    }

    public async getNextDeliveryHolidayTimesByCountryId(countryId: number): Promise<NextDeliveryHolidayTimeViewModel> {
        return httpService.get(this.urlFactory('NextDeliveryHolidayTimesByCountry'), { countryId: countryId });
    }

    public async getNextDeliveryHolidayDisplayRulesByCountryId(countryId: number): Promise<NextDeliveryHolidayDisplayRuleViewModel> {
        return httpService.get(this.urlFactory('nextDeliveryHolidayDisplayRulesByCountryId'), { countryId: countryId });
    }

    public async getNextDeliveryHolidayConfigurationsByCountryId(countryId: number): Promise<NextDeliveryHolidayConfigurationViewModel> {
        return httpService.get(this.urlFactory('nextDeliveryHolidayConfigurationsByCountryId'), { countryId: countryId });
    }

    public async getNextDeliverySettings(countryId: number): Promise<NextDeliverySettingsViewModel> {
        return httpService.get(this.urlFactory('nextDeliverySettings'), { countryId: countryId });
    }

    public async addOrUpdateNextDeliveryWeekTime(request: AddOrUpdateNextDeliveryWeekTimeRequest): Promise<NextDeliveryWeekTimeViewModel> {
        return httpService.post(this.urlFactory('addOrUpdateNextDeliveryWeekTime'), request);
    }

    public async addOrUpdateNextDeliveryHolidayTime(request: AddOrUpdateNextDeliveryHolidayTimeRequest): Promise<NextDeliveryHolidayTimeViewModel> {
        return httpService.post(this.urlFactory('addOrUpdateNextDeliveryHolidayTime'), request);
    }

    public async addOrUpdateNextDeliveryWeekDisplayRule(request: AddOrUpdateNextDeliveryWeekDisplayRuleRequest): Promise<NextDeliveryWeekDisplayRuleViewModel> {
        return httpService.post(this.urlFactory('addOrUpdateNextDeliveryWeekDisplayRule'), request);
    }

    public async addOrUpdateNextDeliveryHolidayDisplayRule(request: AddOrUpdateNextDeliveryHolidayDisplayRuleRequest): Promise<NextDeliveryHolidayDisplayRuleViewModel> {
        return httpService.post(this.urlFactory('addOrUpdateNextDeliveryHolidayDisplayRule'), request);
    }

    public async addOrUpdateNextDeliveryHolidayConfiguration(request: AddOrUpdateNextDeliveryHolidayConfigurationRequest): Promise<NextDeliveryHolidayConfigurationViewModel> {
        return httpService.post(this.urlFactory('addOrUpdateNextDeliveryHolidayConfiguration'), request);
    }

    public async addOrUpdateNextDeliverySettings(request: AddOrUpdateNextDeliverySettingsRequest): Promise<NextDeliverySettingsViewModel> {
        return httpService.post(this.urlFactory('addOrUpdateNextDeliverySettings'), request);
    }

    public async deleteNextDeliveryWeekTime(request: DeleteNextDeliveryWeekTimeRequest): Promise<NextDeliveryWeekTimeViewModel> {
        return httpService.delete(this.urlFactory('deleteNextDeliveryWeekTime'), request);
    }

    public async deleteNextDeliveryHolidayTime(request: DeleteNextDeliveryHolidayTimeRequest): Promise<NextDeliveryHolidayTimeViewModel> {
        return httpService.delete(this.urlFactory('deleteNextDeliveryHolidayTime'), request);
    }

    public async deleteNextDeliveryWeekDisplayRule(request: DeleteNextDeliveryWeekDisplayRuleRequest): Promise<NextDeliveryWeekDisplayRuleViewModel> {
        return httpService.delete(this.urlFactory('deleteNextDeliveryWeekDisplayRule'), request);
    }

    public async deleteNextDeliveryHolidayDisplayRule(request: DeleteNextDeliveryHolidayDisplayRuleRequest): Promise<NextDeliveryHolidayDisplayRuleViewModel> {
        return httpService.delete(this.urlFactory('deleteNextDeliveryHolidayDisplayRule'), request);
    }
}
