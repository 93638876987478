import { ProductConditionCode } from '@/types/apiServerContract';
import dictionaryService from '@/core/translation/dictionary.service';

type ProductClassificationCodeDictionary = {
    title: string;
    details: string;
}

const ProductClassifications = new Map<ProductConditionCode, ProductClassificationCodeDictionary>(
    [
        [ProductConditionCode.Exchange, { title: 'Product.Condition.Exchange', details: 'Product.Condition.Exchange.Details' }],
        [ProductConditionCode.SpecialOr, { title: 'Product.Condition.SpecialOr', details: 'Product.Condition.SpecialOr.Details' }],
        [ProductConditionCode.C1, { title: 'Product.Condition.C1', details: 'Product.Condition.C1.Details' }],
        [ProductConditionCode.C2, { title: 'Product.Condition.C2', details: 'Product.Condition.C2.Details' }],
        [ProductConditionCode.C3, { title: 'Product.Condition.C3', details: 'Product.Condition.C3.Details' }],
        [ProductConditionCode.Refurb, { title: 'Product.Condition.Refurb', details: 'Product.Condition.Refurb.Details' }]
    ]
);

class ProductConditionService {
    public getProductClassificationCodeDictionary(code: ProductConditionCode | null | undefined, details: boolean = false): string | null {
        if (!code) return null;

        const productClassification = ProductClassifications.get(code);
        if (!productClassification) { throw new Error(`Unknown classification code ${code}!`); }

        return details ? dictionaryService.get(productClassification.details) : dictionaryService.get(productClassification.title);
    }
}

export default new ProductConditionService();
