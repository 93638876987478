// Borrowed from https://github.com/posva/vue-reactive-refs
// Supports all browsers, but requires you to manually declare `$reactiveRefs` as a component option:
// @Component({ $reactiveRefs: ['refIWantToBeReactive'] })
import Vue, { PluginFunction } from 'vue';

declare module 'vue/types/options' {
    interface ComponentOptions<V extends Vue> {
        $reactiveRefs?: string[];
    }
}

const ReactiveRefs: PluginFunction<never> = _Vue => {
    _Vue.mixin({
        beforeCreate() {
            const { $reactiveRefs } = this.$options;

            if (!$reactiveRefs) {
                return;
            }

            // @ts-ignore
            this.$refs = _Vue.observable(
                $reactiveRefs.reduce(($refs, key) => {
                    $refs[key] = undefined;
                    return $refs;
                }, {} as Record<string, Vue | Element | Vue[] | Element[] | undefined>)
            );
        }
    });
};

Vue.use(ReactiveRefs);
