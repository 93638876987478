






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AdnuntiusBulkService from './adnuntiusBulk.service';
import gtmService from '@/project/tracking/gtm/GTM.service';
import { AdnuntiusTrackingData } from '@/project/tracking/adnuntius/types';

@Component
export default class AdnuntiusPixels extends Vue {
    isSetup = false;
    isVisible = false;
    isMaybeViewed = false;
    isClicked = false;
    observer: IntersectionObserver | null = null;

    @Prop(String)
    renderedUrl!: string | null;

    @Prop(String)
    visibleUrl!: string | null;

    @Prop(String)
    viewUrl!: string | null;

    @Prop(String)
    clickUrl!: string | null;

    @Prop(Boolean)
    alwaysVisible!: boolean | null;

    @Prop(Object)
    trackingData!: AdnuntiusTrackingData | null;

    handleClick() {
        if (!this.isClicked) {
            this.isClicked = true;
            this.forwardContentRequests(this.clickUrl);
            this.trackCreativeClick();
        }
    }

    forwardContentRequests(url: string | null) {
        if (url) { AdnuntiusBulkService.requestData(url); }
    }

    trackCreativeView() {
        if (this.trackingData) {
            gtmService.trackCreativeView([{ name: this.trackingData.lineItemId, id: this.trackingData.creativeId, creative: this.trackingData.parentBlockAlias }]);
        }
    }

    trackCreativeClick() {
        if (this.trackingData) {
            gtmService.trackCreativeClick(this.trackingData.lineItemId, this.trackingData.creativeId, this.trackingData.parentBlockAlias);
        }
    }

    mounted() {
        this.setup();
    }

    @Watch('renderedUrl')
    onRenderedUrlChange(url: string, oldUrl: string) {
        if (url) {
            this.setup();
        }
    }

    setup() {
        // Setup only when has been mounted and link props are present
        if (this.isSetup || !this.renderedUrl || !this.$el) return;
        this.isSetup = true;

        this.forwardContentRequests(this.renderedUrl);

        if (this.alwaysVisible) {
            this.forwardContentRequests(this.viewUrl);
            this.forwardContentRequests(this.visibleUrl);
        } else {
            this.observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            if (!this.isVisible) {
                                this.isVisible = true;
                                this.forwardContentRequests(this.visibleUrl);
                            }
                        } else {
                            this.isMaybeViewed = false;
                        }

                        if (entry.intersectionRatio >= 0.5 && entry.isIntersecting) {
                            this.isMaybeViewed = true;
                            setTimeout(() => {
                                if (this.isMaybeViewed) {
                                    this.forwardContentRequests(this.viewUrl);
                                    this.trackCreativeView();
                                    this.unObserve();
                                }
                            }, 1000);
                        }
                    });
                },
                {
                    threshold: [0, 0.5]
                });

            this.observer.observe(this.$el);
        }
    }

    unObserve(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    destroyed() {
        this.unObserve();
    }
}
