/**
 * All 'js-styles' (tailwind plugins) are required and included in ../plugins.js
 *
 *
 * Generated class sample
 *
 * .o-aspect-ratio-16/9 {
 *     padding-top: 56.25%;
 *     position: relative;
 * }
 *
 *
 */

const _map = require('lodash/map');

module.exports = function(variants) {
    return function({ e, addUtilities }) {
        const ratios = {
            '1/1': [1, 1],
            '1/2': [1, 2],
            '2/1': [2, 1],
            '4/3': [4, 3],
            '16/9': [16, 9]
        };

        const utilities = _map(ratios, ([width, height], name) => ({
            [`.${e(`o-aspect-ratio-${name}`)}`]: {
                paddingTop: `${((Math.round(height) / Math.round(width)) * 100).toFixed(4)}%`,
                position: 'relative',
                height: 0,
                borderStyle: 'none',
                overflow: 'hidden'
            }
        }));

        addUtilities(utilities, variants);
    };
};
