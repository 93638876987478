
















































































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { StockInformationViewObject, StockLocationTypeViewObject } from '@/types/apiServerContract';
import dictionaryService from '@/core/translation/dictionary.service';
import Temporary from '@/core/ui-components/Temporary.vue';
import Api from '@/project/http/Api.service';
import NotificationFetchService from '@/project/product/services/notificationsFetch.service';
import myAccountStore from '@/project/app/client-pages/my-account/my-account.store';
import gtmService from '@/project/tracking/gtm/GTM.service';
import serverContextService from '@/core/serverContext.service';

@Component({
    components: { Temporary }
})
export default class ProductTileStock extends Vue {
    @Prop({ required: true }) itemId!: string;
    @Prop({ required: true }) stock!: { loaded: boolean, data: StockInformationViewObject[] | null, simpleData: StockInformationViewObject[] | null };
    @Prop({ required: false, type: Boolean, default: false }) tileMode!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) popoverTriggerAsIconOnly!: boolean;
    @Prop({ required: false, type: String, default: '' }) popoverTriggerClass!: string;
    @Prop({ required: false, type: Boolean, default: false }) alignRight!: boolean;
    @Prop({ required: false, type: Boolean, default: true }) showText!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) ignoreNotifyMe!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) showDetailedStock!: boolean;
    @Prop({ required: false, type: Number, default: 2 }) numberOfStockLabels!: number;
    @Prop({ required: false, type: Boolean, default: false }) isLimitedItem!: boolean;
    @Prop({ required: false, type: Boolean, default: true }) allowStockPopover!: boolean;
    @Prop({ required: true, type: Boolean }) isLoggedIn!: boolean;

    notificationInProgress: boolean = false;
    notificationLoading: boolean = false;
    notificationList: string[] = [];

    get hideStock() {
        return serverContextService.externalProductGuideSettings?.hideStock === true;
    }

    get detailedStockData(): StockInformationViewObject[] {
        if (this.hideStock) {
            return [];
        }

        return this.stock.data && this.stock.data.length > 0 ? this.stock.data : [];
    }

    get stockData(): StockInformationViewObject[] {
        if (this.hideStock) {
            return [];
        }

        if (this.showDetailedStock) {
            return this.stock.data && this.stock.data.length > 0 ? this.stock.data : [];
        } else {
            return this.stock.simpleData && this.stock.simpleData.length > 0 ? this.stock.simpleData : [];
        }
    }

    get stockDataResume(): StockInformationViewObject[] {
        if (this.showDetailedStock) {
            // Product Details Page shows all stock data
            return this.stockData;
        } else {
            return this.stockData.slice(0, this.numberOfStockLabels);
        }
    }

    get showStockPopover(): boolean {
        if (!this.allowStockPopover || !this.isLoggedIn) {
            return false;
        }

        if (this.showDetailedStock) {
            return false;
        }

        if (this.stockData.length < this.detailedStockData.length) {
            return true;
        }

        if (this.stockDataResume.length < this.stockData.length) {
            return true;
        }

        // if one of the lines are longer than 20 chars (we then expect 20 to be where the string is truncated)
        return !!this.stockDataResume.find(stock => this.getStockInformationText(stock).length > 20);
    }

    get showNotifyMe() {
        if (!this.isLoggedIn || this.ignoreNotifyMe === true) {
            return false;
        }

        return this.stockData.every(x => x.locationType === 2 && x.hasStock) ?? false;
    }

    get account() {
        return myAccountStore.account;
    }

    async notifyOnStockReplenish(callback: () => void) {
        try {
            this.notificationInProgress = true;
            await Api.notifications.notifyOnStockReplenish(this.itemId);
            gtmService.trackNotifyOnStockReplenish();
            callback();
        } finally {
            this.notificationInProgress = false;
        }
    }

    get isOnNotificationList() {
        return this.notificationList.some(x => x === this.itemId);
    }

    getStockIcon(stock: StockInformationViewObject) {
        const stockIcons = {
            green: 'stock-icon-green',
            yellow: 'stock-icon-yellow',
            gray: 'stock-icon-gray'
        };

        const type = stock.locationType;
        switch (type) {
        case StockLocationTypeViewObject.Local:
            return stockIcons.green;
        case StockLocationTypeViewObject.Remote:
        case StockLocationTypeViewObject.InComing:
            return stockIcons.yellow;
        default:
            return stockIcons.gray;
        }
    }

    getStockInformationText(stock: StockInformationViewObject): string {
        return dictionaryService.translate(stock.stockInformationText);
    }

    async checkIfProductIsNotificationListed() {
        if (!this.isLoggedIn || this.notificationLoading) return;
        this.notificationLoading = true;

        try {
            const result = await NotificationFetchService.requestData();

            if (result) {
                this.notificationList = result;
            }
        } catch {
            // No need to log any potential errors here since it's dealt with in our "AbstractBulkFetch" method.
        } finally {
            this.notificationLoading = false;
        }
    }

    @Watch('isLoggedIn')
    onLoginStateChange(state: boolean) {
        if (state) {
            this.checkIfProductIsNotificationListed();
        }
    }

    created() {
        this.checkIfProductIsNotificationListed();
    }
}
