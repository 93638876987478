import Vue, { VNodeDirective } from 'vue';
import botService from '@/project/site/bot.service';
import serverContextService from '@/core/serverContext.service';

function errorHandler(el:HTMLElement, classNamesArray:string[] = []) {
    if (el.dataset.onerror) { return; }
    el.dataset.onerror = 'true';
    el.setAttribute('src', serverContextService.imageNotFound); // Set attribute to no image src const
    el.removeAttribute('srcset'); // Remove srcset attribute

    classNamesArray.forEach((className: string) => {
        el.classList.add(className); // Add class when error on image
    });
}

Vue.directive('on-error', {
    bind: function(el: HTMLElement, binding: VNodeDirective) {
        // Prerender blocks images by default, so all images ends up with fallback url when google sees them.
        if (botService.uaIsPrerender()) return;

        const classNamesArrayTemp = binding.value && binding.value[0] ? binding.value : [];
        const classNamesArray = ['c-on-error', ...classNamesArrayTemp];

        el.addEventListener('error', () => errorHandler(el, classNamesArray));
    }
});

Vue.directive('delegateOnImgError', {
    bind: function(elWrapper: HTMLElement, binding: VNodeDirective) {
        const imageEls = elWrapper.querySelectorAll('img');

        const classNamesArrayTemp = binding.value && binding.value[0] ? binding.value : [];
        const classNamesArray = ['c-on-error', 'max-h-215', ...classNamesArrayTemp];

        imageEls.forEach((el, index) => {
            el.addEventListener('error', () => errorHandler(el, classNamesArray));
        });
    }
});
