import { debounce } from 'lodash';
import Deferred from '@/core/async/Deferred';
import Api from '@/project/http/Api.service';

class NotificationFetchService {
    private debounceTime = 100;
    private debouncedFetch = debounce(this.fetch, this.debounceTime);
    private deferred = new Deferred<string[]>();

    public async requestData(): Promise<string[]> {
        this.debouncedFetch();
        return this.deferred.promise;
    }

    public async fetch() {
        const result = await Api.notifications.getNotifications();
        this.deferred.resolve(result.itemIds);
        this.deferred = new Deferred<string[]>();
    }
}

export default new NotificationFetchService();
