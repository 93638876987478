import { sessionStorageService, localStorageService, IStorage } from '@/core/storage.service';
import UUID from '@/core/uuid.service';

const SESSION_STORAGE_KEY = 'eet-sessionkey';
const LOCAL_STORAGE_KEY = 'eet-localkey';
const _sessionKey = initSessionKey(sessionStorageService, SESSION_STORAGE_KEY);
const _localStorageKey = initSessionKey(localStorageService, LOCAL_STORAGE_KEY);

function initSessionKey(storage: IStorage, keyName: string) {
    let key = storage.getItem(keyName);
    if (key) {
        return key;
    }

    key = UUID();
    storage.setItem(keyName, key);
    return key;
}

function getSessionKey(): string {
    return _sessionKey;
}

function getLocalStorageKey(): string {
    return _localStorageKey;
}

export default {
    getSessionKey, getLocalStorageKey
};
