import axios, { CancelTokenSource } from 'axios';
import httpService from './http.service';
import { HttpCancelError } from '@/core/http/HttpCancelError';

export function createCancellableGet<T>() : (url: string, params?: any, config?: object) => Promise<T> {
    let cancelTokenSrc: CancelTokenSource | null = null;

    return async function get<T>(url: string, params?: any, config?: object): Promise<T> {
        if (cancelTokenSrc) {
            cancelTokenSrc.cancel();
        }
        cancelTokenSrc = axios.CancelToken.source();
        try {
            const theConfig = { ...config, cancelToken: cancelTokenSrc.token };
            const response = await httpService.get<T>(url, params, '', theConfig);
            cancelTokenSrc = null;
            return response;
        } catch (error) {
            if (axios.isCancel(error)) {
                throw new HttpCancelError();
            }
            throw error;
        }
    };
}
