import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { AllUsersRequest, CrudUserRequest, RequestServiceRequest, SalesRepViewModel, UsersViewModel, UserViewModel } from '@/types/apiServerContract';
import myAccountStore from '@/project/app/client-pages/my-account/my-account.store';

export class UserController {
    readonly urlFactory = createUrlFactory('User');

    public async account(): Promise<UserViewModel> {
        return httpService.get(this.urlFactory('Current'));
    }

    public async salesRep(): Promise<SalesRepViewModel> {
        return httpService.get(this.urlFactory('SalesRep'));
    }

    public async update(request: CrudUserRequest, messagesId: string): Promise<UserViewModel> {
        const req: Promise<UserViewModel> = httpService.post(this.urlFactory('Update'), request, messagesId);
        req.then((data: UserViewModel) => {
            if (myAccountStore.account?.userId === data?.userViewObject?.userId) {
                myAccountStore.updateAccount(data.userViewObject);
            }
        });
        return req;
    }

    public async create(request: CrudUserRequest, messagesId: string): Promise<UserViewModel> {
        return httpService.post(this.urlFactory('Create'), request, messagesId);
    }

    public async allByCustomer(request?: AllUsersRequest): Promise<UsersViewModel> {
        if (request && request.sortColumn === undefined) {
            delete request.sortColumn;
            delete request.sortOrder;
        }
        return httpService.get(this.urlFactory('AllByCustomer'), request);
    }

    public async delete(userId: string, messageId?: string) {
        return httpService.delete(this.urlFactory('Delete'), {
            userId
        }, undefined, messageId);
    }

    public async postponeInactive(userId: string): Promise<any> {
        return httpService.post(this.urlFactory('PostponeInactive'), userId);
    }

    public async requestService(request: RequestServiceRequest, messagesId: string) {
        return httpService.post(this.urlFactory('RequestService'), request, messagesId);
    }
}
