











import Vue from 'vue';
import { Prop, Watch, Component } from 'vue-property-decorator';
import serverContextService from '@/core/serverContext.service';
import loggingService from '@/core/logging.service';

@Component
export default class cIcon extends Vue {
    @Prop({
        type: String,
        required: true
    })
    name!:string;

    @Prop({
        type: String
    })
    height!:string;

    @Prop({
        type: String
    })
    width!:string;

    @Prop()
    original!: boolean;

    @Prop()
    alt!: string;

    @Prop()
    title!: string;

    @Prop()
    color;

    @Prop()
    fill;

    get nameComputed() {
        const opremaPrefixedIcons = new Set(['logo', 'logo-half-eet', 'logo-half-top', 'lyn1', 'lyn2', 'lyn3', 'splash-new']);
        let name: string = this.name;
        if (serverContextService.isOprema && serverContextService.themeString && opremaPrefixedIcons.has(this.name)) {
            name = `${serverContextService.themeString}-${this.name}`;
        }
        return name;
    }

    created() {
        this.importIcon(this.nameComputed);
    }

    @Watch('name')
    onNameChange() {
        this.importIcon(this.nameComputed);
    }

    importIcon(name: string) {
        import(/* webpackChunkName: "eet-icon-[request]" */ `@/project/icons/${name}`).catch(e => loggingService.error(`icon fetch error: ${name}, parent component ${this.$parent?.$options?.name || 'unknown'}`));
    }

    get altText(): string {
        return this.alt || this.title || this.name;
    }
}
