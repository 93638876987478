import log from '@/core/logging.service';
import { isNumber } from 'lodash';
export type NamedLabelArgument = Record<string, string | undefined>;
const isLabelDebugMode = location.search.toLowerCase().includes('labelsdebug');

class DictionaryService {
    private dictionaryMap;
    private initialized = false;

    public translate(input: string) {
        if (!input || (input.match(/#/g) || []).length < 2) {
            return input;
        }

        let output = input;

        let labelStart = input.indexOf('#', 0);
        let labelEnd = input.indexOf('#', labelStart + 1);

        while (labelStart > -1 && labelEnd > -1) {
            const key = input.substring(labelStart + 1, labelEnd);

            if (this.exists(key)) {
                const keyReplaceRegex = new RegExp('#' + key + '#');
                output = output.replace(keyReplaceRegex, this.get(key));
            }

            labelStart = input.indexOf('#', labelEnd + 1);
            labelEnd = input.indexOf('#', labelStart + 1);
        }

        return output;
    }

    public get(key: string, args: (string | number)[] = []) {
        this.ensureInitialized();
        const translated = this.dictionaryMap.get(key);
        if (translated === undefined) {
            log.error(`### Key '${key}' not found in dictionary ###`);
            return `##${key}`;
        }
        if (isLabelDebugMode) {
            return `${this.format(translated, args)} (${key})`;
        } else {
            return this.format(translated, args);
        }
    }

    public getWithNamedValues(key: string, namedArguments: NamedLabelArgument = {}, failWithMissingRecords: boolean = false, useKeyAsLabel: boolean = false): string | null {
        this.ensureInitialized();
        if (!key || (key && key.length === 0)) {
            log.error('### Key is null ###');
            return null;
        }
        // useKeyAsLabel false: get label from umbraco dictionary
        // useKeyAsLabel true: get label in key and don't look it up
        const translated = useKeyAsLabel ? key : this.dictionaryMap.get(key);
        if (useKeyAsLabel === false && translated === undefined) {
            log.error(`### Key '${key}' not found in dictionary ###`);
            return `##${key}`;
        }
        const label = this.formatNamed(translated, namedArguments);
        if (failWithMissingRecords && label.indexOf('{') !== -1) {
            return null;
        }
        if (isLabelDebugMode) {
            return `${label} (${key})`;
        } else {
            return label;
        }
    }

    public getWithNamedValuesUntilString(keys: string[], namedArguments: NamedLabelArgument = {}): string | null {
        for (let i = 0; i < keys.length; i++) {
            const value = this.getWithNamedValues(keys[i], namedArguments, true, true);
            if (value) {
                return value;
            }
        }

        return null;
    }

    public seofyNumbers(num: number | undefined): number | undefined {
        if (isNumber(num)) {
            if (num < 10) return num; // 0-9
            if (num < 100) return Math.floor(num / 10) * 10; // 10-99 floor to nearest 10
            if (num < 1000) return Math.floor(num / 100) * 100; // 100-999 floor to nearest 100
            if (num < 10000) return Math.floor(num / 500) * 500; // 1000-9999 floor to nearest 500
            return Math.floor(num / 1000) * 1000; // 10000+ floor to nearest 1000
        }
        return undefined;
    }

    public exists(key: string): boolean {
        this.ensureInitialized();
        return !!this.dictionaryMap.get(key);
    }

    public reinitialize() {
        this.initialized = false;
        this.ensureInitialized();
    }

    private format(translated, args: (string| number)[] = []): string {
        return args.reduce((result, arg, ix) => {
            return result.split(`{${ix}}`).join(arg);
        }, translated);
    }

    public formatNamed(toTranslate: string, namedValues: Record<string, string | undefined> = {}): string {
        let translated: string = toTranslate;
        Object.keys(namedValues).forEach(key => {
            const namedValue = namedValues[key];
            if (namedValue !== undefined) {
                // @ts-ignore
                const pattern = new RegExp(`{${key}}`, 'gi');
                translated = translated.replace(pattern, namedValue);
            }
        });
        return translated;
    }

    private dictionaryExists(): boolean {
        return !!window.servercontext.dictionary;
    }

    private ensureInitialized() {
        if (this.initialized) {
            return;
        }
        this.initialized = true;
        // Don't do the check in constructor - too early - dictionary might not be loaded at that time
        if (!this.dictionaryExists()) {
            throw new Error('window.servercontext.dictionary should exist');
        }
        this.dictionaryMap = new Map<string, string>(Object.entries(window.servercontext.dictionary));
    }
}

export default new DictionaryService();
