import { AbstractModalService } from '@/core/modals/AbstractModalService';
import { ModalOptions } from '@/core/modals/ModalState';

class MaintenancePageOverlayService extends AbstractModalService {
    public show(options?: Partial<ModalOptions>) {
        return this.showModal({
            component: () => import('@/project/app/content-pages/MaintenancePage.vue'),
            componentAttrs: {
            },
            options: {
                wide: true,
                disableBodyScroll: true,
                closeOnBreakpointChange: false,
                showCloseButton: false,
                disableUserClose: true,
                placement: 'top',
                ...options
            }
        });
    }
}

export default new MaintenancePageOverlayService();
