import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';

export class OciController {
    private readonly urlFactory = createUrlFactory('Oci');

    public async getPunchOutForm(): Promise<string> {
        return httpService.get(this.urlFactory('PunchOutForm'));
    }
}
