import bus from '@/core/bus';
import { potentialMaintenanceMaxFailedRequestLimitReachedKey } from '@/core/http/constants';
// import contentApiService from '@/core/http/contentApi.service';
import appStore from '@/core/app/app.store';
import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { MaintenanceViewModel } from '@/types/apiServerContract';

export class MaintenanceController {
    private readonly urlFactory = createUrlFactory('Maintenance');

    constructor() {
        bus.on(potentialMaintenanceMaxFailedRequestLimitReachedKey, () => this.checkIsMaintenanceMode());
    }

    public async checkIsMaintenanceMode(): Promise<MaintenanceViewModel> {
        const result = await httpService.get(this.urlFactory('maintenanceactive')) as MaintenanceViewModel;
        appStore.isMaintenanceMode = result.maintenanceIsActive;
        return result;
    }
}
