import httpService from '@/core/http/http.service';
import { CableGuideCableTypesViewModel } from '@/types/apiServerContract';
import { createUrlFactory } from '@/project/http/ApiHelper.service';

export class CableGuideController {
    private readonly urlFactory = createUrlFactory('CableGuide');

    public async getCableTypes(cableEndTypeA?: string | null | undefined, cableEndTypeB?: string | null | undefined, allowedBrands?:string[]): Promise<CableGuideCableTypesViewModel> {
        return httpService.get(this.urlFactory('GetCableTypes'), {
            cableEndTypeA,
            cableEndTypeB,
            allowedBrands: allowedBrands || []
        });
    }

    public async getCableEndTypesA(cableType: string | null | undefined, allowedBrands?:string[]): Promise<CableGuideCableTypesViewModel> {
        return httpService.get(this.urlFactory('GetCableEndTypesA'), {
            cableType,
            allowedBrands: allowedBrands || []
        });
    }

    public async getCableEndTypesB(cableType: string | null | undefined, cableEndTypeA: string, allowedBrands?:string[]): Promise<CableGuideCableTypesViewModel> {
        return httpService.get(this.urlFactory('GetCableEndTypesB'), {
            cableType,
            cableEndTypeA,
            allowedBrands: allowedBrands || []
        });
    }
}
