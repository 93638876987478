
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class Bus extends Vue {
    emit(event: string, ...args: any[]) {
        this.$emit(event, ...args);
    }

    once(event: string, callback: (...args: any[]) => void) {
        this.$once(event, callback);
    }

    on(event: string, callback: (...args: any[]) => void) {
        this.$on(event, callback);
    }

    off(event: string, callback: (...args: any[]) => void) {
        this.$off(event, callback);
    }
}
