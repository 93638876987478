import { ImpersonationViewModel, UserViewObject } from '@/types/apiServerContract';
import Api from '@/project/http/Api.service';
import { hardRedirect } from '@/core/redirect.service';
import tokenService from '@/core/auth/token.service';
import bus from '@/core/bus';
import authService, { LOGGED_OFF } from '@/core/auth/auth.service';
import { localStorageService } from '@/core/storage.service';
import { PageName } from '@/router/routes/constants';
import { router } from '@/router/index';

class ImpersonationService {
    private URL_LOCAL_STORAGE_KEY = 'eetgroup-eet-site';
    private _isImpersonating = !!(tokenService.getUserData()?.impersonatorEmail);
    private _impersonatorCultureStored = localStorageService.getItemAs<string>(this.URL_LOCAL_STORAGE_KEY);
    private _impersonatorCulture = this._impersonatorCultureStored ? this._impersonatorCultureStored : 'en-zz';

    constructor() {
        bus.on(LOGGED_OFF, () => {
            if (this.isImpersonating) {
                hardRedirect(this.redirectUrl(this._impersonatorCulture));
            }
        });
    }

    public async impersonate(user: UserViewObject, messageId: string) {
        const result: ImpersonationViewModel = await Api.impersonation.beginImpersonation(user.id, messageId);

        authService.replaceToken(result.token);

        // Create hard redirect to the siteUrl / MarketUrl we receive from the API call
        if (result.siteUrl) {
            hardRedirect(this.redirectUrl(result.siteUrl));
        }
    }

    public async endImpersonation() {
        const result: ImpersonationViewModel = await Api.impersonation.endImpersonation();

        authService.replaceToken(result.token);

        const myAccountPagePath = router.resolve({ name: PageName.MY_ACCOUNT_IMPERSONATION });

        hardRedirect(this.redirectUrl(this._impersonatorCulture, myAccountPagePath.href));
    }

    public get impersonateeName(): string {
        if (!this.isImpersonating) return '';
        return tokenService.getUserData()?.userName ?? '';
    }

    public get isImpersonating(): boolean {
        return this._isImpersonating;
    }

    private redirectUrl(site: string, href?: string) {
        // convert /xx-xx/lajaskldfkf?lksadf or /lajaskldfkf?lksadf to /zz-zz/lajaskldfkf?lksadf
        // Where xx-xx is culture and zz-zz is users preferred culture
        let url = href != null ? href : location.pathname + location.search;

        if (location.pathname.match(/^\/[a-z]{2}-[a-z]{2}/i)) {
            url = url.substr(6);
        }

        return site + url;
    }
}

export default new ImpersonationService();
