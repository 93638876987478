

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Temporary extends Vue {
    @Prop({ type: String, default: 'center', validator: value => ['left', 'right', 'top', 'center', 'fade'].includes(value as string) })
    mode!: string;

    @Prop({ required: false, type: Number, default: 200 })
    delay!: number;

    @Prop({ required: false, type: Number, default: 1500 })
    duration!: number;

    @Prop({ type: Boolean, required: false, default: false })
    hasTrigger!: boolean;

    @Prop({
        type: Function,
        required: false,
        default: () => {}
    }) leaveHandler!: () => void;

    active: boolean = false;

    trigger() {
        setTimeout(() => {
            this.active = true;

            if (this.duration > 0) {
                setTimeout(() => {
                    this.active = false;
                }, this.duration);
            }
        }, this.delay);
    }

    get animationEnterClass() {
        const animTypes = {
            left: 'slideInLeft',
            right: 'slideInRight',
            top: 'slideInDown',
            center: 'zoomIn',
            fade: 'fadeIn'
        };
        return this.animationClass(animTypes) + ' u-anim-delay-100 u-anim-dur-300 u-anim-ease-out';
    }

    get animationLeaveClass() {
        const animTypes = {
            left: 'slideOutLeft',
            right: 'slideOutRight',
            top: 'slideOutDown',
            center: 'fadeOut',
            fade: 'fadeOut'
        };
        return this.animationClass(animTypes) + ' u-anim-dur-200 u-anim-ease-in';
    }

    animationClass(animTypes: { [mode: string]: string }) {
        return `animated ${animTypes[this.mode]}`;
    }

    mounted() {
        if (this.hasTrigger) return;

        this.trigger();
    }
}
