import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { TranslationResultViewModel } from '@/types/apiServerContract';

export class TranslateController {
    private readonly urlFactory = createUrlFactory('translation');

    public async translateDescription(productWid: string, language: string): Promise<TranslationResultViewModel> {
        return httpService.get(this.urlFactory('translatedescription'), { productWid, language });
    }
}
