import loggingService from '@/core/logging.service';
import HttpStatus from 'http-status-codes';
import siteService from '@/project/site/site.service';
import botService from '@/project/site/bot.service';
import { hardRedirect } from '@/core/redirect.service';
import serverContext from '@/core/serverContext.service';
import domService from '@/core/dom/dom.service';
import { router } from '@/router/index';
import urlStringHelperService from '@/core/urlStringHelper.service';

const ErrorPage = {
    [HttpStatus.NOT_FOUND]: '/404',
    [HttpStatus.INTERNAL_SERVER_ERROR]: '/500',
    [HttpStatus.BAD_GATEWAY]: '/500'
};

const uaIsBot = botService.uaIsBot();
const uaIsPrerender = botService.uaIsPrerender();

function handleErrorCodes(errorCode: number, message?: string, notFoundProductWid?: string) {
    if (!Object.keys(ErrorPage).includes(errorCode + '')) {
        return;
    }

    if (uaIsPrerender) {
        // For prerender do XHR redirect and set prerender metadata
        domService.setPrerenderStatusCode(errorCode);
        router.push(ErrorPage[errorCode]);
    } else if (uaIsBot) {
        // If bot, but not prerender (not all product detailpages urls are handled by prerender) - hard-redirect
        const path = `/${serverContext.siteInfo.culture}${ErrorPage[errorCode]}`;
        hardRedirect(path);
    } else if (errorCode === HttpStatus.NOT_FOUND) {
        // No bot. 404's are redirected to content-page - all others handled by toaster for user
        loggingService.warn(`404 page. Referrer: ${siteService.documentReferrer}`);

        if (notFoundProductWid && serverContext.settings.product404Page) {
            router.push(urlStringHelperService.withoutCulturePrefix(`${serverContext.settings.product404Page}?wid=${notFoundProductWid}`));
        } else {
            router.push(ErrorPage[errorCode]);
        }
    }

    if (message) {
        loggingService.info(`StatusCode: ${errorCode} - ${message}`);
    }
}

export default {
    handleErrorCodes
};
