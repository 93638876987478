import { RawLocation, Route } from 'vue-router';
import { BreadcrumbItem, Media, Metadata } from '@/types/contentServerContract';
import domService from '@/core/dom/dom.service';
import appStore from '@/core/app/app.store';
import authService from '@/core/auth/auth.service';
import permissionService, { Permission } from '@/project/auth/permission.service';
import loginFormOverlayService from '@/project/modals/overlays/LoginFormOverlay.service';
import { router } from '@/router/index';
import botService from '@/project/site/bot.service';
import HttpStatus from 'http-status-codes';
import bus from '@/core/bus';
import { RouterBeforeChangeKey } from '@/core/http/constants';
import { UrlQueryKey } from '@/core/urlQueryKey';
import { PageName } from '@/router/routes/constants';

export interface RouterMetaData {
    isContent?: boolean; // For internal use
    isTunnelView?: boolean;
    hideBreadcrumb?: boolean;
    breadcrumb?: BreadcrumbItem[];
    skipAutoBreadcrumb?: boolean;
    isPublic?: boolean;
    permission?: Permission;
    headMeta: Partial<Metadata>,
    skipAutoPageTracking?: boolean,
    skipAutoSeoTitle?: boolean
}

router.beforeEach((to: Route, from: Route, next: (to?: RawLocation | false | void) => void) => {
    bus.emit(RouterBeforeChangeKey, to, from);
    // If querystring contains term, redirect to search page
    if (to.query && to.query[UrlQueryKey.termKey]) {
        if (to.name !== PageName.SEARCH) {
            next({ name: PageName.SEARCH, query: to.query });
            return;
        }
    }

    const routerMeta = to.meta as RouterMetaData;
    const isLoggedIn = authService.isLoggedIn();
    const nonPublicAndNotLoggedIn = !routerMeta.isPublic && !isLoggedIn;
    const needsPermissionAndHasNot = routerMeta.permission && !permissionService.can(routerMeta.permission);

    if (nonPublicAndNotLoggedIn) {
        if (botService.uaIsPrerender()) {
            domService.setPrerenderStatusCode(HttpStatus.UNAUTHORIZED);
        }
        setTimeout(() => {
            loginFormOverlayService.show('login', to.path);
        }, 0);
        next('/');
        return;
    }

    if (needsPermissionAndHasNot) {
        next('/');
        return;
    }

    next();
});

router.afterEach((to: Route) => {
    const routerMeta = to.meta as RouterMetaData;

    appStore.initPage({ isTunnelView: routerMeta.isTunnelView ?? false, hideBreadcrumb: routerMeta.hideBreadcrumb ?? false });

    if (routerMeta.isContent) {
        // Route for content pages (from server). Ignore
        return;
    }

    // Set breadcrumb
    if (!routerMeta.skipAutoBreadcrumb) {
        const breadCrumb = routerMeta.breadcrumb || appStore.getBreadcrumbFromRoute(to.fullPath);
        appStore.setBreadcrumb(breadCrumb);
    }

    const metaData = {
        ...{
            id: 0,
            key: '',
            index: false,
            url: domService.buildCanonicalUrl(to.path),
            languages: [],
            navigationTitle: to.name || '',
            seoDescription: '',
            seoImage: undefined as unknown as Media
        },
        ...routerMeta.headMeta
    } as Metadata;

    // Set the seoTitle automatically if skipAutoSeoTitle has not been setup on the route
    if (!routerMeta.skipAutoSeoTitle) {
        metaData.seoTitle = to.name || '';
    }

    // Set meta-data - with optional pr. route override.
    domService.updateMetaData(metaData);
});
