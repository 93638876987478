import Api from '@/project/http/Api.service';
import AbstractBulkFetch from '@/core/bulk-fetch/AbstractBulkFetch';
import { StockViewObject } from '@/types/apiServerContract';
import { keyBy } from 'lodash';
export const StockFetchDebounceTime = 100;

export interface StockViewObjectExtended extends StockViewObject {
    isServiceAvailable: boolean;
}

class StockFetchService extends AbstractBulkFetch<StockViewObjectExtended> {
    async apiCall(itemIds: string[], cancelKey: string): Promise<{ [key: string]: StockViewObjectExtended }> {
        const result = await Api.stock.stockProducts(itemIds, cancelKey);
        const stocks = result.stockByItemId.map(stock => ({
            ...stock,
            isServiceAvailable: result.isServiceAvailable
        }) as StockViewObjectExtended);
        return keyBy<StockViewObjectExtended>(stocks, 'itemId');
    }
}

export default new StockFetchService('Stock', StockFetchDebounceTime, true, true);
