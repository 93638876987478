





























import { Vue, Prop, Component } from 'vue-property-decorator';
import { ProductTileViewObject, LineItemViewObject } from '@/types/apiServerContract';
import productConditionService from '@/project/product/services/productCondition.service';

@Component
export default class ProductTileCondition extends Vue {
    @Prop({ required: true }) product!: ProductTileViewObject | LineItemViewObject;
    @Prop({ required: false, type: Boolean }) condensedMode!: boolean;

    @Prop({ type: Boolean, default: false })
    tileBasketMode!: boolean;

    getClassificationCodeDictionary(details: boolean = false) {
        return productConditionService.getProductClassificationCodeDictionary(this.product.conditionCode, details);
    }
}
