
import { Vue, Component, Watch } from 'vue-property-decorator';
import authService from '@/core/auth/auth.service';
import sitePickerOverlayService, { SHOW_SITE_PICKER } from '@/project/modals/overlays/SitePickerOverlay.service';
import siteService from './site.service';
import botService from './bot.service';
import Api from '@/project/http/Api.service';
import { SwitchSiteResponseViewModel } from '@/types/apiServerContract';
import bus from '@/core/bus/index';
import { ModalState } from '@/core/modals/ModalState';
import { isDevMode } from '@/core/environmentService';

@Component
export default class SitePickerModule extends Vue {
    updatingServer: boolean = false;
    cookieInformationRetryCounter: number = 0;
    validateSite() {
        const isLoggedIn = authService.isLoggedIn();
        const isBot = (botService.uaIsPrerender() || botService.uaIsBot());
        const isOnFrontpage = document.location.pathname === window.servercontext.url;
        const isModalActive = this.modals.length > 0;
        if (!isLoggedIn && !isBot && !siteService.hasBeenPickedByUser() && !isModalActive && isOnFrontpage) {
            // Don't show sitePicker if there is also a cookieinformation.com banner shown
            if (this.hasUserGivenCookieConsent() || this.isDevelopment()) {
                setTimeout(this.showSitePicker, 1000);
                return;
            }
            if (window.CookieInformation || this.cookieInformationRetryCounter++ < 5) {
                // window.CookieInformation: true when script is loaded and user is reading cookie banner
                // this.cookieInformationRetryCounter: retry counter if cookieinformation script fails to load
                setTimeout(this.validateSite, 1000);
            } else if (!window.CookieInformation) {
                this.showSitePicker();
            }
        }
    }

    isDevelopment(): boolean {
        return isDevMode();
    }

    get modals() {
        return ModalState.modals;
    }

    hasUserGivenCookieConsent(): boolean {
        return window.CookieInformation?.getConsentGivenFor('cookie_cat_functional') !== undefined;
    }

    async showSitePicker() {
        const currentSiteId = window.servercontext.siteId;
        const selectedSite: any = await sitePickerOverlayService.show();
        const selectedSiteId = selectedSite?.siteId;
        if (selectedSite && currentSiteId !== selectedSiteId) {
            await this.submit(selectedSiteId);
        }
    }

    async submit(siteId) {
        if (this.updatingServer) { return; }

        try {
            this.updatingServer = true;
            const result: SwitchSiteResponseViewModel = await Api.sites.switchSite({ siteId }, 'switch-site');

            if (result.token) {
                authService.setToken(result.token);
            }
            siteService.update(result);
        } catch (err) {
            // Ok, validation-messages will be shown
        } finally {
            this.updatingServer = false;
        }
    }

    get isLoggedIn() {
        return authService.isLoggedIn();
    }

    @Watch('isLoggedIn')
    async loadSiteOptions() {
        const siteOptions = await Api.sites.siteOptions();
        siteService.setCountryOptions(siteOptions);

        const siteHasMultipleSiteOptions = siteOptions?.countries?.length && (siteOptions?.countries.length > 1 || siteOptions.countries[0].sites.length > 1);
        if (siteHasMultipleSiteOptions) {
            this.validateSite();
        }
    }

    render() {
        return undefined;
    }

    created() {
        this.loadSiteOptions();
        bus.on(SHOW_SITE_PICKER, this.showSitePicker);
    }

    destroyed() {
        bus.off(SHOW_SITE_PICKER, this.showSitePicker);
    }
}
