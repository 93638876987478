






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import viewportEvents, { Viewport } from '@/core/responsive/viewport/viewportEvents.service';
import { range } from 'lodash';

type CallbackType = (on: boolean) => void;

interface ExtendedScrollPointDefinition {
    clazz?: string;
    callback?: CallbackType;
}

type ScrollPointDefinition = string | CallbackType | ExtendedScrollPointDefinition;
type ScrollDirection = 'up' | 'down';

export interface ScrollTriggerOptions {
    scrollingDown?: ScrollPointDefinition,
    scrollingUp?: ScrollPointDefinition,

    [key: number]: ScrollPointDefinition
}

const MinVelocity = 0.2;
let customScrollPointIds: string[];
const finalOptions = new Map<string, ExtendedScrollPointDefinition>();

@Component
export default class ScrollTrigger extends Vue {
    classes: string[] = [];
    oldScrollY: number = 0;
    oldTimestamp: number = 0;
    scrollDirection: ScrollDirection | null = null;

    @Prop({
        type: Object,
        default: {}
    })
    options!: ScrollTriggerOptions;

    mounted() {
        viewportEvents.setCallback(this.viewportEventHandler);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { scrollingDown, scrollingUp, ...customScrollDefinitions } = this.options;
        customScrollPointIds = Object.keys(customScrollDefinitions);

        // Create array with room for all classes
        this.classes = range(Object.keys(this.options).length).map(() => '');

        // fixup all scroll-definitions so we can handle them faster runtime
        Object.keys(this.options).forEach(spKey => {
            finalOptions.set(spKey, createExtendedDefinition(spKey, this.options[spKey]));
        });

        function createExtendedDefinition(key: string, scrollPoint: ScrollPointDefinition): ExtendedScrollPointDefinition {
            let clazz = `_${key}_`; // Fallback - in order to keep track of whether callback has been fired.
            let callback;
            if (typeof scrollPoint === 'string') {
                clazz = scrollPoint;
            } else if (typeof scrollPoint === 'function') {
                callback = scrollPoint;
            } else {
                clazz = scrollPoint.clazz || clazz;
                callback = scrollPoint.callback;
            }

            return {
                clazz,
                callback
            };
        }
    }

    destroyed() {
        viewportEvents.removeCallback(this.viewportEventHandler);
    }

    viewportEventHandler(viewport: Viewport, timestamp: number) {
        const scrollY = viewport.scrollY;
        if (scrollY === this.oldScrollY) return;
        this.handleScroll(scrollY, timestamp);
        this.oldScrollY = scrollY;
        this.oldTimestamp = timestamp;
    }

    handleScroll(scrollY: number, timestamp: number) {
        this.handleUpDown(scrollY, timestamp);
        this.handleScrollPoints(scrollY);
    }

    handleUpDown(scrollY: number, timestamp: number) {
        const currentScrollDirection: ScrollDirection = scrollY > this.oldScrollY ? 'down' : 'up';

        if (currentScrollDirection === this.scrollDirection) {
            // No changes
            return;
        }

        if (currentScrollDirection === 'down') {
            this.setScrollPoint('scrollingDown', 0);
            this.clearScrollPoint('scrollingUp', 1);
            this.scrollDirection = 'down';
        } else {
            // Change to scrolling up only if fast enough
            const velocity = Math.abs((scrollY - this.oldScrollY) / (timestamp - this.oldTimestamp));
            if (velocity >= MinVelocity) {
                this.setScrollPoint('scrollingUp', 1);
                this.clearScrollPoint('scrollingDown', 0);
                this.scrollDirection = 'up';
            }
        }
    }

    handleScrollPoints(scrollY: number) {
        customScrollPointIds.forEach((scrollPosId, ix) => {
            if (scrollY >= parseInt(scrollPosId, 10)) {
                this.setScrollPoint(scrollPosId, 2 + ix);
            } else {
                this.clearScrollPoint(scrollPosId, 2 + ix);
            }
        });
    }

    setScrollPoint(scrollPointId: string, position: number) {
        const scrollPointDef = finalOptions.get(scrollPointId);
        if (!scrollPointDef) return;

        // Already set ?
        if (this.classes[position]) return;

        Vue.set(this.classes, position, scrollPointDef.clazz);
        const cb = scrollPointDef.callback;
        if (cb) {
            cb(true);
        }
    }

    clearScrollPoint(scrollPointId: string, position: number) {
        const scrollPointDef = finalOptions.get(scrollPointId);
        if (!scrollPointDef) return;

        // Already cleared ?
        if (!this.classes[position]) return;

        Vue.set(this.classes, position, '');

        const cb = scrollPointDef.callback;
        if (cb) {
            cb(false);
        }
    }
}
