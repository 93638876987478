import Deferred from '@/core/async/Deferred';

export class Ensure {
    private cancelled = false;
    private timedOut = false;
    private deferred = new Deferred<void>();
    private done = false;

    constructor(private expr: () => boolean, private timeout = 5000) {}

    private checkExpression() {
        if (this.done) return;
        if (this.cancelled) {
            this.deferred.reject(new Error('cancelled'));
            this.done = true;
            return;
        }
        if (this.timedOut) {
            this.deferred.reject(new Error('timed out'));
            this.done = true;
            return;
        }
        const fulfilled = this.expr();
        if (fulfilled) {
            this.deferred.resolve();
            this.done = true;
            return;
        }
        setTimeout(() => this.checkExpression(), 100);
    }

    public async start(): Promise<void> {
        if (this.timeout > 0) {
            setTimeout(() => this.timedOut = true, this.timeout);
        }
        this.checkExpression();
        return this.deferred.promise;
    }

    public cancel() {
        this.cancelled = true;
    }
}
