import Vue from 'vue';
import makeStoreDebugAccessible from '@/core/store/storeDebug';
import { localStorageService } from '@/core/storage.service';
import serverContextService, { SiteInfo } from '@/core/serverContext.service';
import { LoginViewModel, SwitchSiteResponseViewModel } from '@/types/apiServerContract';
import { hardRedirect } from '@/core/redirect.service';
import domService from '@/core/dom/dom.service';
import authService from '@/core/auth/auth.service';

const URL_LOCAL_STORAGE_KEY = 'eetgroup-eet-site';
const REFERRER_LOCAL_STORAGE_KEY = 'eetgroup-referrer';

const state: SiteInfo = Vue.observable({
    languageCode: null,
    siteId: -1,
    url: null,
    countryCode: null,
    culture: null,
    customCulture: null,
    erpBusinessEntityId: null,
    marketId: null,
    countryOptions: null,
    maintenanceMode: false
});

makeStoreDebugAccessible('site-service', state);

let documentReferrer = '';
let savedUrl: string | null = null;
init();

function init() {
    // If we have a saved referrer, use that one and clear it.
    documentReferrer = localStorageService.getItemAs<string>(REFERRER_LOCAL_STORAGE_KEY) || document.referrer;
    localStorageService.removeItem(REFERRER_LOCAL_STORAGE_KEY);

    savedUrl = localStorageService.getItemAs<string>(URL_LOCAL_STORAGE_KEY);
    Object.assign(state, serverContextService.siteInfo);
}

function update(siteInfo: LoginViewModel | SwitchSiteResponseViewModel) {
    const redirect = shouldRedirect(state.url, siteInfo.url);
    state.erpBusinessEntityId = siteInfo.erpBusinessEntityId;
    state.marketId = siteInfo.marketId;
    state.culture = siteInfo.culture;
    state.siteId = siteInfo.siteId;
    state.url = siteInfo.url;
    state.languageCode = siteInfo.languageCode;
    state.countryCode = siteInfo.countryCode;
    localStorageService.setItemAs<string>(URL_LOCAL_STORAGE_KEY, state.url);
    savedUrl = state.url;
    if (redirect) {
        hardRedirect(createUrlInPreferredCulture('update'));
    }
}

function shouldRedirect(oldUrl: string | null, newUrl: string | null): boolean {
    return oldUrl?.toLowerCase() !== newUrl?.toLowerCase();
}

function getCountryCode(): string | null {
    return state.countryCode;
}

function getLanguageCode(): string | null {
    return state.languageCode;
}

function getCulture(): string | null {
    return state.culture;
}

function getErpBusinessEntityId(): number | null {
    return state.erpBusinessEntityId;
}

function getMarketId(): number | null {
    return state.marketId;
}

function getUrl(): string {
    return state.url || '';
}

function getSiteId(): number | null {
    return state.siteId;
}

function hasBeenPickedByUser(): boolean {
    return savedUrl != null;
}

function createUrlInPreferredCulture(action: 'init' | 'update') {
    const culture = savedUrl ? savedUrl.split('/')[1] : 'en-eu';
    const pageMatch = domService.getHreflang(culture);

    // Let's first see if we can find a matching hreflang (When using the language picker)
    if (pageMatch) {
        return pageMatch;
    }

    // In case the language is updated using the language picker and no page from hreflang matched - We return the new url
    // On update we do the same for anonymous and authorized users. Authorized users are allowed to switch between markets using the language picker - On init we only do this for anonymous users
    // If the new url does not exist the 404 handler will kick in
    if (action === 'update' || (!authService.isLoggedIn() && action === 'init')) {
        return getNewUrl();
    }

    // If an authenticated user tries to change market manually using the address bar we redirect them to the frontpage instead.
    return `/${culture}`;
}

function setCountryOptions(options) {
    state.countryOptions = options;
}

function getCountryOptions() {
    return state.countryOptions || null;
}

function getNewUrl() {
    // convert /xx-xx/lajaskldfkf?lksadf or /lajaskldfkf?lksadf to /zz-zz/lajaskldfkf?lksadf
    // Where xx-xx is culture and zz-zz is users preferred culture
    let url = location.pathname + location.search;

    if (location.pathname.match(/^\/[a-z]{2}-[a-z]{2}/i)) {
        url = url.substr(6);
    }

    return savedUrl + url;
}

export default {
    update,
    getErpBusinessEntityId,
    getMarketId,
    getCountryCode,
    getLanguageCode,
    getCulture,
    getSiteId,
    getUrl,
    hasBeenPickedByUser,
    getCountryOptions,
    setCountryOptions,
    documentReferrer
};
