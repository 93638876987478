import Vue from 'vue';
import gtmService from './GTM.service';

function trackClick(this: HTMLElement, event: UIEvent): any {
    const aTag = findParentByTagName(event.target, 'A') as HTMLElement;
    if (aTag) {
        const href = aTag.getAttribute('data-tracking-name') || (aTag as HTMLAnchorElement).href;
        gtmService.trackLinkClick(href);
    }
}

function findParentByTagName(element, tagName) {
    let parent = element;

    while (parent !== null && parent.tagName !== tagName.toUpperCase()) {
        parent = parent.parentNode;
    }
    return parent;
}

Vue.directive('linkClickTracker', {
    inserted(el, binding) {
        el.addEventListener('click', trackClick, false);
    },
    unbind(el) {
        el.removeEventListener('click', trackClick);
    }
});
