import { debounce } from 'lodash';
import Deferred from '@/core/async/Deferred';
import UUID from '@/core/uuid.service';
import httpService from '@/core/http/http.service';
import botService from '@/project/site/bot.service';

export interface BulkFetchRequest<T> {
    promise: Promise<T>,
    cancel: () => void
}

export default abstract class AbstractBulkFetch<T> {
    protected activeListenersById: Map<string, Deferred<T>[]> = new Map<string, Deferred<T>[]>();
    protected debouncedFetch = debounce(this.fetch, this.debounceTime);

    private cancellationKey: string = UUID();

    constructor(protected name: string, protected debounceTime: number = 100, protected upperCased: boolean = false, protected ignoreForBots: boolean = false) {}

    public requestData(itemId: string): BulkFetchRequest<T> {
        if (this.upperCased) {
            itemId = itemId.toUpperCase(); // Make sure we are case-insensitive
        }

        const deferred = new Deferred<T>();

        const listeners = this.activeListenersById.get(itemId);
        if (listeners) {
            listeners.push(deferred);
        } else {
            this.activeListenersById.set(itemId, [deferred]);
        }

        const cancelKey = this.cancellationKey;

        const cancelFunc = (deferred: Deferred<T>, key: string) => {
            deferred.reject(null);
            httpService.cancelRequest(key);
        };

        this.debouncedFetch();

        return {
            promise: deferred.promise,
            cancel: () => cancelFunc(deferred, cancelKey)
        };
    }

    private async fetch() {
        const listeners = this.activeListenersById;
        this.activeListenersById = new Map<string, Deferred<T>[]>();

        const cancelKey = this.cancellationKey;
        this.cancellationKey = UUID();

        // Some Fetches can be ignored for Bots
        if (this.ignoreForBots === true && (botService.uaIsPrerender() || botService.uaIsBot())) {
            this.handleResultFromServer(listeners, null, true);
        } else {
            try {
                const ids = Array.from(listeners.keys());
                const result = await this.apiCall(ids, cancelKey);
                const upperCasedResult = this.upperCased ? this.upperCaseResultKeys(result) : result;
                this.handleResultFromServer(listeners, upperCasedResult, !result); // If result is null, we allow an empty response body (just a status 200)
            } catch (e) {
                this.handleResultFromServer(listeners, null, false);
            }
        }
    }

    private upperCaseResultKeys(result: { [key: string]: T } | null) {
        if (!result) return result;

        const o = Object.entries(result).reduce((a, [key, value]) => {
            a[key.toUpperCase()] = value;
            return a;
        }, {});

        return o;
    }

    private handleResultFromServer(listeners: Map<string, Deferred<T>[]>, result: { [key: string]: T } | null, emptyResult: boolean) {
        listeners.forEach((listener: Deferred<T>[], id: string) => {
            const data = result && result[id];
            if (data) {
                listener.forEach(l => l.resolve(data));
            } else if (emptyResult) {
                listener.forEach(l => l.resolve());
            } else {
                listener.forEach(l => l.reject(`[${this.name}]: Unable to find a result for id: ${id}`));
            }
        });
    }

    protected abstract apiCall(ids: string[], cancelKey: string): Promise<{ [key: string]: T } | null>;
}
