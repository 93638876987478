import { isDevMode } from '@/core/environmentService';

// Exposes the state below window.$eetgroupStores$.[your state] when in develop mode.
export default function makeStoreDebugAccessible(key: string, state: any) {
    if (isDevMode()) {
        const mainKey = '$eetgroupStores$';
        window[mainKey] = window[mainKey] || {};
        window[mainKey][key] = state;
    }
}
