








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { JsonContent } from '@/types/contentServerContract';
import { blockResolver, ClientComponentType } from '@/core/app/componentResolver.service';

@Component
export default class BlockRender extends Vue {
    @Prop({
        required: true,
        type: Object,
        validator(value: any): boolean {
            return 'alias' in value && 'content' in value;
        }
    }) block!: JsonContent;

    resolvedBlock(): ClientComponentType | undefined {
        return blockResolver.resolve(this.block.alias)?.component;
    }
}
