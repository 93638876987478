import { AxiosRequestConfig } from 'axios';
import sessionService from '@/project/session/session.service';
import siteService from '@/project/site/site.service';
import authService from '@/core/auth/auth.service';

export default (config: AxiosRequestConfig): AxiosRequestConfig => {
    config.headers = config.headers || {};
    if (siteService.getErpBusinessEntityId() != null) {
        // @ts-ignore
        config.headers['x-eet-businessentityid'] = siteService.getErpBusinessEntityId();
        // @ts-ignore
        config.headers['x-eet-marketid'] = siteService.getMarketId();
    }
    if (authService.hasLoggedInBefore()) {
        config.headers['x-eet-sessionkeyhistory'] = 'true';
    }
    config.headers['x-eet-sessionkey'] = sessionService.getSessionKey();
    config.headers['x-eet-localkey'] = sessionService.getLocalStorageKey();
    config.headers['x-eet-umb-a'] = authService.isLoggedIn();
    // @ts-ignore
    config.headers['x-eet-siteid'] = siteService.getSiteId();

    return config;
};
