






import Vue from 'vue';
import Component from 'vue-class-component';
import GTMService, { ProductTrackingState, TrackingProduct } from './GTM.service';
import { Prop, Watch } from 'vue-property-decorator';
import { TrackingProductAndElement } from '@/project/tracking/gtm/types';
import bus from '@/core/bus';
import { RouterBeforeChangeKey } from '@/core/http/constants';
interface ProductState {
    product: TrackingProduct,
    el: Element,
    visible: boolean
}
export const ProductTrackerProcessBatchKey = 'ProductTrackerProcessBatchKey';
const SETTLE_TIME_MS = 2000;
const IDLE_TIME_MS = 60000;
const MAX_NO_OF_PRODUCTS_IN_BATCH = 15;

@Component
export default class ProductListTracker extends Vue {
    settled = false;
    trackingState: ProductState[] = [];
    idleTimerHandle: number | undefined;
    settleTimerHandle: number | undefined;
    observer = new IntersectionObserver(this.productBecameVisible,
        {
            threshold: [0.1]
        });

    @Prop({ required: true, type: String })
    listName!: string;

    @Prop({ required: true, type: Array })
    products!: TrackingProduct[];

    productImpression(trackingProductAndElement: TrackingProductAndElement) {
        const annotatedProduct = {
            ...trackingProductAndElement.product,
            position: this.positionOfProduct(trackingProductAndElement.product),
            listName: this.listName
        };

        const existingIx = this.trackingState.findIndex(ts => ts.product.wid === trackingProductAndElement.product.wid);
        if (existingIx === -1) {
            this.trackingState.push({
                product: annotatedProduct,
                visible: false,
                el: trackingProductAndElement.el
            });
            this.observer.observe(trackingProductAndElement.el);
        } else {
            // Update - probably because stock / price was loaded
            this.trackingState[existingIx].product = annotatedProduct;
        }
    }

    productClick(product: TrackingProduct) {
        this.sendTrackingBatch(true);
        GTMService.productClick(product, this.listName);
    }

    @Watch('products')
    onProductsChanged(products: TrackingProduct[]) {
        if (this.settled) {
            // Send pending before starting all over with new products
            this.sendTrackingBatch();
        }
        this.settled = false;

        // Clean out all registered products not in new list
        this.trackingState = this.trackingState
            .filter(ts => !!products.find(p => p.wid === ts.product.wid));

        // Don't send tracking until 2 secs. after products-change has settled.
        clearTimeout(this.idleTimerHandle);
        clearTimeout(this.settleTimerHandle);
        this.settleTimerHandle = setTimeout(() => {
            this.settled = true;
            this.maybeSendTracking();
        }, SETTLE_TIME_MS);
    }

    productBecameVisible(elements: IntersectionObserverEntry[]): void {
        elements.forEach(elem => {
            if (elem.isIntersecting) {
                this.observer.unobserve(elem.target);
                const productState = this.trackingState.find(p => p.el === elem.target);
                if (productState) {
                    productState.visible = true;
                }
            }
        });
        this.maybeSendTracking();
    }

    get visibleTrackingProducts(): TrackingProduct[] {
        return this.trackingState
            .filter(ts => ts.visible)
            .map(ts => ts.product);
    }

    maybeSendTracking() {
        if (this.visibleTrackingProducts.length === MAX_NO_OF_PRODUCTS_IN_BATCH) {
            this.sendTrackingBatch();
        } else {
            // Have fewer items than batch size - set idle-timer
            clearTimeout(this.idleTimerHandle);
            this.idleTimerHandle = setTimeout(this.sendTrackingBatch, IDLE_TIME_MS);
        }
    }

    sendTrackingBatch(forced = false) {
        if (!forced && !this.settled) return;

        // Normally only include products that has received price and stock - but when forced (navigating away) - include the 'premature' ones
        const batchToSend = this.visibleTrackingProducts
            .filter(vp => forced ? true : vp.trackingState !== ProductTrackingState.NoErpResponseYet)
            .splice(0, MAX_NO_OF_PRODUCTS_IN_BATCH);

        if (batchToSend.length) {
            GTMService.trackImpressions(batchToSend);
            // Clean up the ones sent
            this.trackingState = this.trackingState
                .filter(ts => !batchToSend.includes(ts.product));
        }
    }

    positionOfProduct(product: TrackingProduct): number {
        return this.products.findIndex(p => p.wid === product.wid) + 1;
    }

    // Use separate function to be able to run bus.off in cleanup
    runTrackingBatchAfterBusUpdate() {
        this.sendTrackingBatch(true);
    }

    cleanup() {
        this.sendTrackingBatch(true);
        this.observer.disconnect();
        window.removeEventListener('beforeunload', this.cleanup);
        clearTimeout(this.settleTimerHandle);
        clearTimeout(this.idleTimerHandle);
        bus.off(ProductTrackerProcessBatchKey, this.runTrackingBatchAfterBusUpdate);
        bus.off(RouterBeforeChangeKey, this.runTrackingBatchAfterBusUpdate);
    }

    created() {
        window.addEventListener('beforeunload', this.cleanup);
        bus.on(ProductTrackerProcessBatchKey, this.runTrackingBatchAfterBusUpdate);
        bus.on(RouterBeforeChangeKey, this.runTrackingBatchAfterBusUpdate);
    }

    beforeDestroy() {
        this.cleanup();
    }
}
