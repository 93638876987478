














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class PlusAccept extends Vue {
    @Prop({
        required: true,
        type: String,
        default: 'plus',
        validator: value => ['plus', 'accept'].includes(value as string)
    })
    mode;
}
