// Depends on JSNLog which is probably loaded by serverside scripting.

import Vue from 'vue';
import StackTrace from 'stacktrace-js';
import logging from './logging.service';
import { isProdMode } from '@/core/environmentService';

function init() {
    Vue.config.errorHandler = function(err, vm, info) {
        if (doStackTrace()) {
            StackTrace.fromError(err)
                .then(stackframes => logStackframes(stackframes, err, info))
                .catch(logging.error);
        } else {
            logging.error(err);
        }
    };

    window.onerror = function(errorMsg, url, lineNumber, column, errorObj) {
        if (doStackTrace() && errorObj) {
            StackTrace.fromError(errorObj)
                .then(stackframes => logStackframes(stackframes, errorObj, errorMsg, url, lineNumber, column))
                .catch(logging.error);
            return true;
        } else {
            logging.error(errorObj);
        }
    };

    window.onunhandledrejection = function(event) {
        if (event && event.reason) {
            if (doStackTrace()) {
                StackTrace.fromError(event.reason)
                    .then(stackframes => logStackframes(stackframes, event.reason.message, event.reason))
                    .catch(e => logging.error(event.reason));
            } else {
                logging.error(event);
            }
        }
    };

    function logStackframes(stackframes, errorObj, errorMsg, url?, lineNumber?, column?) {
        const stringifiedStack = stackframes
            .map(sf => {
                return sf.toString();
            })
            .join('\n');

        logging.exception(
            {
                msg: 'Exception! ' + stringifiedStack,
                errorMsg: errorMsg,
                url: url,
                lineNumber: lineNumber,
                column: column
            },
            errorObj
        );
    }

    function doStackTrace() {
        return isProdMode() && window.servercontext?.siteChrome?.settings?.enableClientSideLogging;
    }
}

init();
