
import Vue from 'vue';
import { map } from 'lodash';
import Component from 'vue-class-component';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

// Define as many slots you want with their 'name' set to breakpoints:
// <breakpoints>
//   <div slot="sm">
//     xx
//   </div>
//   <div slot="min-md,max-xl">
//     yy
//   </div>
//   <div slot="not-xs,not-md,max-xl"> - for all except md and xs
//   </div>
// </breakpoints>

@Component
export default class Breakpoint extends Vue {
    created() {
        if (!this.$slots) {
            throw new Error('You must provide at least 1 slot');
        }
        Object.keys(this.$slots).forEach(slotName => {
            if (this.$slots[slotName] && this.$slots[slotName]!.length !== 1) {
                throw new Error(`There must be exactly 1 element for each breakpoint slot. ${slotName} has ${this.$slots[slotName]!.length} Parent is ${this.$parent?.$options?.name || 'Unknown'}`);
            }
        });
    }

    render(h) {
        const visibleSlots = map(this.$slots, (slot, key) => {
            return {
                visible: breakpointsState.isBreakpointActive(key),
                slot
            };
        })
            .filter(x => x.visible)
            .map(x => x.slot);
        return visibleSlots.length && visibleSlots[0] ? visibleSlots[0][0] : undefined;
    }
}
