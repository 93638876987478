import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    AddOrUpdatePlaceholderItemToRackConfigurationRequest,
    AddPrimaryItemToConfigurationRequest,
    AddSecondaryItemToConfigurationRequest,
    DownloadAssemblyGuideRequest,
    DownloadPriceListRequest,
    DuplicateRackViewModel,
    MoveItemInConfigurationRequest,
    RackAccessoriesSearchViewModel,
    RackConfigurationsViewModel,
    RackConfigurationViewModel,
    RackSearchRequest,
    RackSearchViewModel,
    RemoveItemFromConfigurationRequest,
    UpdateRackConfigurationNameRequest
} from '@/types/apiServerContract';
import { AxiosResponse } from 'axios';

export class RackController {
    private readonly urlFactoryRackSearch = createUrlFactory('racksearch');
    private readonly urlFactoryRackConfiguration = createUrlFactory('rackconfiguration');
    private readonly urlFactoryRackConfigurator = createUrlFactory('rackconfigurator');

    public async searchRack(request: RackSearchRequest): Promise<RackSearchViewModel> {
        return httpService.get(this.urlFactoryRackSearch('searchracks'), request);
    }

    public async searchRackAccessories(wid: string): Promise<RackAccessoriesSearchViewModel> {
        return httpService.get(this.urlFactoryRackSearch('searchaccessories'), { wid });
    }

    public async getConfiguration(configurationId?: string): Promise<RackConfigurationViewModel> {
        return httpService.get(this.urlFactoryRackConfigurator('getconfiguration'), { configurationId });
    }

    public async createConfiguration(rackItemId: string): Promise<RackConfigurationViewModel> {
        return httpService.post(this.urlFactoryRackConfigurator('createrackconfiguration'), { rackItemId });
    }

    public async addPrimaryItemToConfiguration(request: AddPrimaryItemToConfigurationRequest, messageId?: string): Promise<RackConfigurationViewModel> {
        return httpService.post(this.urlFactoryRackConfigurator('addprimaryitemtoconfiguration'), request, messageId);
    }

    public async movePrimaryItemInConfiguration(request: MoveItemInConfigurationRequest): Promise<RackConfigurationViewModel> {
        return httpService.post(this.urlFactoryRackConfigurator('moveiteminconfiguration'), request);
    }

    public async addOrUpdateSecondaryItemToConfiguration(request: AddSecondaryItemToConfigurationRequest): Promise<RackConfigurationViewModel> {
        return httpService.post(this.urlFactoryRackConfigurator('addorupdatesecondaryitemtoconfiguration'), request);
    }

    public async removeItemFromConfiguration(request: RemoveItemFromConfigurationRequest): Promise<RackConfigurationViewModel> {
        return httpService.delete(this.urlFactoryRackConfigurator(
            'removeitemfromconfiguration'),
        undefined,
        {
            data: { ...request }
        });
    }

    public async renameRackConfiguration(configurationId: string, name: string): Promise<RackConfigurationViewModel> {
        const request: UpdateRackConfigurationNameRequest = {
            configurationId, name
        };
        return httpService.post(this.urlFactoryRackConfigurator('updaterackconfigurationname'), request);
    }

    public async downloadPriceList(request: DownloadPriceListRequest): Promise<any> {
        const result = await httpService.post<AxiosResponse>(
            this.urlFactoryRackConfigurator('downloadpricelist'),
            request,
            undefined,
            undefined,
            {
                responseType: 'blob'
            },
            true);

        const fileName = httpService.getContentDispositionFilename(result);
        const file = result.data as Blob;

        return {
            fileName,
            file
        };
    }

    public async downloadAssemblyGuide(request: DownloadAssemblyGuideRequest): Promise<any> {
        const result = await httpService.post<AxiosResponse>(
            this.urlFactoryRackConfigurator('downloadassemblyguide'),
            request,
            undefined,
            undefined,
            {
                responseType: 'blob'
            },
            true);

        const fileName = httpService.getContentDispositionFilename(result);
        const file = result.data as Blob;

        return {
            fileName,
            file
        };
    }

    public async addPlaceholderItemToConfiguration(request: AddOrUpdatePlaceholderItemToRackConfigurationRequest, messageId?: string): Promise<RackConfigurationViewModel> {
        return httpService.post(this.urlFactoryRackConfigurator('addorupdateplaceholderitemtoconfiguration'), request, messageId);
    }

    public async loadUsersConfigurations(wid?: string): Promise<RackConfigurationsViewModel> {
        return httpService.get(this.urlFactoryRackConfiguration('list'), { rackWid: wid });
    }

    public async duplicateUsersConfigurations(configurationId?: string): Promise<DuplicateRackViewModel> {
        return httpService.post(this.urlFactoryRackConfiguration('duplicate'), { configurationId });
    }

    public async deleteUsersConfigurations(configurationId?: string): Promise<RackConfigurationsViewModel> {
        return httpService.delete(this.urlFactoryRackConfiguration('delete'), { configurationId });
    }
}
