























import Vue from 'vue';
import Component from 'vue-class-component';
import ScrollTrigger, { ScrollTriggerOptions } from '@/core/scroll/ScrollTrigger.vue';
import BrandHeader from '@/project/layout/page-header/BrandHeader.vue';
import TopHeader from './TopHeader.vue';
import MainNavigation from './MainNavigation.vue';
import ImpersonationBanner from '@/project/shared/impersonation/ImpersonationBanner.vue';
import themeService from '@/project/app/themes/theme.service';
import authService from '@/core/auth/auth.service';
import appStore from '@/core/app/app.store';
import { PageName } from '@/router/routes/constants';

@Component({
    components: { MainNavigation, TopHeader, BrandHeader, ScrollTrigger, ImpersonationBanner }
})
export default class DesktopPageHeader extends Vue {
    mainNavigationTransitionEnabled = false;
    get mainNavigationFixed() {
        return appStore.desktopMainNavigationFixed;
    }

    get megaMenuShown() {
        return appStore.desktopMegaMenuShown;
    }

    scrollingUp(on: boolean) {
        on ? themeService.restoreBackgroundOpacity() : themeService.disableBackgroundOpacity();
    }

    topHeaderFixed(on: boolean) {
        if (!on) {
            appStore.desktopMainNavigationFixed = on;
            this.mainNavigationTransitionEnabled = false;
        } else {
            themeService.disableBackgroundOpacity();
        }
    }

    megaMenuShownChange(shown: boolean): void {
        appStore.desktopMegaMenuShown = shown;
    }

    mainNavigationHidden(on: boolean) {
        if (on) {
            appStore.desktopMainNavigationFixed = true;
            // Avoid immediate transition when we go to fixed.
            setTimeout(() => this.mainNavigationTransitionEnabled = true, 25);
        }
    }

    get scrollTriggerOptions(): ScrollTriggerOptions {
        return this.showBrands
            ? {
                scrollingUp: { clazz: 'scrolling-up', callback: this.scrollingUp },
                60: { clazz: 'top-header-fixed', callback: this.topHeaderFixed },
                120: this.mainNavigationHidden
            }
            : {
                scrollingUp: { clazz: 'scrolling-up', callback: this.scrollingUp },
                1: { clazz: 'top-header-fixed', callback: this.topHeaderFixed },
                120: this.mainNavigationHidden
            };
    }

    get showBrands(): boolean {
        // not logged in on frontpage: false, else true
        if (this.isOnFrontpage && this.isLoggedIn === false) {
            return false;
        }
        return true;
    }

    get isLoggedIn(): boolean {
        return authService.isLoggedIn();
    }

    get isOnFrontpage(): boolean {
        return appStore.isFrontPage;
    }

    get isSearchOpen(): boolean {
        return this.$route.name === PageName.SEARCH;
    }
}
