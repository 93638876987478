import httpService from '@/core/http/http.service';
import { createUrlFactory, shouldExclude } from '@/project/http/ApiHelper.service';
import { createCancellableGet } from '@/core/http/cancellableGet';
import { omitBy } from 'lodash';
import {
    ProductGuideBrandViewModel,
    ProductGuideSeriesViewModel,
    ProductGuideModelViewModel,
    ProductGuideItemViewModel,
    ProductGuideSearchRequest,
    ProductGuideSearchViewModel,
    ProductGuideConfigurationViewModel,
    ProductGuideViewModel,
    ProductGuideSearchResultViewModel,
    ProductGuideBrandsViewModel,
    ProductGuideSerialSearchResultViewModel
} from '@/types/apiServerContract';
import serverContextService from '@/core/serverContext.service';
import { ProductGuideBrandSettingsViewModel } from '@/types/contentServerContract';
import { UrlFacets } from '@/core/urlQueryKey';

export class ProductGuideController {
    private readonly urlFactory = createUrlFactory('ProductGuide');
    private readonly externalUrlFactory = createUrlFactory('externalproductguide');

    public async getBrands(guideId: string): Promise<ProductGuideBrandViewModel> {
        return httpService.get<ProductGuideBrandViewModel>(this.urlFactory('GetBrands'), {
            guideId
        });
    }

    private cancellableSeries = createCancellableGet<ProductGuideSeriesViewModel>();

    public async getSeries(guideId: string, brandId: string): Promise<ProductGuideSeriesViewModel> {
        return this.cancellableSeries(this.urlFactory('GetSeries'), {
            guideId,
            brandId
        });
    }

    private cancellableModels = createCancellableGet<ProductGuideModelViewModel>();

    public async getModels(guideId: string, brandId: string, seriesName: string): Promise<ProductGuideModelViewModel> {
        return this.cancellableModels(this.urlFactory('GetModels'), {
            guideId,
            brandId,
            seriesName
        });
    }

    private cancellableItems = createCancellableGet<ProductGuideItemViewModel>();

    public async getItems(searchRequest: ProductGuideSearchRequest, selectedFacets: UrlFacets): Promise<ProductGuideItemViewModel> {
        const params = omitBy({ ...searchRequest, ...selectedFacets }, shouldExclude);
        return this.cancellableItems(this.urlFactory('GetItems'), params);
    }

    private cancellableSearch = createCancellableGet<ProductGuideSearchViewModel>();

    public async search(guideId: string, searchText: string): Promise<ProductGuideSearchViewModel> {
        return this.cancellableSearch(this.urlFactory('Search'), {
            guideId,
            searchText
        });
    }

    public async getConfiguration(externalId): Promise<ProductGuideConfigurationViewModel> {
        return httpService.get(this.urlFactory('ProductGuideConfiguration'), {
            externalId: externalId,
            sourceDomain: document.referrer || 'unknown'
        });
    }

    public async getCableGuideBrands(brandType?: string): Promise<string[]> {
        return httpService.get(this.urlFactory('getcablebrands'), {
            brandType
        });
    }

    public async trackclick(productClick: {
        externalId: string,
        productId: string,
        destination: 0 | 1
    }): Promise<any> {
        try {
            // Browser might not allow us to get anything into document.referrer https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy
            return httpService.get(this.externalUrlFactory('trackclick'), {
                externalId: productClick.externalId,
                sourceDomain: document.referrer || 'unknown',
                productId: productClick.productId,
                destination: productClick.destination
            });
        } catch (e) {}
    }

    public async getProductGuideBrand(): Promise<ProductGuideBrandsViewModel> {
        return httpService.get(this.urlFactory('GetProductGuideBrands'));
    }

    public async getProductGuide(params: { brandIdOrName: string, productGuideKeyOrId?: string }): Promise<ProductGuideViewModel> {
        return httpService.get(this.urlFactory('getproductguide'), params);
    }

    public async getProductGuidesSettings(): Promise<ProductGuideBrandSettingsViewModel> {
        return httpService.getRaw(`${serverContextService.contentHost}/umbraco/api/ProductGuideSettings/GetProductGuideBrandSettings?culture=${serverContextService.siteInfo.culture}&sitename=${serverContextService.siteName}`);
    }

    public async findProductGuideModel(params: { brandIdOrName: string, searchTerm: string}, contextKey, messageId: string): Promise<ProductGuideSearchResultViewModel> {
        return httpService.get(this.urlFactory('findproductguidemodel'), params, contextKey, undefined, messageId);
    }

    public async findProductFromSerialNumber(params: { brandIdOrName: string, serialNumber: string}, contextKey: string, messageId: string): Promise<ProductGuideSerialSearchResultViewModel> {
        return httpService.get(this.urlFactory('findproductfromserialnumber'), params, contextKey, undefined, messageId);
    }
}
