import Vue from 'vue';
import makeStoreDebugAccessible from '@/core/store/storeDebug';
import {
    AccountHeaderOrderType,
    AccountLedgerViewObject,
    AccountLineType,
    AddOrUpdateFavoriteItemListRequest, AllUsersRequest,
    CreateFavoriteListRequest,
    CustomerViewObject,
    DeliveryAddressViewObject,
    FavoriteListPermission,
    FavoriteListViewModel,
    FavoriteListViewObject,
    LedgerDocumentType,
    LedgerRequest,
    NotificationType,
    OrderHeaderRequest,
    OrderHeaderViewObject,
    OrderHistoryDetailViewObject,
    OrderLinesRequest,
    OrderLineViewObject,
    RemoveItemsFromFavoriteListRequest,
    SendMailRequest,
    UpdateFavoriteListRequest,
    UserViewObject,
    RmaHeaderViewObject
} from '@/types/apiServerContract';
import Api from '@/project/http/Api.service';
import loggingService from '@/core/logging.service';
import dictionaryService from '@/core/translation/dictionary.service';
import urlHelperService from '@/core/urlHelper.service';
import GTMBasics from '@/project/tracking/gtm/GTM.basics';

interface IMyAccountState {
    isServiceAvailable: boolean,

    account: UserViewObject | null,
    customer: CustomerViewObject | null,
    orderDetails: OrderHistoryDetailViewObject[],
    orderHeaders: Map<AccountHeaderOrderType, readonly OrderHeaderViewObject[]>,
    orderLines: Map<AccountLineType, readonly OrderLineViewObject[]>,
    associatedUsers: UserViewObject[];
    inactiveUsersCount: number;
    ledgerOrders: AccountLedgerViewObject[];
    ledgerDocumentTypes: LedgerDocumentType[];
    legerTotalHits: number;
    legerPageSize: number;
    legerPage: number;
    userNotifications: NotificationType[];
    editProductGuidesMenuItem: boolean;
    showNextDeliveryMenuItem: boolean;
    showDirectDebitBlock: boolean;
    showRackConfigurationsMenuItem: boolean;
    invoices: SendMailRequest[];
    rmaHistory: RmaHeaderViewObject[];
    addresses: DeliveryAddressViewObject[];
    allowedCountryCodes: string[];
    favouriteLists: FavoriteListViewModel | null
}

export interface AddressWithSearchKey extends DeliveryAddressViewObject {
    searchKey?: string;
}

class MyAccountStore {
    private state: IMyAccountState = Vue.observable({
        isServiceAvailable: true,
        account: null,
        customer: null,
        orderDetails: [],
        orderHeaders: new Map<AccountHeaderOrderType, readonly OrderHeaderViewObject[]>(),
        orderLines: new Map<AccountLineType, readonly OrderLineViewObject[]>(),
        associatedUsers: [],
        inactiveUsersCount: 0,
        ledgerOrders: [],
        ledgerDocumentTypes: [],
        legerTotalHits: 0,
        legerPageSize: 0,
        legerPage: 1,
        userNotifications: [],
        invoices: [],
        rmaHistory: [],
        addresses: [],
        allowedCountryCodes: [],
        favouriteLists: null,
        showNextDeliveryMenuItem: false,
        showRackConfigurationsMenuItem: false,
        editProductGuidesMenuItem: false,
        showDirectDebitBlock: true
    });

    constructor() {
        makeStoreDebugAccessible('myAccountStore', this.state);
    }

    public get isServiceAvailable() {
        return this.state.isServiceAvailable;
    }

    async getAccountInformation() {
        const response = await Api.user.account();
        this.state.account = response.userViewObject;
    }

    async getCustomerDetails() {
        const result = await Api.customer.customer();
        this.state.customer = result.customer;
    }

    private async getCustomerPermissions() {
        try {
            const result = await Api.account.permissions();
            this.state.editProductGuidesMenuItem = result.showCustomProductGuides;
            this.state.showDirectDebitBlock = result.showDirectDebitBlock;
            this.state.showNextDeliveryMenuItem = result.showNextDeliveryAdministration;
            this.state.showRackConfigurationsMenuItem = result.showRackConfigurationMenuItem;
        } catch (e) {
            this.state.userNotifications = [];
        }
    }

    private async getCustomerNotifications() {
        try {
            const result = await Api.account.notifications();
            this.state.userNotifications = result.notificationItems;
        } catch (e) {
            this.state.userNotifications = [];
        }
    }

    public get hasProductGuideConfigurations(): boolean {
        return this.state.editProductGuidesMenuItem;
    }

    public get showNextDeliveryMenuItem(): boolean {
        return this.state.showNextDeliveryMenuItem;
    }

    public get showShowRackConfigurationsMenuItem(): boolean {
        return this.state.showRackConfigurationsMenuItem;
    }

    public get showDirectDebitBlock(): boolean {
        return this.state.showDirectDebitBlock;
    }

    public notificationForMenuItem(menuItem: NotificationType): boolean {
        return !!this.state.userNotifications.find(i => i === menuItem);
    }

    public get customerIsDue():boolean {
        return !!this.state.userNotifications.find(i => i === NotificationType.Ledger);
    }

    public get account() {
        return this.state.account;
    }

    public get customer() {
        return this.state.customer;
    }

    public updateAccount(user: UserViewObject) {
        Object.assign(this.state.account, user);
    }

    public async fetchOrderHeaders(request: OrderHeaderRequest): Promise<boolean> {
        this.state.isServiceAvailable = true;

        try {
            const result = await Api.account.orderHeaders(request);
            this.state.isServiceAvailable = result.isServiceAvailable;

            if (result.isServiceAvailable) {
                this.state.orderHeaders = new Map(this.state.orderHeaders.set(request.type, Object.freeze(result.orderInformation)));
            }
            return result.resultOverflow;
        } catch (e) {
            loggingService.error(e);
        }

        return false;
    }

    public getOrderHeaderByOrderId(id: string): OrderHeaderViewObject | null {
        for (const entry of Array.from(this.state.orderHeaders.entries())) {
            for (const item of entry[1]) {
                if (item.orderId === id) { return item; }
            }
        }

        return null;
    }

    public getOrderHeaders(type: AccountHeaderOrderType): readonly OrderHeaderViewObject[] {
        return this.state.orderHeaders.get(type) ?? [];
    }

    public async fetchOrderLines(request: OrderLinesRequest): Promise<boolean> {
        this.state.isServiceAvailable = true;

        try {
            const result = await Api.account.orderLines(request);
            this.state.isServiceAvailable = result.isServiceAvailable;

            if (result.isServiceAvailable) {
                this.state.orderLines = new Map(this.state.orderLines.set(request.type, Object.freeze(result.orderInformation)));
            }

            return result.resultOverflow;
        } catch (e) {
            loggingService.error(e);
        }

        return false;
    }

    public getOrderLines(type: AccountLineType): readonly OrderLineViewObject[] {
        return this.state.orderLines.get(type) ?? [];
    }

    public async getAssociatedUsers(payload?: AllUsersRequest) {
        const result = await Api.user.allByCustomer(payload);
        this.state.associatedUsers = result.users;
        this.state.inactiveUsersCount = result.inactiveUsers;
        return result;
    }

    public get associatedUsers() {
        return this.state.associatedUsers;
    }

    public get inactiveUsersCount() {
        return this.state.inactiveUsersCount;
    }

    public updateAssociatedUser(user: UserViewObject) {
        const associatedUser = this.associatedUsers.find(x => x.userId === user.userId);
        if (associatedUser) {
            Object.assign(associatedUser, user);
        }
    }

    public addAssociatedUser(user: UserViewObject) {
        this.state.associatedUsers.push(user);
    }

    public async removeAssociatedUser(userId: string, deleteApiKey: string) {
        const index = this.state.associatedUsers.findIndex(x => x.userId === userId);
        if (index < 0) return false;

        try {
            await Api.user.delete(userId, deleteApiKey);
            if (this.state.associatedUsers[index].isInactive) {
                this.state.inactiveUsersCount--;
            }
            this.state.associatedUsers.splice(index, 1);
            return true;
        } catch (e) {
            loggingService.error(e);
            return false;
        }
    }

    public async getAddresses() {
        const addressesPromise = Api.delivery.getDeliveryAddresses()
            .then(result => {
                this.state.addresses = result.deliveryAddresses;
                this.state.addresses.sort(this.orderAddress);
            });
        const AllowedCountryCodesPromise = Api.customer.shipToCountries()
            .then(result => this.state.allowedCountryCodes = result.countries);
        await Promise.all([addressesPromise, AllowedCountryCodesPromise]);
    }

    public get addresses(): AddressWithSearchKey[] {
        return this.state.addresses;
    }

    public async updateAddress(address: DeliveryAddressViewObject, messagesId: string) {
        const updatedAddress = await Api.delivery.addOrUpdateDeliveryAddress(address, messagesId);
        const existingAddr = this.addresses.find(x => x.webId === updatedAddress.webId);
        if (existingAddr) {
            if (address.isDefault && !existingAddr.isDefault) {
                // Address was set to default - clear old one
                const currentDefaultAddr = this.addresses.find(x => x.isDefault);
                if (currentDefaultAddr) currentDefaultAddr.isDefault = false;
            }
            Object.assign(existingAddr, updatedAddress);
            // Invalidate searchkey and sort again
            existingAddr.searchKey = undefined;
            this.state.addresses.sort(this.orderAddress);
        }
    }

    public async addAddress(address: DeliveryAddressViewObject, messagesId: string) {
        const newAddress = await Api.delivery.addOrUpdateDeliveryAddress(address, messagesId);
        if (address.isDefault) {
            // Address was set to default - clear old one
            const currentDefaultAddr = this.addresses.find(x => x.isDefault);
            if (currentDefaultAddr) currentDefaultAddr.isDefault = false;
        }
        this.state.addresses.push(newAddress);
        this.state.addresses.sort(this.orderAddress);
    }

    public async removeAddress(address: DeliveryAddressViewObject, deleteApiKey: string) {
        const index = this.state.addresses.findIndex(x => x.webId === address.webId);
        if (index < 0) return false;

        if (!address.webId) {
            loggingService.error('MyAccountStore.RemoveAddress: webId is null for ' + JSON.stringify(address));
            return;
        }

        try {
            await Api.delivery.deleteDeliveryAddress(address.webId, deleteApiKey);
            this.state.addresses.splice(index, 1);
            this.state.addresses.sort(this.orderAddress);
            return true;
        } catch (e) {
            loggingService.error(e);
            return false;
        }
    }

    public get allowedCountryCodes(): string[] {
        return this.state.allowedCountryCodes;
    }

    private orderAddress(a: DeliveryAddressViewObject, b: DeliveryAddressViewObject): number {
        // Always default first, if present, then alphabetic on company
        if (a.isDefault) return -1;
        if (b.isDefault) return 1;
        return ('' + a.companyName).localeCompare(b.companyName);
    }

    public async fetchLedger(request: LedgerRequest) {
        this.state.isServiceAvailable = true;
        if (this.state.legerPageSize > 0 && !request.pageSize) {
            request.pageSize = this.state.legerPageSize;
        }

        try {
            const result = await Api.account.ledger(request);
            this.state.ledgerOrders = result.orderInformation;
            this.state.ledgerDocumentTypes = result.documentTypes;
            this.state.isServiceAvailable = result.isServiceAvailable;
            this.state.legerTotalHits = result.totalHits;
            this.state.legerPage = result.page;
            this.state.legerPageSize = result.pageSize;
            if (request.page && urlHelperService.getPage() !== request.page) {
                urlHelperService.setPage(request.page);
            }
        } catch (e) {
            loggingService.error(e);
        }
    }

    public getLedgerOrders(): readonly AccountLedgerViewObject[] {
        return this.state.ledgerOrders ?? [];
    }

    public getLedgerDocumentTypes(): readonly LedgerDocumentType[] {
        return this.state.ledgerDocumentTypes ?? [];
    }

    public getLedgerPageSize(): number {
        return this.state.legerPageSize ?? 0;
    }

    public getLedgerPage(): number {
        return this.state.legerPage ?? 1;
    }

    public getLedgerTotalHits(): number {
        return this.state.legerTotalHits ?? 0;
    }

    public updateInvoiceArray(invoice: SendMailRequest) {
        const orderIndex = this.state.invoices.findIndex(order => order.documentNumber === invoice.documentNumber);

        if (orderIndex === -1) {
            this.state.invoices.push(invoice);
        } else {
            this.state.invoices.splice(orderIndex, 1);
        }
    }

    public async fetchRmaHistoryVersion2() {
        const result = await Api.rma.getRmaHistory();
        this.state.rmaHistory = result.rmaHeaders;
    }

    public get rmaHistory() {
        return this.state.rmaHistory;
    }

    public setServiceAvailableStatus(status: boolean) {
        this.state.isServiceAvailable = status;
    }

    public async fetchAllFavouriteLists() {
        try {
            this.state.favouriteLists = await Api.favorites.getAllLists();
        } catch (error) {
            loggingService.error(error);
        }
    }

    public get favouriteLists() {
        return this.state.favouriteLists;
    }

    public get allFavouriteLists() {
        const lists = [
            ...this.state.favouriteLists?.private ?? [],
            ...this.state.favouriteLists?.myShared ?? [],
            ...this.state.favouriteLists?.sharedWithMe ?? []
        ];

        return lists.length ? lists : [this.defaultList];
    }

    public get editAbleFavoriteLists() {
        if (!this.state.favouriteLists) return [];

        const editAbleLists =
         ([
             ...this.state.favouriteLists.myShared,
             ...this.state.favouriteLists.sharedWithMe])
             .filter(x => x.permission === FavoriteListPermission.Edit);

        return [...this.state.favouriteLists.private, ...editAbleLists];
    }

    public async deleteFavouriteList(id: number) {
        try {
            this.state.favouriteLists = await Api.favorites.delete(id);
            return true;
        } catch (error) {
            loggingService.error(error);
            return false;
        }
    }

    public async removeFromSharedFavouriteList(id: number) {
        try {
            this.state.favouriteLists = await Api.favorites.removeFromSharedList(id);
            return true;
        } catch (error) {
            loggingService.error(error);
            return false;
        }
    }

    public async addFavoriteItem(request: AddOrUpdateFavoriteItemListRequest) {
        const list = this.allFavouriteLists.find(x => x.id === request.listId);
        if (!list) return;

        try {
            await Api.favorites.addOrUpdateItem(request);
            list.itemsCount += request.items.length;
        } catch (error) {
            loggingService.error(error);
        }

        GTMBasics.pushToDataLayer({
            event: 'button.click',
            buttonTarget: 'addToFavourites'
        });
    }

    public async removeFavoriteItems(request: RemoveItemsFromFavoriteListRequest) {
        const list = this.allFavouriteLists.find(x => x.id === request.listId);
        if (!list) return;

        try {
            await Api.favorites.removeItems(request);
            Math.max(0, list.itemsCount -= request.itemIds.length);
        } catch (error) {
            loggingService.error(error);
        }

        GTMBasics.pushToDataLayer({
            event: 'button.click',
            buttonTarget: 'removeFromFavourites'
        });
    }

    public async createFavouriteList(request: CreateFavoriteListRequest) {
        const updatedFavouriteLists = await Api.favorites.create(request);

        this.state.favouriteLists = updatedFavouriteLists;

        return updatedFavouriteLists;
    }

    public async updateFavouriteList(request: UpdateFavoriteListRequest) {
        this.state.favouriteLists = await Api.favorites.update(request);
    }

    public async init() {
        await Promise.all([
            this.getAccountInformation(),
            this.getCustomerDetails(),
            this.fetchAllFavouriteLists(),
            this.getCustomerPermissions(),
            this.getCustomerNotifications()]);
    }

    public clear() {
        this.state.isServiceAvailable = true;
        this.state.account = null;
        this.state.customer = null;
        this.state.orderDetails = [];
        this.state.orderHeaders = new Map<AccountHeaderOrderType, readonly OrderHeaderViewObject[]>();
        this.state.orderLines = new Map<AccountLineType, readonly OrderLineViewObject[]>();
        this.state.ledgerOrders = [];
        this.state.invoices = [];
        this.state.addresses = [];
        this.state.allowedCountryCodes = [];
        this.state.favouriteLists = null;
    }

    get defaultList(): FavoriteListViewObject {
        return {
            createdBy: -1,
            id: -1,
            itemsCount: 0,
            name: dictionaryService.get('FavoriteList.DefaultListName'),
            permission: FavoriteListPermission.Edit
        };
    };
}

export default new MyAccountStore();
