export function isDevMode(): boolean {
    return !isProdMode();
}

export function isProdMode(): boolean {
    return process.env.NODE_ENV === 'production';
}

export function isExternalProductGuide(): boolean {
    return window.servercontext.isExternalProductGuide;
}
