import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import redirectInterceptor from './redirect.interceptor';
import { RedirectError } from './RedirectError';
import validationErrorsInterceptor from './error.interceptor';
import {
    requestHandler as oAuthRequestInterceptor,
    responseHandler as oAuthResponseInterceptor
} from '@/core/auth/OAuth.interceptor';
import siteInterceptor from '@/project/site/site.interceptor';
import * as querystring from 'qs';
import { forEach, isArray } from 'lodash';
import HttpStatus from 'http-status-codes';
import { PageData } from '@/types/contentServerContract';
import siteService from '@/project/site/site.service';
import { HttpCancelError } from '@/core/http/HttpCancelError';
import bus from '@/core/bus';
import errorCodeService from '@/core/errorCode.service';
import {
    HasOutstandingXhrCallsEventKey,
    potentialMaintenanceMaxFailedRequestLimit,
    potentialMaintenanceMaxFailedRequestLimitReachedKey
} from '@/core/http/constants';
import { PageRenderedEventKey } from '@/router/routes/constants';

// Those status'es that should cause "then" to be executed (so we can have interceptors)
const handledErrorStatusCodes = [
    HttpStatus.BAD_REQUEST,
    HttpStatus.NOT_FOUND,
    HttpStatus.INTERNAL_SERVER_ERROR,
    HttpStatus.BAD_GATEWAY,
    HttpStatus.UNAUTHORIZED
];

// theFileYouDeclaredTheCustomConfigIn.ts
declare module 'axios' {
    export interface AxiosRequestConfig {
      messagesId?: string;
    }
  }

export class HttpService {
    private outstandingRequests = 0;
    private failedRequestCount = 0;

    private responseInterceptors: Array<
        (value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>
        > = [redirectInterceptor, validationErrorsInterceptor, oAuthResponseInterceptor];

    private requestInterceptors: Array<
        (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>
        > = [oAuthRequestInterceptor, siteInterceptor];

    private pageCancelTokenSrc: CancelTokenSource | null = null;

    private get defaultGetHeaders() : any {
        return ({
            'Content-Type': 'application/json', // must use this casing for the authentication service to work
            'x-eet-culture': siteService.getCulture()
        });
    }

    constructor() {
        this.registerInterceptors();

        axios.defaults.validateStatus = status => (status >= 200 && status < 300) || handledErrorStatusCodes.includes(status);
        axios.defaults.paramsSerializer = params => {
            const sortedParams = this.getParams(params);
            return querystring.stringify(sortedParams, { arrayFormat: 'repeat' });
        };
        bus.on(PageRenderedEventKey, () => this.resetFailedRequestCount());
    }

    private cancellableRequestDictionary: { [key: string]: CancelTokenSource | null } = {};

    private requestSucceded(res: AxiosResponse, contextKey?: string) {
        if (contextKey) {
            const cancellableContextKey = `${res.config.url}-${contextKey}`;
            this.cancellableRequestDictionary[cancellableContextKey] = null;
            delete this.cancellableRequestDictionary[cancellableContextKey];
        }
        return res;
    }

    private ensureCancellationToken(url: string, contextKey: string) {
        const cancellableContextKey = `${url}-${contextKey}`;
        let cancelTokenSrc = this.cancellableRequestDictionary[cancellableContextKey];
        if (cancelTokenSrc) {
            cancelTokenSrc.cancel();
        }

        cancelTokenSrc = axios.CancelToken.source();
        this.cancellableRequestDictionary[cancellableContextKey] = cancelTokenSrc;

        return cancelTokenSrc;
    }

    /** Returns true if cancelled, false if no oustanding request found. */
    public cancelRequest(cancelKey: string): boolean {
        for (const key in this.cancellableRequestDictionary) {
            if (key.endsWith(cancelKey)) {
                const cancelTokenSrc = this.cancellableRequestDictionary[key];
                if (cancelTokenSrc) {
                    cancelTokenSrc.cancel();
                    this.cancellableRequestDictionary[key] = null;
                    delete this.cancellableRequestDictionary[key];
                    return true;
                }
            }
        }

        return false;
    }

    private handlePotentialErrorResponse<T>(res: AxiosResponse<T>): T {
        if (handledErrorStatusCodes.includes(res.status)) {
            // Validatestatus above is set to include these ones so it will trigger 'then'.
            // Reason is that interceptors will then be run automatically on this as well.
            if ([HttpStatus.BAD_GATEWAY, HttpStatus.INTERNAL_SERVER_ERROR].includes(res.status)) {
                errorCodeService.handleErrorCodes(res.status, res.config.url);
            }
            throw res;
        }

        return res.data;
    }

    private unwrapResponse(data: any) {
        return data.model;
    }

    private handleErrorResponse(error: any): void {
        if (error) {
            if (axios.isCancel(error)) {
                throw new HttpCancelError();
            }
            if (error instanceof RedirectError) {
                return;
            }
            this.incrementFailedRequestCount();
        }

        throw error;
    }

    private registerInterceptors() {
        this.responseInterceptors.forEach(i => axios.interceptors.response.use(i));
        this.requestInterceptors.forEach(i => axios.interceptors.request.use(i));
    }

    private getParams(params: any): any {
        const keys: string[] = [];

        forEach(params, (value, key: string) => {
            keys.push(key);
        });

        const sortedParams = {};

        keys.forEach(value => {
            const searchValue = params[value];
            const sortedValues = isArray(searchValue) ? searchValue.sort() : searchValue;
            sortedParams[value] = sortedValues;
        });

        return sortedParams;
    }

    private resetFailedRequestCount() {
        this.failedRequestCount = 0;
    }

    private incrementFailedRequestCount() {
        this.failedRequestCount++;
        // Only emit once, therefore ===
        if (this.failedRequestCount % potentialMaintenanceMaxFailedRequestLimit === 0) {
            bus.emit(potentialMaintenanceMaxFailedRequestLimitReachedKey);
        }
    }

    private decrementOutstandingRequests(url: string) {
        this.changeOutstandingRequests(url, this.outstandingRequests - 1);
    }

    private incrementOutstandingRequests(url: string) {
        this.changeOutstandingRequests(url, this.outstandingRequests + 1);
    }

    private nonCountedOutstandingRequestPatterns: RegExp[] = [
        /forwardAdnuntiusPixelRequest/i,
        /\/price\//i,
        /\/stock\//i
    ];

    private changeOutstandingRequests(url: string, count: number) {
        if (this.nonCountedOutstandingRequestPatterns.some(p => p.test(url))) {
            return;
        }
        if (this.outstandingRequests === 0 || count === 0) {
            bus.emit(HasOutstandingXhrCallsEventKey, count > 0);
        }
        this.outstandingRequests = count;
    }

    public getContentDispositionFilename(response: AxiosResponse) {
        const contentDisposition = response.headers['content-disposition'];
        const match = contentDisposition.match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/i);
        const fileName = match ? match[1] : '';
        return fileName;
    }

    public async get<T>(url: string, params?: any, cancellationContextKey?: string, config?: AxiosRequestConfig, messagesId?: string): Promise<T> {
        this.incrementOutstandingRequests(url);

        config = config || {};
        config = {
            ...config,
            ...{
                messagesId,
                headers: this.defaultGetHeaders,
                data: {}, // Axios kills application/json if no data object is provided https://github.com/axios/axios/issues/86#issuecomment-139638284
                params
            }
        };

        const cancelTokenSrc = cancellationContextKey ? this.ensureCancellationToken(url, cancellationContextKey) : undefined;

        return axios
            .get(url, { ...config, cancelToken: cancelTokenSrc?.token })
            .then(res => this.requestSucceded(res, cancellationContextKey))
            .then(res => this.handlePotentialErrorResponse(res))
            .then(res => this.unwrapResponse(res))
            .catch(err => this.handleErrorResponse(err))
            .finally(() => this.decrementOutstandingRequests(url));
    }

    public async post<T>(url: string, payload?: any, messagesId?: string, cancellationContextKey?: string, config?: AxiosRequestConfig, rawResult: boolean = false): Promise<T> {
        this.incrementOutstandingRequests(url);
        const headers = this.defaultGetHeaders;
        // ReCAPTCHA headers are set by custom config headers
        if (config && config.headers) {
            Object.assign(headers, config.headers);
        }
        config = {
            ...config,
            ...{
                messagesId,
                headers
            }
        };

        const cancelTokenSrc = cancellationContextKey ? this.ensureCancellationToken(url, cancellationContextKey) : undefined;

        return axios
            .post(url, payload, { ...config, cancelToken: cancelTokenSrc?.token })
            .then(res => this.requestSucceded(res, cancellationContextKey))
            .then(res => rawResult ? res : this.handlePotentialErrorResponse(res))
            .then(res => rawResult ? res : this.unwrapResponse(res))
            .catch(err => this.handleErrorResponse(err))
            .finally(() => this.decrementOutstandingRequests(url));
    }

    public async delete<T>(url: string, params?: any, config?: AxiosRequestConfig, messagesId?: string): Promise<T> {
        this.incrementOutstandingRequests(url);

        config = config || {};
        const data = { ...config.data || { } };
        config = {
            ...config,
            ...{
                headers: this.defaultGetHeaders,
                messagesId,
                data: data, // Axios kills application/json if no data object is provided https://github.com/axios/axios/issues/86#issuecomment-139638284
                params
            }
        };

        return axios
            .delete(url, config)
            .then(res => this.handlePotentialErrorResponse(res))
            .then(res => this.unwrapResponse(res))
            .catch(err => this.handleErrorResponse(err))
            .finally(() => this.decrementOutstandingRequests(url));
    }

    public async getPage(url: string): Promise<PageData> {
        this.incrementOutstandingRequests(url);
        try {
            if (this.pageCancelTokenSrc) {
                this.pageCancelTokenSrc.cancel();
            }
            this.pageCancelTokenSrc = axios.CancelToken.source();
            try {
                const res = await axios.get<PageData>(url, {
                    headers: this.defaultGetHeaders,
                    data: {}, // Axios kills application/json if no data object is provided https://github.com/axios/axios/issues/86#issuecomment-139638284
                    cancelToken: this.pageCancelTokenSrc.token
                });
                const response = this.handlePotentialErrorResponse(res);
                this.pageCancelTokenSrc = null;
                return response;
            } catch (error) {
                if (axios.isCancel(error)) {
                    throw new HttpCancelError();
                }
                throw error;
            }
        } finally {
            this.decrementOutstandingRequests(url);
        }
    }

    public async getPageParallel(url: string): Promise<PageData> {
        this.incrementOutstandingRequests(url);
        try {
            const res = await axios.get<PageData>(url, {
                headers: this.defaultGetHeaders,
                data: {} // Axios kills application/json if no data object is provided https://github.com/axios/axios/issues/86#issuecomment-139638284
            });
            const response = this.handlePotentialErrorResponse(res);
            this.pageCancelTokenSrc = null;
            return response;
        } finally {
            this.decrementOutstandingRequests(url);
        }
    }

    public async getRaw<T>(url: string): Promise<T> {
        this.incrementOutstandingRequests(url);
        try {
            const res = await axios.get<T>(url, {
                headers: this.defaultGetHeaders,
                data: {} // Axios kills application/json if no data object is provided https://github.com/axios/axios/issues/86#issuecomment-139638284
            });
            const response = this.handlePotentialErrorResponse(res);
            this.pageCancelTokenSrc = null;
            return response as T;
        } finally {
            this.decrementOutstandingRequests(url);
        }
    }
}

export default new HttpService();
