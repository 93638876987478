











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SmartParagraph extends Vue {
    @Prop({ required: true, type: String }) content!: string;
    @Prop({ type: Boolean, default: false }) asHtml!: string;
    @Prop({ default: 100, type: Number }) maxCharacters!: number;
    @Prop({ type: String, default: '...' }) overflowIndicator!: string;

    get absoluteContent() {
        const content = this.content.substring(0, this.maxCharacters);
        return this.contentOverflowing ? content + this.overflowIndicator : content;
    }

    get contentOverflowing() {
        return this.content.length > this.maxCharacters;
    }
}
