import Api from '@/project/http/Api.service';
import AbstractBulkFetch from '@/core/bulk-fetch/AbstractBulkFetch';
import { ProductBidViewObject } from '@/types/apiServerContract';
import { keyBy } from 'lodash';

class ProductBidsFetchService extends AbstractBulkFetch<ProductBidViewObject> {
    async apiCall(itemIds: string[], cancelKey: string): Promise<{ [key: string]: ProductBidViewObject }> {
        const result = await Api.product.bids(itemIds, cancelKey);
        return keyBy<ProductBidViewObject>(result.bids, 'productWid');
    }
}

export default new ProductBidsFetchService('Product.Bids', 100, true);
