























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import authService from '@/core/auth/auth.service';
import SearchCtrl from '@/project/search/SearchCtrl.vue';
import MiniBasket from '@/project/basket/MiniBasket.vue';
import { PageName } from '@/router/routes/constants';
import serverContextService from '@/core/serverContext.service';
import loginFormOverlayService from '@/project/modals/overlays/LoginFormOverlay.service';
import themeService from '@/project/app/themes/theme.service';
import { hexToRgbValues } from '@/core/color/color.service';
import { isDevMode } from '@/core/environmentService';
import RouterLinkConditional from '@/core/dom/router-link-conditional.vue';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
import { ResourceMenuViewModel } from '@/types/contentServerContract';
const TopHeaderResourceMenu = () => import('@/project/layout/page-header/desktop/TopHeaderResourceMenu.vue');

@Component({
    components: { TopHeaderResourceMenu, RouterLinkConditional, ResponsiveIcon, MiniBasket, SearchCtrl }
})
export default class TopHeader extends Vue {
    searchTerm = '';
    searchOverlayAnchorElement: Element | null = null;
    showResourcesMenu: boolean = false;

    mounted() {
        this.searchOverlayAnchorElement = this.$el;
    }

    login() {
        loginFormOverlayService.show('login');
    }

    closeResourcesMenu() {
        this.showResourcesMenu = false;
    }

    get resourceMenu(): ResourceMenuViewModel | null {
        return serverContextService.resourceMenu;
    }

    get hasResourceMenu(): boolean {
        return !!((this.resourceMenu?.resourceMenuItemGroups?.length ?? 0) > 0);
    }

    get isLoggedIn(): boolean {
        return authService.isLoggedIn();
    }

    get myAccountPageName() {
        return PageName.MY_ACCOUNT_PROFILE;
    }

    get favouritesPageName() {
        return PageName.MY_ACCOUNT_FAVOURITES;
    }

    get productGuidePage() {
        return serverContextService?.settings?.productGuidePage;
    }

    get becomeACustomerPage() {
        return serverContextService?.settings?.becomeACustomerPage;
    }

    get showBecomeACustomer(): boolean {
        // Only show for users who has never been logged in - and only if page exists
        return ((!this.isLoggedIn && !authService.hasLoggedInBefore()) && !!this.becomeACustomerPage);
    }

    get salesAndSupportPage() {
        return serverContextService?.settings?.salesAndSupportPage;
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }

    isDevMode(): boolean {
        return isDevMode();
    }

    logout() {
        authService.logOff();
    }

    get topHeaderStyle(): Partial<CSSStyleDeclaration> {
        const rgb = themeService.theme?.topColor && hexToRgbValues(themeService.theme.topColor);

        return {
            ...(rgb && { backgroundColor: `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, ${themeService.takeOverOpacity})` }),
            ...(themeService.theme?.topColor && { borderColor: themeService.theme.topColor })
        };
    }

    get isInWhiteTheme() {
        return themeService.isInWhiteTheme;
    }

    get logoAltText():string {
        return serverContextService.footer?.companyDetails?.companyName || '';
    }

    get frontpageUrl():string {
        return serverContextService.siteInfo?.url || '/';
    }

    handleClickToggleResourceMenu() {
        this.showResourcesMenu = !this.showResourcesMenu;
    }
}
