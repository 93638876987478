
import { Vue, Component } from 'vue-property-decorator';
import myAccountStore from './my-account.store';
import authService from '@/core/auth/auth.service';

@Component
export default class MyAccountModule extends Vue {
    async inititalizeAccountStore() {
        if (authService.isLoggedIn()) {
            await myAccountStore.init();
        }
    }

    async setup() {
        this.inititalizeAccountStore();
    }

    render() {
        return undefined;
    }

    created() {
        this.setup();
    }
}
