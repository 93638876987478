import logging from '@/core/logging.service';

export type ClientComponentType = object | (() => object);

export interface ClientComponentOptions {
    updateOnQueryChange?: boolean
}

export interface ClientComponent {
    component: ClientComponentType,
    options?: ClientComponentOptions
}

class ComponentResolver {
    components = new Map<string, ClientComponent>();
    fallback: ClientComponent | undefined;

    constructor(private context: string, private enforceFallback: boolean = true) {
    }

    register<T extends string>(alias: T, component: ClientComponentType, options?: Partial<ClientComponentOptions>) {
        if (this.components.get(alias)) {
            throw new Error(`${this.context}: ${alias} already registered`);
        }
        this.components.set(alias, { component: component, options: options });
    }

    registerFallback(component: ClientComponentType) {
        this.fallback = { component: component };
    }

    resolve(alias: string): ClientComponent | null {
        const component = this.components.get(alias);
        if (!component) {
            if (this.fallback) {
                logging.warn(`${this.context}: ${alias} not registered, using fallback`);
                return this.fallback;
            } else if (this.enforceFallback) {
                const msg = `${this.context}: ${alias} not registered and no default component`;
                logging.warn(msg);
                throw new Error(msg);
            } else {
                return null;
            }
        }
        return component;
    }
}

export const blockResolver = new ComponentResolver('block');
export const pageResolver = new ComponentResolver('page', false);
export const modalsResolver = new ComponentResolver('modal');
