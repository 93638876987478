

















/* eslint-disable */
import {Component, Vue} from 'vue-property-decorator';
import Temporary from '@/core/ui-components/Temporary.vue';

@Component({
    components: {
        Temporary
    }
})
export default class CopyText extends Vue {
    copied: boolean = false;

    mounted() {
        const textToCopy = this.$el.querySelector('.copy-text');

        if (textToCopy) {
            this.$el.addEventListener('click', event => {

                event.preventDefault();

                let selection = window.getSelection();
                let range = document.createRange();

                range.selectNodeContents(textToCopy);

                // Remove all selections before adding the new range
                selection?.removeAllRanges();

                // Add selection range
                selection?.addRange(range);

                // Trigger copy
                document.execCommand('copy');

                // Remove all selections after
                selection?.removeAllRanges();
                this.copied = true;
            });
        }
    }

    done() {
        this.copied = false;
    }
}
