import { includes, isArray, isString, trim, map, find, throttle } from 'lodash';
import viewportEvents from '@/core/responsive/viewport/viewportEvents.service';

type BreakpointDef = { name: string; min: number; max: number };
type BreakpointsDef = BreakpointDef[];

export default {
    isActiveBreakpoint,
    getActiveBreakpoint,
    getBreakpoints,
    getBreakpoint,
    addListener,
    removeListener,
    getExpandedBreakpoints
};

let breakpoints: BreakpointsDef;

function getBreakpoints(): BreakpointsDef {
    return breakpoints;
}

function getBreakpoint(name: string): BreakpointDef {
    const bp = find(breakpoints, { name });
    if (!bp) {
        throw new Error(`${name} is not a known breakpoint`);
    }
    return bp;
}

/*
  Given breakpoints: 'xs', 'sm', 'md', 'lg', 'xl'
  Expands

    'xs,sm,lg' => ['xs','sm','lg']
    'min-md' => ['md','lg','xl']
    'min-sm,max-lg' => ['sm','md','lg']

    breakpoints can also be excluded with the 'not-' prefix: example: [max-xl, not-md] for all breakpoints besides md

 NB: Only expects to find at most 1 breakpoint with min- prefix and at most 1 with max- prefix, so only 1 range!
 NB: Might contain duplicates.
 */
function getExpandedBreakpoints(breakpoints: string): string[] {
    const bpsAsArray = breakpoints.split(',').map(l => l.trim());
    let finalSizes: string[] = [...bpsAsArray].filter(bps => !bps.startsWith('min-') && !bps.startsWith('max-') && !bps.startsWith('not-'));
    const breakpointKeys = getBreakpoints().map(bp => bp.name);

    while (hasExcludedPrefixes(bpsAsArray)) {
        const excluded = bpsAsArray.find(s => s.startsWith('not'));
        if (!excluded) break;

        let excludedIdx = breakpointKeys.indexOf(stripPrefix(excluded));
        if (excludedIdx >= 0) { breakpointKeys.splice(excludedIdx, 1); }

        excludedIdx = bpsAsArray.indexOf(excluded);
        if (excludedIdx >= 0) { bpsAsArray.splice(excludedIdx, 1); }
    }

    if (hasMinMaxPrefixes(bpsAsArray)) {
        const minBp = bpsAsArray.find(s => s.startsWith('min-'));
        const minBpIx = minBp ? breakpointKeys.indexOf(stripPrefix(minBp)) : 0;
        const maxBp = bpsAsArray.find(s => s.startsWith('max-'));
        const maxBpIx = maxBp ? breakpointKeys.indexOf(stripPrefix(maxBp)) + 1 : breakpointKeys.length;
        const range = breakpointKeys.slice(minBpIx, maxBpIx);

        finalSizes = [...finalSizes, ...range];
    }

    return finalSizes;

    function hasMinMaxPrefixes(keys: string[]): boolean {
        return keys.some(k => k.startsWith('min-') || keys.some(k => k.startsWith('max-')));
    }

    function hasExcludedPrefixes(keys: string[]): boolean {
        return keys.some(k => k.startsWith('not-'));
    }

    function stripPrefix(size: string): string {
        return size.substr(size.indexOf('-') + 1);
    }
}

export function defineBreakpoints(bps: { [bp: string]: { min: number; max: number } }) {
    breakpoints = map(bps, (range, bp) => {
        return { name: bp, min: range.min, max: range.max };
    }).sort((a, b) => a.min - b.min);
}

function normalizeBreakpoints(bps: string) {
    const normalizedBps = isString(bps) ? bps.split(',') : isArray(bps) ? bps : [];
    return map(normalizedBps, trim);
}

function isActiveBreakpoint(bps: string) {
    const arrayOfBreakpointNames = normalizeBreakpoints(bps);
    const activeBreakpoint = getActiveBreakpoint();
    return includes(arrayOfBreakpointNames, activeBreakpoint);
}

function getActiveBreakpoint(): string {
    const width = Math.max(window.innerWidth, window.document.documentElement!.clientWidth);

    const activeBreakpoint = breakpoints.find(bp => {
        return bp.min <= width && bp.max >= width;
    })!.name;

    return activeBreakpoint;
}

let listeners: Array<() => void> = [];

function addListener(listener: () => void) {
    listeners.push(listener);
    listener();
}

function removeListener(listener: Function) {
    listeners = listeners.filter(l => l !== listener);
}

class Worker {
    private throttleTimer = 500;
    private winWidth = 0;
    private checkActiveBreakpointThrottled: any;

    constructor(private callback: any) {
        this.checkActiveBreakpointThrottled = throttle(this.handleViewportEvents.bind(this), this.throttleTimer, {
            trailing: true
        });

        viewportEvents.setCallback(this.checkActiveBreakpointThrottled);
    }

    public destroy() {
        viewportEvents.removeCallback(this.checkActiveBreakpointThrottled);
    }

    public handleViewportEvents(viewport: any) {
        const currentWinWidth = viewport.width;
        if (currentWinWidth === this.winWidth) {
            return;
        }
        this.winWidth = currentWinWidth;
        this.callback();
    }
}

// eslint-disable-next-line no-new
new Worker(() => {
    for (const l of listeners) {
        l();
    }
});
