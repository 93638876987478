import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    RecommendationsRequest,
    ProductTileViewModel,
    RecommendationsCategoriesRequest,
    RecommendedCategoriesViewModel,
    RecommendationsBrandsRequest,
    RecommendedBrandsViewModel,
    RecommendationsBySearchTermRequest
} from '@/types/apiServerContract';

export class RecommendationsController {
    private readonly urlFactory = createUrlFactory('recommendations');

    public async recommend(request: RecommendationsRequest): Promise<ProductTileViewModel> {
        return httpService.post(this.urlFactory('recommend'), request);
    }

    public async recommendByCategory(request: RecommendationsCategoriesRequest): Promise<RecommendedCategoriesViewModel> {
        return httpService.post(this.urlFactory('recommendpopularcategories'), request);
    }

    public async recommendPopularBrands(request: RecommendationsBrandsRequest): Promise<RecommendedBrandsViewModel> {
        return httpService.post(this.urlFactory('recommendpopularbrands'), request);
    }

    public async recommendSearchTerm(request: RecommendationsBySearchTermRequest): Promise<ProductTileViewModel> {
        return httpService.post(this.urlFactory('recommendbysearchterm'), request);
    }
}
