






















import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import AdnuntiusPixels from '@/project/tracking/adnuntius/AdnuntiusPixels.vue';
import { BrandLogoViewModel } from '@/types/apiServerContract';
import { PageName } from '@/router/routes/constants';
import themeService from '@/project/app/themes/theme.service';

@Component({
    components: { AdnuntiusPixels }
})
export default class BrandLogo extends Vue {
    @Prop({
        type: Object
    }) content!: BrandLogoViewModel;

    get imageCssClass() : string {
        const isTall = this.content.image.width < this.content.image.height;
        const isSquare = this.content.image.width === this.content.image.height;

        return isTall || isSquare ? 'c-brandlogo-image--max-h' : 'c-brandlogo-image--max-w';
    }

    get brandPageName() {
        return PageName.BRAND;
    }

    get brandThemeStyle(): Partial<CSSStyleDeclaration> {
        const isInWhiteTheme = themeService.isInWhiteTheme;

        return {
            ...(isInWhiteTheme && { filter: 'brightness(0) invert(1)' })
        };
    }
}
