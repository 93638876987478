import Vue from 'vue';
import { Dictionary, RawLocation } from 'vue-router/types/router';
import { Route } from 'vue-router';
import { isEqual } from 'lodash';
import { router } from 'spaRouter';
import urlStringHelperService from '@/core/urlStringHelper.service';
import { UrlQueryKey, UrlFacets, WID_REGEX_LOCATOR } from '@/core/urlQueryKey';

const nonFacetKeys = [
    UrlQueryKey.termKey,
    UrlQueryKey.searchWithinResultKey,
    UrlQueryKey.categoryKey,
    UrlQueryKey.categoryIdKey,
    UrlQueryKey.categoryIdsKey,
    UrlQueryKey.campaignKey,
    UrlQueryKey.sortByKey,
    UrlQueryKey.pageKey,
    UrlQueryKey.lastFacetKey,
    UrlQueryKey.showScoreKey,
    UrlQueryKey.showExplanationKey,
    UrlQueryKey.showRawKey,
    UrlQueryKey.labelsdebug,
    UrlQueryKey.productGuideGuideId,
    UrlQueryKey.productGuideProductPageSize,
    UrlQueryKey.productGuideModeKey,
    UrlQueryKey.productGuideBrandKey,
    UrlQueryKey.productGuideSeriesKey,
    UrlQueryKey.productGuideModelKey,
    UrlQueryKey.productGuideSearchKey,
    UrlQueryKey.cableGuideCableTypeKey,
    UrlQueryKey.cableGuideCableEndAKey,
    UrlQueryKey.cableGuideCableEndBKey,
    UrlQueryKey.productGuideConfigurationStep,
    UrlQueryKey.productGuideExternalButtonLink,
    UrlQueryKey.productGuideExternalButtonText,
    UrlQueryKey.productGuideKey,
    UrlQueryKey.productGuideId,
    UrlQueryKey.productGuideBrandName,
    UrlQueryKey.origin,
    UrlQueryKey.theme,
    UrlQueryKey.css,
    UrlQueryKey.language,
    UrlQueryKey.hideStock,
    UrlQueryKey.hideFacets,
    UrlQueryKey.wid,
    UrlQueryKey.utm_content,
    UrlQueryKey.utm_campaign,
    UrlQueryKey.utm_source,
    UrlQueryKey.utm_medium,
    UrlQueryKey.utm_id,
    UrlQueryKey.utm_source_platform,
    UrlQueryKey.utm_campaign_id,
    UrlQueryKey.utm_term,
    UrlQueryKey.utm_creative_format,
    UrlQueryKey.utm_marketing_tactic,
    UrlQueryKey.fbclid,
    UrlQueryKey.gclid,
    UrlQueryKey.rckh,
    UrlQueryKey.RackType
];

const observableFacets: {
    facets: UrlFacets
} = Vue.observable({
    facets: { }
});

export default {
    updateFacetValue,
    setFacetValues,
    clearFacets,
    clearNonFacets,
    getFacets,
    getLastFacet,
    isSearching,
    getPage,
    setPage,
    getTerm,
    setTerm,
    getSearchWithinResult,
    setSearchWithinResult,
    setCategory,
    getCategory,
    getCategoryId,
    setCategoryId,
    getCategoryIds,
    setCategoryIds,
    getSearchFilterId,
    setSearchFilterId,
    getSearchPriceSortFilter,
    setSearchPriceSortFilter,
    getSortBy,
    setSortBy,
    getLocationForPage,
    getLocationForTerm,
    getCampaignCode,
    setCampaignCode,
    getShowScore,
    setShowScore,
    getShowExplanation,
    getShowRaw,
    setHash,
    getHash,
    getWidsFromTerm,
    clearHash,
    cleanupUrl,
    setProductGuideMode,
    getProductGuideMode,
    setProductGuideParams,
    setProductGuideSearchTerm,
    getProductGuideSearchTerm,
    setProductGuideId,
    getProductGuideBrand,
    getProductGuideSeries,
    getProductGuideModel,
    getProductSearchTerm,
    getCableGuideCableType,
    getCableGuideCableEndA,
    getCableGuideCableEndB,
    getProductGuideId,
    setCableGuideParams,
    getQueryParam,
    removeQueryParams,
    setFacetRanges,
    getBids,
    getBidsBrand,
    getBidsWebCategories
};

// Resets observable facets for every route-change
router.afterEach(updateObservableFacets);

function updateFacetValue(facetId: string, key: string, selected: boolean, updateLastFacet: boolean) {
    let facetValues = router.currentRoute.query[facetId];

    // Convert to array for all cases
    if (!facetValues) {
        facetValues = [];
    } else if (!(facetValues instanceof Array)) {
        facetValues = [facetValues];
    } else {
        // Create new array or changes will not be detected
        facetValues = [...facetValues];
    }

    // Add or remove
    if (selected) {
        facetValues.push(key);
    } else {
        facetValues = facetValues.filter(v => v !== key);
    }
    setFacetValues(facetId, facetValues as string[], updateLastFacet);
}

function setFacetValues(facetId: string, facetValues: string[], updateLastFacet: boolean) {
    // Cleans all facets for this group and set the new ones
    const query = { ...router.currentRoute.query };
    delete query[facetId];

    if (facetValues.length > 0) {
        query[facetId] = facetValues;
    }

    if (updateLastFacet) {
        query[UrlQueryKey.lastFacetKey] = facetId;
    }
    updateQuery(query, [UrlQueryKey.pageKey], true);
}

function setFacetRanges({ facetId, rangeFrom, rangeTo, updateLastFacet }: { facetId: string, rangeFrom: string, rangeTo: string, updateLastFacet: boolean }) {
    // Cleans all facets for this group and set the new ones
    const query = { ...router.currentRoute.query };

    const facetFrom = 'from_' + facetId;
    const facetTo = 'to_' + facetId;

    delete query[facetId];
    delete query[facetFrom];
    delete query[facetTo];

    if (rangeFrom && rangeTo) {
        query[facetFrom] = rangeFrom;
        query[facetTo] = rangeTo;
    }

    if (updateLastFacet) {
        query[UrlQueryKey.lastFacetKey] = facetId;
    }

    updateQuery(query, [UrlQueryKey.pageKey], true);
}

function clearFacets() {
    // If query is empty, do not do anything
    if (Object.keys(router.currentRoute.query).length === 0) {
        return;
    }

    // Remove all keys related to facets
    const query = { ...router.currentRoute.query };
    Object
        .keys(query)
        .filter(key => !nonFacetKeys.includes(key as UrlQueryKey))
        .forEach(key => {
            delete query[key];
        });

    delete query[UrlQueryKey.lastFacetKey];
    delete query[UrlQueryKey.searchWithinResultKey];
    updateQuery(query, [UrlQueryKey.pageKey]);
}

function clearNonFacets() {
    // If query is empty, dont do anything
    if (Object.keys(router.currentRoute.query).length === 0) {
        return;
    }

    // Remove all keys not related to facets
    const query = { ...router.currentRoute.query };
    Object
        .keys(query)
        .filter(key => nonFacetKeys.includes(key as UrlQueryKey))
        .forEach(key => {
            delete query[key];
        });

    delete query[UrlQueryKey.lastFacetKey];
    updateQuery(query, [UrlQueryKey.pageKey]);
}

function toUrlFacets(query: Dictionary<string | string[]>): UrlFacets {
    // Ensure all values are arrays
    const result: UrlFacets = {};
    Object
        .keys(query)
        .forEach(key => {
            result[key] = (query[key] instanceof Array ? query[key] : [query[key]]) as string[];
        });
    return result;
}

function getFacets(): UrlFacets {
    return observableFacets.facets;
}

function getLastFacet(): string | undefined {
    return getQueryParam(UrlQueryKey.lastFacetKey);
}

function updateObservableFacets() {
    // Clean
    Vue.set(observableFacets, 'facets', { });

    // Refill
    const query = toUrlFacets({ ...router.currentRoute.query } as Dictionary<string | string[]>);
    Object
        .keys(query)
        .filter(key => !nonFacetKeys.includes(key as UrlQueryKey))
        .forEach(facetGroup => {
            Vue.set(observableFacets.facets, facetGroup, query[facetGroup]);
        });
}

function isSearching(route?: Route) {
    return !!route?.query?.term;
}

function cleanupUrl(facetKeys: { key: string, activeValueKeys: string[], from?: number, to?: number }[]) {
    const query = { ...router.currentRoute.query };

    const keys = facetKeys.map(x => x.key);

    // Make sure query part only contains well known non-facet keys and current facets
    const foreignKeys = Object
        .keys(router.currentRoute.query)
        .filter(key => !nonFacetKeys.includes(key as UrlQueryKey) && !(keys && keys.includes(key)));

    if (foreignKeys.length) {
        foreignKeys
            .forEach(key => {
                delete query[key];
            });
    }

    // Remove any facet value that is no longer active
    facetKeys.forEach(x => {
        const facetFrom = 'from_' + x.key;
        const facetTo = 'to_' + x.key;

        delete query[x.key];
        delete query[facetFrom];
        delete query[facetTo];

        if (x.from && x.to) {
            query[facetFrom] = x.from.toString();
            query[facetTo] = x.to.toString();
        } else if (x.activeValueKeys.length > 0) {
            query[x.key] = x.activeValueKeys;
        }
    });

    updateQuery(query);
}

function getTerm(): string | undefined {
    return getQueryParam(UrlQueryKey.termKey);
}

function getLocationForTerm(term: string): RawLocation {
    const query = { ...router.currentRoute.query };
    query[UrlQueryKey.termKey] = term;

    return ({ query });
}
function getSearchWithinResult(): string | undefined {
    return getQueryParam(UrlQueryKey.searchWithinResultKey);
}

function getLocationForPage(page: number): RawLocation {
    const query = { ...router.currentRoute.query };
    if (page > 1) {
        query[UrlQueryKey.pageKey] = page + '';
    } else {
        // Avoid ?page=1 as it is the same as no page query
        delete query[UrlQueryKey.pageKey];
    }
    return ({ query });
}

function getPage(): number {
    const value = parseInt(getQueryParam(UrlQueryKey.pageKey) || '', 10);
    return !value || Number.isNaN(value) ? 1 : value;
}

function getCategory(): string | undefined {
    return getQueryParam(UrlQueryKey.categoryKey);
}

function getCategoryId(): string | undefined {
    return getQueryParam(UrlQueryKey.categoryIdKey);
}

function getCategoryIds(): string[] {
    const categoryIds = router.currentRoute.query[UrlQueryKey.categoryIdsKey] as string[];
    return categoryIds instanceof Array ? categoryIds : categoryIds ? [categoryIds] : [];
}

function getSearchFilterId(): number | undefined {
    const value = parseInt(getQueryParam(UrlQueryKey.searchFilterKey) || '', 10);
    return !value || Number.isNaN(value) ? undefined : value;
}

function setSearchFilterId(value: number) {
    setQueryParam(UrlQueryKey.searchFilterKey, value + '');
}

function getSearchPriceSortFilter(): number | undefined {
    const value = parseInt(getQueryParam(UrlQueryKey.searchPriceSortKey) || '', 10);
    return !value || Number.isNaN(value) ? undefined : value;
}

function setSearchPriceSortFilter(value: number) {
    setQueryParam(UrlQueryKey.searchPriceSortKey, value + '');
}

function getCampaignCode(): string | undefined {
    return getQueryParam(UrlQueryKey.campaignKey);
}

function setCampaignCode(campaignCode: string): void {
    setQueryParam(UrlQueryKey.campaignKey, campaignCode);
}

function getSortBy(): string {
    return getQueryParam(UrlQueryKey.sortByKey) as string || '';
}

function setPage(page: number) {
    setQueryParam(UrlQueryKey.pageKey, page + '', [], true);
}

function setTerm(term: string, push: boolean = false): void {
    setQueryParam(UrlQueryKey.termKey, urlStringHelperService.removeNonPrintableChars(term), [UrlQueryKey.pageKey, UrlQueryKey.categoryIdKey], push);
}
function setSearchWithinResult(term: string, push: boolean = false): void {
    setQueryParam(UrlQueryKey.searchWithinResultKey, urlStringHelperService.removeNonPrintableChars(term), [UrlQueryKey.pageKey, UrlQueryKey.categoryIdKey], push);
}

function setCategory(categoryId: string): void {
    setQueryParam(UrlQueryKey.categoryKey, categoryId, [UrlQueryKey.pageKey]);
}

function setCategoryId(categoryId: string): void {
    setQueryParam(UrlQueryKey.categoryIdKey, categoryId, [UrlQueryKey.pageKey]);
}

function setCategoryIds(categoryIds: string[], options: { selected: boolean, clearOthers?: boolean, pushToHistory?: boolean, clearPageQuery?: boolean }): void {
    const { selected, clearOthers, pushToHistory, clearPageQuery } = options;
    if (options.pushToHistory === undefined) {
        options.pushToHistory = true;
    }
    if (options.clearPageQuery === undefined) {
        options.clearPageQuery = true;
    }
    const query = JSON.parse(JSON.stringify(router.currentRoute.query));
    let activeCategoryIds = query[UrlQueryKey.categoryIdsKey] as string[];

    if (!activeCategoryIds || !(activeCategoryIds instanceof Array) || clearOthers) {
        activeCategoryIds = [];
    }

    if (selected) {
        activeCategoryIds.push(...categoryIds);
    } else {
        activeCategoryIds = activeCategoryIds.filter(x => !categoryIds.includes(x));
    }

    const uniques = [...new Set<string>(activeCategoryIds)];

    if (uniques.length === 0) {
        delete query[UrlQueryKey.categoryIdsKey];
    } else {
        query[UrlQueryKey.categoryIdsKey] = uniques;
    }
    const keysToClear = clearPageQuery ? [UrlQueryKey.pageKey] : undefined;
    updateQuery(query, keysToClear, pushToHistory);
}

function setSortBy(sortBy: string): void {
    setQueryParam(UrlQueryKey.sortByKey, sortBy, [UrlQueryKey.pageKey]);
}

function setProductGuideMode(mode: 'search' | 'step-by-step', push: boolean = false): void {
    setQueryParam(UrlQueryKey.productGuideModeKey, mode.toLowerCase(), [], push);
}

function setProductGuideSearchTerm(term: string, push: boolean = false): void {
    setQueryParam(UrlQueryKey.productGuideSearchKey, term,
        [UrlQueryKey.productGuideBrandKey, UrlQueryKey.productGuideSeriesKey, UrlQueryKey.productGuideModelKey], push);
}

function getProductGuideSearchTerm() {
    return getQueryParam(UrlQueryKey.productGuideSearchKey);
}

function setProductGuideId(guideIds: string, push: boolean = false): void {
    setQueryParam(UrlQueryKey.productGuideId, guideIds,
        [UrlQueryKey.pageKey], push);
}

function setProductGuideParams(brand?: string, series?: string, model?: string): void {
    const params = {
        ...(brand && { [UrlQueryKey.productGuideBrandKey]: brand }),
        ...(brand && series && { [UrlQueryKey.productGuideSeriesKey]: series }),
        ...(brand && series && model && { [UrlQueryKey.productGuideModelKey]: model })
    } as Record<UrlQueryKey, string>;

    const missing = [
        UrlQueryKey.productGuideSearchKey,
        UrlQueryKey.productGuideBrandKey,
        UrlQueryKey.productGuideSeriesKey,
        UrlQueryKey.productGuideModelKey
    ].filter(x => !Object.keys(params).includes(x));

    setQueryParams(params, missing);
}

function getProductGuideMode(): 'search' | 'step-by-step' | undefined {
    return getQueryParam(UrlQueryKey.productGuideModeKey)?.toLowerCase() as 'search' | 'step-by-step';
}

function getProductGuideBrand() {
    return getQueryParam(UrlQueryKey.productGuideBrandKey);
}

function getProductGuideSeries() {
    return getQueryParam(UrlQueryKey.productGuideSeriesKey);
}

function getProductGuideModel() {
    return getQueryParam(UrlQueryKey.productGuideModelKey);
}

function getProductSearchTerm() {
    return getQueryParam(UrlQueryKey.productGuideSearchKey);
}

function getCableGuideCableType() {
    return getQueryParam(UrlQueryKey.cableGuideCableTypeKey);
}

function getCableGuideCableEndA() {
    return getQueryParam(UrlQueryKey.cableGuideCableEndAKey);
}

function getCableGuideCableEndB() {
    return getQueryParam(UrlQueryKey.cableGuideCableEndBKey);
}

function getProductGuideId() {
    return getQueryParam(UrlQueryKey.productGuideId);
}

function setCableGuideParams(cableType?: string, cableEndA?: string, cableEndB?: string): void {
    const params = {
        ...(cableType && { [UrlQueryKey.cableGuideCableTypeKey]: cableType }),
        ...(cableEndA && { [UrlQueryKey.cableGuideCableEndAKey]: cableEndA }),
        ...(cableEndA && cableEndB && { [UrlQueryKey.cableGuideCableEndBKey]: cableEndB })
    } as Record<UrlQueryKey, string>;

    const missing = [
        UrlQueryKey.cableGuideCableTypeKey,
        UrlQueryKey.cableGuideCableEndAKey,
        UrlQueryKey.cableGuideCableEndBKey
    ].filter(x => !Object.keys(params).includes(x));

    setQueryParams(params, missing, true);
}

function getShowScore(): boolean {
    const showScore = getQueryParam(UrlQueryKey.showScoreKey) as string || '';
    return showScore.toLowerCase() === 'true';
}

function setShowScore(enabled: boolean) {
    setQueryParam(UrlQueryKey.showScoreKey, enabled ? 'true' : '');
}

function getShowExplanation(): boolean {
    const showScore = getQueryParam(UrlQueryKey.showExplanationKey) as string || '';
    return showScore.toLowerCase() === 'true';
}

function getWidsFromTerm(): string[] {
    const term = getTerm();
    return term?.match(WID_REGEX_LOCATOR) ?? [];
}

function getBids(): string[] {
    const bids = router.currentRoute.query[UrlQueryKey.bidsKey] as string[];
    return bids instanceof Array ? bids : bids ? [bids] : [];
}

function getBidsBrand(): string[] {
    const bids = router.currentRoute.query[UrlQueryKey.bidsBrandKey] as string[];
    return bids instanceof Array ? bids : bids ? [bids] : [];
}

function getBidsWebCategories(): string[] {
    const bidsWebCategories = router.currentRoute.query[UrlQueryKey.bidsWebCategoriesKey] as string[];
    return bidsWebCategories instanceof Array ? bidsWebCategories : bidsWebCategories ? [bidsWebCategories] : [];
}

function getShowRaw(): boolean {
    const raw = getQueryParam(UrlQueryKey.showRawKey) as string || '';
    return raw.toLowerCase() === 'true';
}

function getQueryParam(param: UrlQueryKey) : string | undefined {
    return router.currentRoute.query[param] as string;
}

function setQueryParam(param: UrlQueryKey, value: string | string[], clearOthers: string[] = [], push: boolean = false) {
    const query = { ...router.currentRoute.query };
    if (!value && !query[param]) return;
    if (value === query[param]) return;
    if (value) {
        query[param] = value;
    } else {
        delete query[param];
    }
    updateQuery(query, clearOthers, push);
}

function setQueryParams(params: Record<UrlQueryKey, string>, clearOthers: string[] = [], push: boolean = false) {
    const query = { ...router.currentRoute.query };
    for (const param of Object.entries(params)) {
        const key = param[0];
        const val = param[1];

        if (!val && !query[key]) continue;
        if (val === query[key]) continue;
        if (val) {
            query[key] = val;
        } else {
            delete query[key];
        }
    }
    updateQuery(query, clearOthers, push);
}

function removeQueryParams<K extends keyof typeof UrlQueryKey>(...params: K[]) {
    const query = { ...router.currentRoute.query };

    for (const param of params) {
        delete query[UrlQueryKey[param]];
    }

    updateQuery(query);
}

function updateQuery(query: Dictionary<string | (string | null)[] | null | undefined>, clearOthers: string[] = [], push: boolean = false) {
    if (clearOthers.length) {
        clearOthers.forEach(key => delete query[key]);
    }

    // Only push a change to vue router if a change has actually happenened, fixes "duplicate navigation" router errors
    // Keep hash to make tabs on Order History page work
    if (!isEqual(query, router.currentRoute.query)) {
        if (push) {
            router.push({ query, hash: router.currentRoute.hash }).catch(() => {});
        } else {
            router.replace({ query, hash: router.currentRoute.hash }).catch(() => {});
        }
    }
}

function setHash(hash: string) {
    router.push({ hash: hash });
}

function getHash() {
    return router.currentRoute.hash.slice(1);
}

function clearHash() {
    router.push({ hash: undefined });
}
