import Vue from 'vue';

Vue.directive('whole-numbers-only', {
    bind: function(el: any) {
        el.wholeNumbersHandler = function() {
            el.value = el.value.replace(/\D+/, '');
        };
        el.addEventListener('input', el.wholeNumbersHandler);
    },
    unbind: function(el: any) {
        el.removeEventListener('input', el.wholeNumbersHandler);
    }
});
