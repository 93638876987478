import serverContext from '@/core/serverContext.service';
import { isArray, isEmpty, isNil, isString } from 'lodash';

export function createUrlFactory(controller: string): (action: string) => string {
    return (action: string) => `${serverContext.apiHost}/api/${controller}/${action}`;
}

export function shouldExclude(value: any): boolean {
    return (isString(value) && isEmpty(value)) || isNil(value) || (isArray(value) && value.length === 0);
}
