import { AbstractModalService } from '@/core/modals/AbstractModalService';
import { ModalOptions } from '@/core/modals/ModalState';

class LoginFormOverlayService extends AbstractModalService {
    public show(state: 'login' | 'forgot-pw' | 'reset-pw' | 'change-pw', redirect?: string, options?: Partial<ModalOptions>) {
        return this.showModal({
            component: () => import(
                /* webpackPrefetch: true */
                '@/project/auth/AuthenticationModuleOverlay.vue'),
            componentAttrs: {
                initialState: state,
                redirect
            },
            options: { ...options, closeOnBreakpointChange: false, disableUserClose: true }
        });
    }
}

export default new LoginFormOverlayService();
