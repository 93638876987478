
























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import basketService from '@/project/basket/basket.service';
import { PageName } from '@/router/routes/constants';
import themeService from '@/project/app/themes/theme.service';

@Component
export default class MiniBasket extends Vue {
    animate = false;

    @Prop({ type: Boolean, default: false })
    isMobile!: boolean

    get noOfBasketItems(): number {
        return basketService.noOfBasketItems();
    }

    @Watch('noOfBasketItems')
    onNoOfLineItemsChange() {
        if (basketService.basket) { this.animate = true; }
    }

    get basketPage() {
        return PageName.BASKET;
    }

    get isInWhiteTheme(): boolean {
        return themeService.isInWhiteTheme;
    }
}
