












































import { Vue, Prop, Component } from 'vue-property-decorator';
import { BidViewObject } from '@/types/apiServerContract';

@Component
export default class ProductTileBid extends Vue {
    @Prop({ required: true }) bid!: BidViewObject;
    @Prop({ required: false, default: false, type: Boolean }) isBidOnPdp!: boolean;

    get bidsEndDate() {
        return this.bid.endDate?.split(' ')[0] ?? '';
    }
}
