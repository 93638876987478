




























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class InputSelect extends Vue {
    internalValue: string | object | number | null = null;

    @Prop({ required: true })
    value!: string | object | number | null;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: String, required: false })
    selectClasses!: string;

    @Prop({ type: String, required: false })
    labelPrefix!: string;

    @Prop({ type: String, validator: value => ['blue', 'white'].includes(value as string) })
    variant!: 'blue' | 'white';

    @Prop()
    customMessages!: object;

    get id() {
        return this.name + new Date().valueOf();
    }

    created() {
        this.internalValue = this.value;
    }

    @Watch('value')
    onValueChange(value: object) {
        this.internalValue = value;
    }

    @Watch('internalValue')
    onInternalValueChange(value: any) {
        this.$emit('input', value);
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }

    get hasValue(): boolean {
        return !!this.value;
    }
}
