import Api from '@/project/http/Api.service';
import AbstractBulkFetch from '@/core/bulk-fetch/AbstractBulkFetch';
import { AdnuntiusPixelForwardRequest } from '@/types/apiServerContract';

class AdnuntiusBulkService extends AbstractBulkFetch<void> {
    async apiCall(deliveryUrls: string[]) {
        await Api.personalization.forwardAdnuntiusPixelRequest({
            deliveryUrls: deliveryUrls
        } as AdnuntiusPixelForwardRequest);

        return null;
    }
}

export default new AdnuntiusBulkService('Adnuntius', 200);
