






















import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { ClientMessage, ClientMessageType, ClientMessageWithError, GeneralErrorEventKey } from '@/core/messages/types';
import bus from '@/core/bus';
import dictionaryService from '@/core/translation/dictionary.service';

const AUTO_KILL_TIME = 10000; // ms.

@Component
export default class ErrorToaster extends Vue {
    messages: ClientMessage[] = [];

    created() {
        bus.on(GeneralErrorEventKey, this.addMessages);
    }

    getBgColor(messageType: ClientMessageType): string {
        switch (messageType) {
        case ClientMessageType.Info:
            return 'bg-green-500';
        case ClientMessageType.Warning:
            return 'bg-yellow-500';
        case ClientMessageType.Error:
        default:
            return 'bg-red-500';
        }
    }

    getMessage(msgOrLabel: string): string {
        if (dictionaryService.exists(msgOrLabel)) {
            return dictionaryService.get(msgOrLabel);
        }
        return msgOrLabel;
    }

    addMessages(clientMessages: ClientMessageWithError): void {
        clientMessages.messages.forEach(msg => {
            msg.errorId = clientMessages.errorId;
            setTimeout(() => this.clearMessage(msg), AUTO_KILL_TIME);
        });

        this.messages = this.messages.concat(clientMessages.messages);
    }

    clearMessage(msg: ClientMessage): void {
        this.messages = this.messages.filter(m => m !== msg);
    }
}
