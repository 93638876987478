import Deferred from '@/core/async/Deferred';
import UUID from '@/core/uuid.service';
import { ComponentType, ModalOptions, ModalState } from './ModalState';

const baseOptions: ModalOptions = {
    disableBodyScroll: true,
    contentAutoScroll: true,
    contentMaxHeight: true,
    showCloseButton: true,
    disableUserClose: false,
    placement: 'center',
    singleton: true,
    usePanelWrapper: true,
    wide: false,
    closeOnRouteChange: true,
    closeOnBreakpointChange: true,
    closeButtonPosition: null
};

export abstract class AbstractModalService {
    public abstract show(...aggs: any[]);

    protected async showModal<T>({ component, componentAttrs, modalListeners, options }:
        { component: ComponentType; componentAttrs: any; modalListeners?: any; options?: Partial<ModalOptions> }): Promise<T> {
        const deferred = new Deferred<T>();

        const modalOptions = this.getOptions(options);

        if (modalOptions?.singleton) {
            await ModalState.hideModals();
        }

        ModalState.setActiveModal({
            id: UUID(),
            component: component,
            componentAttrs: componentAttrs,
            modalListeners: modalListeners,
            deferred: deferred,
            state: 'entering',
            ...modalOptions
        });

        return deferred.promise;
    }

    private getOptions(options?: Partial<ModalOptions>) : ModalOptions {
        options = {
            ...baseOptions,
            ...options
        };
        return options as ModalOptions;
    }
}
