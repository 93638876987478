import GTMBasics, { DataLayerEntry } from './GTM.basics';
import {
    FacetValueViewObject,
    FacetViewObject,
    LineItemViewObject,
    MoneyViewObject,
    ReceiptViewModel,
    StockInformationViewObject,
    StockLocationTypeViewObject
} from '@/types/apiServerContract';
import { Route } from 'vue-router';
import { RouterMetaData } from '@/core/app/clientPageHandler';
import { router } from '@/router/index';
import Vue from 'vue';
import {
    generateProductGuideTrackingData,
    ProductGuideTrackingInformation
} from '@/project/app/blocks/productguide/ProductGuide.service';
import {
    CableGuideTrackingInformation,
    generateCableGuideTrackingData
} from '@/project/app/blocks/cableguide/CableGuide.service';
import authService from '@/core/auth/auth.service';

export enum ProductTrackingState {
    Complete,
    NotLoggedIn,
    ErpNotAvailable,
    NoErpResponseYet
}

export interface TrackingLineItem extends LineItemViewObject {
    stockInfo: readonly StockInformationViewObject[] | null;
    trackingState: ProductTrackingState;
}

export interface TrackingProduct {
    wid: string,
    itemId: string,
    displayName?: string,
    brand?: string,
    categoryId?: string,
    price: MoneyViewObject | undefined,
    stockInfo: readonly StockInformationViewObject[] | null,
    listName?: string
    position?: number,
    trackingState: ProductTrackingState,
    gppg?: string;
    mpn?: string;
    mpnNumber?: string;
}

interface InternalTrackingProduct {
    id: string,
    name: string,
    brand: string,
    category: string,
    price?: string,
    metric1?: string,
    dimension16?: boolean,
    dimension20?: boolean,
    dimension19?: string,
    dimension21?: string,
    dimension22?: string,
    dimension23?: string,
    quantity?: number
    list?: string,
    position?: number
}

class GTMService {
    currentPagePath: string = '';

    constructor() {
        this.setupPageViewTracking();
    }

    public trackBasketPage(lineItems: TrackingLineItem[] | null, initial = true) {
        if (!lineItems || lineItems.length === 0) return;

        const trackingObject: DataLayerEntry = initial ? {
            event: 'pageview',
            pageType: 'checkout',
            pageUrl: this.pageViewUrl
        } : {
            event: 'ecom.checkout'
        };

        const currencyCode = this.currencyCodeFromLineItems(lineItems);
        trackingObject.ecommerce = {
            currencyCode: currencyCode,
            checkout: {
                actionField: {
                    step: 1
                },
                products: lineItems.map(this.trackingLineItemToInternal, this)
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackCheckoutPage(rawLineItems: LineItemViewObject[]) {
        const lineItems = rawLineItems.map(rli => ({
            ...rli,
            stockInfo: null,
            trackingState: ProductTrackingState.Complete
        } as TrackingLineItem));

        const trackingObject: DataLayerEntry = {
            event: 'pageview',
            pageType: 'checkout',
            pageUrl: this.pageViewUrl,
            ecommerce: {
                currencyCode: this.currencyCodeFromLineItems(lineItems),
                checkout: {
                    actionField: {
                        step: 2
                    },
                    products: lineItems.map(this.trackingLineItemToInternal, this)
                }
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackConfirmationPage(orderId: string, receiptViewModel: ReceiptViewModel) {
        const lineItems = receiptViewModel.lineItems.map(rli => ({
            ...rli,
            stockInfo: null,
            trackingState: ProductTrackingState.Complete
        } as TrackingLineItem));

        const trackingObject: DataLayerEntry = {
            event: 'pageview',
            pageType: 'confirmation',
            pageUrl: this.pageViewUrl,
            ecommerce: {
                currencyCode: this.currencyCodeFromLineItems(lineItems),
                purchase: {
                    actionField: {
                        id: orderId,
                        revenue: receiptViewModel.subTotal.invariantCultureFormatted,
                        tax: receiptViewModel.totalVat.invariantCultureFormatted,
                        shipping: receiptViewModel.deliveryPrice.price.invariantCultureFormatted
                    },
                    products: lineItems.map(this.trackingLineItemToInternal, this)
                }
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackProductDetailsPage(product: TrackingProduct) {
        const trackingObject: DataLayerEntry = {
            event: 'pageview',
            pageType: 'productdetails',
            pageUrl: this.pageViewUrl,
            userTypeTarget: authService.isLoggedIn() ? 'SignedIn' : authService.hasLoggedInBefore() ? 'SignedOut' : 'NewUser',
            ecommerce: {
                currencyCode: this.currencyCodeFromProducts([product]),
                detail: {
                    products: [this.toInternal(product)]
                }
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackProductDetailsTabChange(tabTarget: string): void {
        GTMBasics.pushToDataLayer({
            event: 'tabClick.pdp',
            tabTarget
        });
    }

    public trackCheckoutOptions(...options: any[]) {
        const trackingObject: DataLayerEntry = {
            event: 'ecom.checkoutOption',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 2,
                        option: options.join('|')
                    }
                }
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackLineItemQuantityChange(lineItem: TrackingLineItem, deltaQuantity: number) {
        this.trackProductTile(this.trackingLineItemToInternal(lineItem), deltaQuantity, this.currencyCodeFromLineItems([lineItem]));
    }

    public trackProductQuantityChange(product: TrackingProduct, deltaQuantity: number, listName?: string) {
        this.trackProductTile(this.toInternal(product, deltaQuantity), deltaQuantity, this.currencyCodeFromProducts([product]), listName);
    }

    public trackLineItemsDeleted(lineItems: TrackingLineItem[]) {
        const trackingObject: DataLayerEntry = {
            event: 'ecom.remove',
            ecommerce: {
                currencyCode: this.currencyCodeFromLineItems(lineItems),
                remove: {
                    products: lineItems.map(this.trackingLineItemToInternal, this)
                }
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackImpressions(products: TrackingProduct[]) {
        const trackingObject: DataLayerEntry = {
            event: 'ecom.impressions',
            ecommerce: {
                currencyCode: this.currencyCodeFromProducts(products),
                impressions: products
                    .map(p => this.toInternal(p), this)
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public lineItemClick(lineItem: TrackingLineItem, listName: string) {
        this.doProductClick(this.trackingLineItemToInternal(lineItem), listName, this.currencyCodeFromLineItems([lineItem]));
    }

    public productClick(product: TrackingProduct, listName: string) {
        this.doProductClick(this.toInternal(product), listName, this.currencyCodeFromProducts([product]));
    }

    public trackSearch(searchQuery: string, searchResults: number) {
        const trackingObject: DataLayerEntry = {
            event: 'pageview',
            pageType: this.currentPagePath,
            pageUrl: this.pageViewUrl,
            userTypeTarget: authService.isLoggedIn() ? 'SignedIn' : authService.hasLoggedInBefore() ? 'SignedOut' : 'NewUser',
            searchQuery,
            searchResults: searchResults + ''
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackFacet(facet: FacetViewObject, value: FacetValueViewObject) {
        const trackingObject: DataLayerEntry = {
            event: 'facets.search',
            facetName: facet.facetId + '|' + facet.facetDisplayName,
            facetValue: value.displayName
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackCreativeView(promos: {name: string, id: string, creative?: string}[]) {
        const trackingObject: DataLayerEntry = {
            event: 'ecom.promoView',
            ecommerce: {
                promoView: {
                    promotions: promos
                }
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackCreativeClick(name: string, id: string, creative?: string) {
        const trackingObject: DataLayerEntry = {
            event: 'ecom.promoClick',
            ecommerce: {
                promoClick: {
                    promotions: [{
                        name,
                        id,
                        creative
                    }]
                }
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackLinkClick(url: string) {
        const trackingObject: DataLayerEntry = {
            event: 'click.link',
            url: url
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    public trackLogInToViewPricesClick(): void {
        GTMBasics.pushToDataLayer({
            event: 'button.click',
            buttonTarget: 'logInToViewPrices'
        });
    }

    public trackCreateUserClick(): void {
        GTMBasics.pushToDataLayer({
            event: 'account.addUser'
        });
    }

    public trackCreateUserSuccess(): void {
        GTMBasics.pushToDataLayer({
            event: 'account.userAdded'
        });
    }

    public trackPageRendered({ pageId, pageType }: { pageId?: number | string, pageType?: string | null}): void {
        if (!pageId && !pageType) return;
        if (pageType === 'content' && !pageId) return;

        GTMBasics.pushToDataLayer({
            event: 'optimize.activate',
            pageId,
            pageType
        });
    }

    public trackPageDestroyed({ pageId, pageType }: { pageId?: number | string, pageType?: string | null}): void {
        if (!pageId && !pageType) return;
        if (pageType === 'content' && !pageId) return;

        GTMBasics.pushToDataLayer({
            event: 'optimize.deactivate',
            pageId,
            pageType
        });
    }

    public trackGenericPageView(pageType: string, pageId?: string | number) {
        if (pageType === 'content' && !pageId) return;

        const trackingObject: DataLayerEntry = {
            event: 'pageview',
            pageUrl: this.pageViewUrl,
            pageType: pageType.toLowerCase(),
            pageId: pageId,
            userTypeTarget: authService.isLoggedIn() ? 'SignedIn' : authService.hasLoggedInBefore() ? 'SignedOut' : 'NewUser'
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    private setupPageViewTracking() {
        const runOptimizeEvents = (to: Route, from: Route) => {
            this.trackPageDestroyed({ pageType: from.name });
            Vue.nextTick().then(() => {
                this.trackPageRendered({ pageType: to.name });
            });
        };

        router.afterEach((to: Route, from: Route) => {
            const newPagePath = to.path.toLowerCase();
            if (newPagePath === this.currentPagePath) return;

            runOptimizeEvents(to, from);

            this.currentPagePath = newPagePath;

            if (!(to.meta as RouterMetaData).skipAutoPageTracking) {
                // Delay a bit so window.location is up-to-date
                setTimeout(() => this.trackGenericPageView(to.name!));
            }
        });
    }

    private doProductClick(product: InternalTrackingProduct, listName: string, currencyCode: string) {
        const trackingObject: DataLayerEntry = {
            event: 'ecom.productClick',
            ecommerce: {
                currencyCode,
                click: {
                    actionField: {
                        list: listName.replaceAll(' ', '_')
                    },
                    products: [product]
                }
            }
        };

        GTMBasics.pushToDataLayer(trackingObject);
    }

    private trackProductTile(trackingProduct: InternalTrackingProduct, deltaQuantity: number, currencyCode: string, listName?: string) {
        const trackingObject: DataLayerEntry = {
            event: deltaQuantity > 0 ? 'ecom.add' : 'ecom.remove',
            ecommerce: {
                currencyCode: currencyCode,
                [deltaQuantity > 0 ? 'add' : 'remove']: {
                    products: [{ ...trackingProduct, quantity: Math.abs(deltaQuantity) }]
                }
            }
        };
        if (listName) {
            trackingObject.ecommerce[deltaQuantity > 0 ? 'add' : 'remove'].actionField = { list: listName.replaceAll(' ', '_') };
        }

        GTMBasics.pushToDataLayer(trackingObject);
    }

    private trackingLineItemToInternal(lineItem: TrackingLineItem): InternalTrackingProduct {
        return this.toInternal({
            ...lineItem,
            price: lineItem.unitPrice
        }, lineItem.quantity);
    }

    private toInternal(product: TrackingProduct, quantity: number | undefined = undefined): InternalTrackingProduct {
        const stockLocationType = getStockLocationType(product.stockInfo);
        const result = {
            id: product.itemId,
            name: product.displayName,
            brand: product.brand,
            category: product.categoryId
        } as InternalTrackingProduct;
        if (quantity) {
            result.quantity = quantity;
        }
        if (product.listName) {
            result.list = product.listName.replaceAll(' ', '_');
        }
        if (product.position) {
            result.position = product.position;
        }
        switch (product.trackingState) {
        case ProductTrackingState.Complete:
            result.price = product.price?.invariantCultureFormatted ?? '';
            result.metric1 = result.price;
            result.dimension21 = product?.gppg;
            result.dimension22 = product?.wid;
            result.dimension23 = product?.mpn || product?.mpnNumber;
            if (stockLocationType != null) {
                result.dimension16 = (stockLocationType === StockLocationTypeViewObject.Local);
                result.dimension20 = (stockLocationType === StockLocationTypeViewObject.Remote || stockLocationType === StockLocationTypeViewObject.InComing);
            }
            break;

        case ProductTrackingState.ErpNotAvailable:
            result.dimension19 = 'erpResponseMissing';
            break;

        case ProductTrackingState.NotLoggedIn:
            result.dimension19 = 'noLoginNoPrice';
            break;

        case ProductTrackingState.NoErpResponseYet:
            result.dimension19 = 'erpResponseNotYetReceived';
            break;
        }
        return result;

        function getStockLocationType(stockInfo: readonly StockInformationViewObject[] | null): StockLocationTypeViewObject | null {
            if (!stockInfo) return null;
            if (stockInfo.length === 0) return null;
            // Has been sorted BE wise so always take the first
            return stockInfo[0].locationType;
        }
    }

    private currencyCodeFromLineItems(lineItems: TrackingLineItem[]): string {
        if (lineItems[0].unitPrice) {
            return lineItems[0].unitPrice.currency;
        }

        return '';
    }

    private currencyCodeFromProducts(products: TrackingProduct[]): string {
        return products[0]?.price?.currency ?? '';
    }

    private get pageViewUrl(): string {
        return location.origin + location.pathname + location.search;
    }

    public trackProductGuide(model: ProductGuideTrackingInformation): void {
        const trackingData = generateProductGuideTrackingData(model);

        if (!trackingData) return;
        GTMBasics.pushToDataLayer(trackingData);
    }

    public trackCableGuide(model: CableGuideTrackingInformation): void {
        const trackingData = generateCableGuideTrackingData(model);

        if (!trackingData) return;
        GTMBasics.pushToDataLayer(trackingData);
    }

    public trackNotifyOnStockReplenish(): void {
        GTMBasics.pushToDataLayer({
            event: 'button.click',
            buttonTarget: 'notify me'
        });
    }
}

export default new GTMService();
