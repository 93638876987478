import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    AddProductsToBasketRequest,
    AddQuoteToBasketRequest, AddressRequestObject,
    AddToBasketRequest,
    BasketViewModel,
    RemoveFromBasketRequest,
    UpdateBasketRequest
} from '@/types/apiServerContract';
import { AxiosResponse } from 'axios';

export type PartialUpdateBasketRequest = Partial<UpdateBasketRequest> & { deliveryAddress?: AddressRequestObject & { searchKey?: string; } };
export class BasketController {
    private readonly urlFactory = createUrlFactory('Basket');

    public async get(): Promise<BasketViewModel> {
        return httpService.get(this.urlFactory('Get'));
    }

    // This is the real type - with optional props. The typescript gen. cannot generate it. Alas.
    public async update(request: PartialUpdateBasketRequest | undefined): Promise<BasketViewModel> {
        const requestClean: PartialUpdateBasketRequest = { ...request };
        if (requestClean?.deliveryAddress?.searchKey) {
            delete requestClean.deliveryAddress.searchKey;
        }
        return httpService.post(this.urlFactory('Update'), requestClean || {});
    }

    public async updateDefault(): Promise<BasketViewModel> {
        return httpService.post(this.urlFactory('UpdateDefault'));
    }

    public async clear(): Promise<BasketViewModel> {
        return httpService.post(this.urlFactory('Clear'));
    }

    public async addOrUpdateLineItems(request: AddToBasketRequest): Promise<BasketViewModel> {
        return httpService.post(this.urlFactory('AddOrUpdateLineItems'), request);
    }

    public async removeLineItems(request: RemoveFromBasketRequest): Promise<BasketViewModel> {
        return httpService.post(this.urlFactory('RemoveLineItems'), request);
    }

    public async addMultipleItemsToBasket(request: AddProductsToBasketRequest): Promise<BasketViewModel> {
        return httpService.post(this.urlFactory('AddProducts'), request);
    }

    public async addQuote(request: AddQuoteToBasketRequest): Promise<BasketViewModel> {
        return httpService.post(this.urlFactory('AddQuote'), request, 'add-quote-to-basket');
    }

    public async removeQuote(quoteId: string): Promise<BasketViewModel> {
        return httpService.post(this.urlFactory('RemoveQuote'), quoteId);
    }

    public async addRackConfigurationToBasket(configurationId: string, reference?: string): Promise<BasketViewModel> {
        return httpService.post(this.urlFactory('addrackconfigurationtobasket'), { configurationId, reference });
    }

    public async export() {
        const result = await httpService.post<AxiosResponse>(this.urlFactory('export'),
            undefined,
            undefined,
            undefined,
            {
                responseType: 'blob'
            },
            true);

        const fileName = httpService.getContentDispositionFilename(result);
        const file = result.data as Blob;

        return {
            fileName,
            file
        };
    }
}
