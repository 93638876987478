import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { EnrichAccessRequest, RequestAccessRequest, RequestAccessResponseViewModel } from '@/types/apiServerContract';

export class AccessController {
    private readonly urlFactory = createUrlFactory('Access');

    public async requestAccess(request: RequestAccessRequest, messageId: string, token?: string): Promise<RequestAccessResponseViewModel> {
        const config = token ? { headers: { 'x-eet-validate': token } } : undefined;
        return httpService.post(this.urlFactory('RequestAccess'), request, messageId, undefined, config);
    }

    public async enrichAccess(request: EnrichAccessRequest, messageId: string, token?: string) {
        const config = token ? { headers: { 'x-eet-validate': token } } : undefined;
        return httpService.post(this.urlFactory('enrichaccessrequest'), request, messageId, undefined, config);
    }
}
