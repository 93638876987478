import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { createCancellableGet } from '@/core/http/cancellableGet';
import {
    AddOrUpdateRmaBasketRequest,
    CreateRmaRequest,
    RmaBasketViewModel,
    RmaHeaderViewModel,
    RmaSearchResultsViewModel,
    RmaStatusViewModel,
    RmaViewModel,
    UpdateRmaBasketAddressRequest
} from '@/types/apiServerContract';

export class RmaController {
    private readonly urlFactoryVersion2 = createUrlFactory('rma');

    private cancellableSearchForRMAv2 = createCancellableGet<RmaSearchResultsViewModel>();

    public async getRmaHistory(): Promise<RmaHeaderViewModel> {
        return httpService.get(this.urlFactoryVersion2('GetRmaHistory'));
    }

    public async searchRma(term: string): Promise<RmaSearchResultsViewModel> {
        return this.cancellableSearchForRMAv2(this.urlFactoryVersion2('search'), {
            searchText: term
        });
    }

    public async getRmaReasons(rmaType): Promise<RmaViewModel> {
        return httpService.get(this.urlFactoryVersion2('rmareasons'), { reasonType: rmaType });
    }

    public async getRmaBasket(rmaType): Promise<RmaBasketViewModel> {
        return httpService.get(this.urlFactoryVersion2('rmabasket'), { reasonType: rmaType });
    }

    public async getStatuses(): Promise<RmaStatusViewModel> {
        return httpService.get(this.urlFactoryVersion2('statuses'));
    }

    public async addOrUpdateRmaBasket(request: AddOrUpdateRmaBasketRequest): Promise<RmaBasketViewModel> {
        return httpService.post(this.urlFactoryVersion2('addorupdate'), request);
    }

    public async updateRmaAddressBasket(request: UpdateRmaBasketAddressRequest): Promise<RmaBasketViewModel> {
        return httpService.post(this.urlFactoryVersion2('updateaddress'), request);
    }

    public async deleteRmaBasket(rmaType): Promise<any> {
        return httpService.delete(this.urlFactoryVersion2('delete'), { reasonType: rmaType });
    }

    public async submitRma(request: CreateRmaRequest, messageId?: string): Promise<void> {
        return httpService.post(this.urlFactoryVersion2('create'), request, messageId);
    }
}
