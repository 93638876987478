import Api from '@/project/http/Api.service';
import AbstractBulkFetch from '@/core/bulk-fetch/AbstractBulkFetch';
import { PriceSimpleViewObject } from '@/types/apiServerContract';
import { keyBy } from 'lodash';

export interface PriceSimpleDataExtended extends PriceSimpleViewObject {
    isServiceAvailable: boolean;
}

class SimplePriceFetchService extends AbstractBulkFetch<PriceSimpleDataExtended> {
    async apiCall(itemIds: string[], cancelKey: string): Promise<{ [key: string]: PriceSimpleDataExtended }> {
        const result = await Api.price.priceSimple(itemIds, cancelKey);
        const prices = result.prices.map(price => ({
            ...price,
            isServiceAvailable: result.isServiceAvailable
        }) as PriceSimpleDataExtended);
        return keyBy<PriceSimpleDataExtended>(prices, 'itemId');
    }
}

export default new SimplePriceFetchService('SimplePrice', 100, true);
