import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { ShowNextDeliveryViewModel } from '@/types/apiServerContract';

export class NextDeliveryController {
    private readonly urlFactory = createUrlFactory('nextdelivery');

    public async getNextDelivery(itemIds: string[]): Promise<ShowNextDeliveryViewModel> {
        return httpService.get(this.urlFactory('shownextdelivery'), {
            itemIds
        });
    }
}
