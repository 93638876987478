import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { CategoryNamesViewModel, LinkItem, MegaMenuViewModel } from '@/types/apiServerContract';

export class NavigationController {
    private readonly urlFactory = createUrlFactory('Navigation');

    public async megaMenu(): Promise<MegaMenuViewModel> {
        return httpService.get<MegaMenuViewModel>(this.urlFactory('MegaMenu'));
    }

    public async category(categoryId: string): Promise<LinkItem> {
        return httpService.get<LinkItem>(this.urlFactory('CategoryName'), { categoryId });
    }

    public async categories(categoryIds: string[]): Promise<CategoryNamesViewModel> {
        return httpService.get<CategoryNamesViewModel>(this.urlFactory('CategoryNames'), { categoryIds });
    }
}
