




import { Vue, Component, Prop } from 'vue-property-decorator';
import { BaseContentModel } from '@/types/contentServerContract';

@Component
export default class ContentPage extends Vue {
    @Prop({ required: true, type: Object }) content!: BaseContentModel;
}
