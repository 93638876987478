import { AbstractModalService } from '@/core/modals/AbstractModalService';

class MobileMenuOverlayService extends AbstractModalService {
    public show() {
        return this.showModal({
            component: () => import(
                /* webpackPrefetch: true */
                '@/project/layout/page-header/mobile/MobileMenu.vue'),
            componentAttrs: {
            },
            options: {
                showCloseButton: false,
                contentMaxHeight: false,
                disableBodyScroll: true,
                usePanelWrapper: false,
                placement: 'right'
            }
        });
    }
}

export default new MobileMenuOverlayService();
