

























import { Vue, Prop, Component } from 'vue-property-decorator';
import { ProductTileViewObject } from '@/types/apiServerContract';
import CopyText from '@/project/shared/CopyText.vue';
import serverContextService from '@/core/serverContext.service';

@Component({ components: { CopyText } })
export default class ProductTileInfo extends Vue {
    @Prop({ required: true }) product!: ProductTileViewObject;

    get itemId(): string | null {
        if (serverContextService.isExternalProductGuide) return null;
        return (this.product.mpn !== this.product.itemId && this.product.itemId) || null;
    }

    get mpn(): string | null {
        return this.product.mpn || null;
    }
}
