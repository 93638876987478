












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { JsonContent } from '@/types/contentServerContract';

@Component
export default class BlockList extends Vue {
    @Prop() blocks!: JsonContent[];

    randomIntFromInterval(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    blockKey(alias: string, ix: number): string {
        return `${alias}-${ix}-${this.randomIntFromInterval(1, 10000)}`;
    }

    @Prop({ type: Boolean, default: true })
    enableContainer!: boolean;

    @Prop({ type: Boolean, default: false })
    disableContainerForFirstBlock!: boolean;

    @Watch('blocks', {
        immediate: true
    })
    setupBlockKeys() {
        if (this.blocks?.length) {
            this.blocks.forEach((x, i) => (x as any).key = this.blockKey(x.alias, i));
        }
    }
}
