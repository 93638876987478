import Vue from 'vue';
import SvgIcon from 'vue-svgicon'; // We are using vue-svgicon to generate icons as components  - https://github.com/MMF-FE/vue-svgicon
import cIcon from '@/core/responsive/image/cIcon.vue';
Vue.component('CIcon', cIcon);

// Default tag name is 'svgicon'
Vue.use(SvgIcon, {
    tagName: 'd-icon',
    classPrefix: 'c-icon'
});
