import { PageName } from '@/router/routes/constants';
import { MenuItem } from '@/types/apiServerContract';

/*
/campaigns/CAMPAIGN (clearance, etc)
*/
function getCampaignCodeFromUrl(url: string): string | null {
    const regMatcher = matchingCampaignRegEx();

    const match = url.match(regMatcher);
    if (match && match.length === 2) {
        return match[1];
    }
    return null;
}

function matchingCampaignRegEx(): RegExp {
    // Create new one every time - RegEx is stateful.
    const campaignRegex = /campaigns\/(\w+).*/i;
    return new RegExp(campaignRegex);
}

function fixupCampaignUrls(menus: MenuItem[], campaignCode: string): void {
    for (const menu of menus) {
        menu.url = '/' + PageName.CAMPAIGN + '/' + campaignCode + '/' + menu.url;
        fixupCampaignUrls(menu.children, campaignCode);
    }
}
// After new infrastructure project, we had SSL issues on cms-media.eetgroup.com, but same content exists on content.eetgroup.com
function rewriteCampaignIconUrl(campaignIconUrl: string = ''): string {
    return campaignIconUrl.replace('cms-media.eetgroup.com/media', 'content.eetgroup.com/media');
}

export default {
    getCampaignCodeFromUrl,
    rewriteCampaignIconUrl,
    fixupCampaignUrls,
    matchingCampaignRegEx
};
