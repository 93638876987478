import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { CategorySeoInformationViewModel } from '@/types/apiServerContract';
export class CategoryController {
    private readonly urlFactory = createUrlFactory('Category');

    public async getCategorySeoInformation(categoryId: string): Promise<CategorySeoInformationViewModel> {
        return httpService.get(this.urlFactory('GetCategorySeoInformation'), { categoryId: categoryId });
    }
}
