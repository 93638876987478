export interface CookieSetStructure {
    name: string,
    value?: string,
    exdays?: number,
    path?: string,
    domain?: string
}

function set(options: CookieSetStructure): void {
    options.value = options.value || '';
    options.path = options.path || '/';

    const domain = options.domain ? 'domain=' + options.domain + ';' : '';
    const path = 'path=' + options.path;
    let expires = '';

    // create the expiration date
    if (options.exdays) {
        var d = new Date();

        d.setTime(d.getTime() + (options.exdays * 24 * 60 * 60 * 1000));
        expires = 'expires=' + d.toUTCString();
    }

    document.cookie = options.name + '=' + options.value + ';' + domain + path + ';' + expires;
}

function get(cname: string): string | undefined {
    const name = cname + '=';
    const cookies = document.cookie.split(';').map(c => c.trim());
    const cookie = cookies.find(c => c.startsWith(cname));
    return cookie ? cookie.substring(name.length, cookie.length) : undefined;
}

function del(name: string): void {
    set({
        name,
        exdays: -1
    });
}

export default {
    set,
    get,
    del
};
