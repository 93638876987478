import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    CreateProductGuideOwnershipViewModel,
    ProductGuideConfigurationViewModel,
    ProductGuideOwnershipViewModel,
    ProductGuideOwnershipViewObject,
    SaveExternalGuideRequest
} from '@/types/apiServerContract';
import serverContext from '@/core/serverContext.service';

export class ProductGuideConfigurationController {
    private readonly urlFactory = createUrlFactory('ProductGuide');

    public async saveExternalGuideOwnership(payload: SaveExternalGuideRequest, messageId: string):Promise<CreateProductGuideOwnershipViewModel> {
        return httpService.post(this.urlFactory('SaveExternalGuideOwnership'), payload, messageId);
    }

    public async getProductGuideConfigurationById(id: string):Promise<ProductGuideConfigurationViewModel> {
        return httpService.get(this.urlFactory('ProductGuideConfiguration'), { externalId: id });
    }

    public async getProductGuideConfigurationForEditById(id: string):Promise<ProductGuideOwnershipViewObject> {
        return httpService.get(this.urlFactory('get'), { externalId: id });
    }

    public async getProductGuideConfigurations():Promise<ProductGuideOwnershipViewModel> {
        return httpService.get(this.urlFactory('List'));
    }

    public async deleteProductGuide(id: string):Promise<CreateProductGuideOwnershipViewModel> {
        return httpService.delete(this.urlFactory('Delete'), { externalId: id });
    }

    public async getProductGuideTexts<T>():Promise<T> {
        return httpService.getRaw(`${serverContext.contentHost}/umbraco/api/ProductGuideSettings/get?culture=${serverContext.siteInfo.culture}&siteName=${serverContext.siteName}`);
    }
}
