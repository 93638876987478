const passwordValidator = {
    validate(value: string) {
        if (!value) return false;
        if (!hasLength(value)) return false;
        if (!hasLowercase(value)) return false;
        if (!hasUppercase(value)) return false;
        if (!hasNumeric(value)) return false;
        if (!hasSpecialChar(value)) return false;
        return true;
    }
};

function hasLength(value: string): boolean {
    return value.length >= 8;
}

function hasUppercase(value: string): boolean {
    return !!(value.match(/[A-Z]/)?.length) ?? false;
}

function hasLowercase(value: string): boolean {
    return !!(value.match(/[a-z]/)?.length) ?? false;
}

function hasNumeric(value: string): boolean {
    return !!(value.match(/\d/)?.length) ?? false;
}

function hasSpecialChar(value: string): boolean {
    return !!(value.match(/[#$%^¤&£*\-_+=[\]{}|\\:',?/`~""();!.(?@)]/)?.length) ?? false;
}

export default {
    passwordValidator,
    hasLength,
    hasLowercase,
    hasUppercase,
    hasNumeric,
    hasSpecialChar
};
