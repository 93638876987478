
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import dictionaryService from '@/core/translation/dictionary.service';

@Component
export default class InputTextArea extends Vue {
    @Prop({ required: true })
    value!: string | null;

    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: false })
    autofocus!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    required!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    showMaxCounter!: boolean;

    @Prop({ type: Number, required: false, default: 5 })
    rows!: number;

    @Prop({ type: String, required: false, default: 'resize-none' })
    inputClasses!: string;

    get id() {
        return this.name + Math.random();
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }

    get hasValue(): boolean {
        return this.value !== '' && this.value != null;
    }

    get labelText(): string {
        return this.isRequired ? `${this.label} *` : this.label;
    }

    get isRequired(): boolean {
        return 'required' in this.$attrs ||
            (typeof this.rules === 'string' && this.rules.includes('required')) ||
            (typeof this.rules === 'object' && !!(this.rules as any).required);
    }

    changedInput(event) {
        this.$emit('input', event.target.value);
    }

    get maxCounter(): null | string {
        if (this.showMaxCounter && this.rules && Object.keys(this.rules).includes('max')) {
            // @ts-ignore
            const max = this.rules.max as number;
            const valueLength = this.value?.length ?? 0;
            return dictionaryService.get('Validation.TextAreaCharsLeftCounter', [(max - valueLength).toString()]);
        }
        return null;
    }
}
