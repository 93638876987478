import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    LoginRequest,
    LoginViewModel,
    RequestResetPasswordRequest,
    ResetPasswordRequest,
    ChangePasswordRequest,
    ValidateResetPasswordTokenViewModel
} from '@/types/apiServerContract';

export class AuthenticationController {
    private readonly urlFactory = createUrlFactory('Authentication');

    public async login(loginData: LoginRequest, messagesId: string): Promise<LoginViewModel> {
        return httpService.post(this.urlFactory('Login'), loginData, messagesId);
    }

    public async requestPasswordReset(email: string, messagesId: string): Promise<void> {
        return httpService.post(this.urlFactory('RequestPasswordReset'), {
            email
        } as RequestResetPasswordRequest, messagesId);
    }

    public async resetPassword(password: string, token: string, messagesId: string): Promise<void> {
        return httpService.post(this.urlFactory('ResetPassword'), {
            password,
            token
        } as ResetPasswordRequest, messagesId);
    }

    public async validateResetPasswordToken(token: string): Promise<ValidateResetPasswordTokenViewModel> {
        return httpService.get(this.urlFactory('validateresetpasswordtoken'), {
            token
        });
    }

    public async changePassword(request: ChangePasswordRequest, messagesId: string) {
        return httpService.post(this.urlFactory('ChangePassword'), request, messagesId);
    }
}
