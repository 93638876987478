














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class MinusCancel extends Vue {
    @Prop({
        required: true,
        type: String,
        default: 'minus',
        validator: value => ['minus', 'cancel'].includes(value as string)
    })
    mode;
}
