










import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { BrandLogoViewModel, RequestBreakpoint } from '@/types/apiServerContract';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import { XS, SM, MD } from '@/core/config/breakpointConfig';
import BrandLogo from './BrandLogo.vue';
import Api from '@/project/http/Api.service';
import adnuntiusService from '@/project/personalization/adnuntius.service';
import themeService from '@/project/app/themes/theme.service';
import { hexToRgbValues } from '@/core/color/color.service';

@Component({
    components: { BrandLogo }
})
export default class BrandHeader extends Vue {
    @Prop({
        type: Object
    }) content!: BrandLogoViewModel | null;

    brandLogos: BrandLogoViewModel[] = [];

    created() {
        this.getBrandLogos(this.activeBreakpoint);
    }

    async getBrandLogos(breakpoint: RequestBreakpoint) {
        this.brandLogos = await Api.personalization.brandLogos(
            breakpoint,
            this.$route.fullPath,
            adnuntiusService.getContactKey(),
            adnuntiusService.getCustomerKey());
    }

    get activeBreakpoint() : RequestBreakpoint {
        let adBreakpoint;
        const breakpoint = breakpointsState.activeBreakpoint;

        if (breakpoint === XS || breakpoint === SM) {
            adBreakpoint = RequestBreakpoint.Mobile;
        } else if (breakpoint === MD) {
            adBreakpoint = RequestBreakpoint.Tablet;
        } else {
            adBreakpoint = RequestBreakpoint.Desktop;
        }

        return adBreakpoint;
    }

    @Watch('activeBreakpoint')
    onActiveBreakpointChange(value: RequestBreakpoint) {
        this.getBrandLogos(value);
    }

    @Watch('$route')
    onRouteChange(newRoute: Route, oldRoute: Route) {
        if (newRoute.path !== oldRoute.path) {
            this.getBrandLogos(this.activeBreakpoint);
        }
    }

    get topHeaderStyle(): Partial<CSSStyleDeclaration> {
        const rgb = themeService.theme?.topColor && hexToRgbValues(themeService.theme.topColor);

        return {
            ...(rgb && { backgroundColor: `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, ${themeService.takeOverOpacity})` }),
            ...(themeService.theme?.topColor && { borderColor: themeService.theme.topColor })
        };
    }
}
