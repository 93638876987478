import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import bus from '@/core/bus';
import { Position, PositionResult } from 'vue-router/types/router';
import { Ensure } from './Ensure';
import { routes } from './routes';
import { HasOutstandingXhrCallsEventKey } from '@/core/http/constants';
import scrollService from '@/core/scroll/scroll.service';
import Deferred from '@/core/async/Deferred';
import { PageRenderedEventKey } from '@/router/routes/constants';

let hasOutstandingXHRRequests = false;
let clearOutstandingXHRRequestsTimerHandle = 0;
let ensurePageReadyForScroll: Ensure | null = null;
const hashOffset = -60;

Vue.use(VueRouter);

export const router = new VueRouter({
    base: window.servercontext?.culture,
    routes: routes,
    mode: 'history',
    async scrollBehavior(to: Route, from: Route, savedPosition: Position | void) {
        const positionResultDeferred: Deferred<PositionResult> = new Deferred<PositionResult>();

        // First set scrollresult for waiting scenarios
        const positionResult = await setScrollPositionResult(to, from, savedPosition);
        positionResultDeferred.resolve(positionResult);

        // Await pending XHR request and report whole page is ready (so e.g. footer can be drawn (WebVitals CLS))
        try {
            await pageRendered();
        } finally {
            bus.emit(PageRenderedEventKey);
        }

        return positionResultDeferred.promise;
    }
});

router.onReady(() => {
    preventAutoScroll();

    bus.on(HasOutstandingXhrCallsEventKey, (hasOutstanding: boolean) => {
        if (hasOutstanding) {
            clearTimeout(clearOutstandingXHRRequestsTimerHandle);
            hasOutstandingXHRRequests = true;
        } else {
            // Allow for rendering of data returned from call
            clearOutstandingXHRRequestsTimerHandle = setTimeout(() => hasOutstandingXHRRequests = false, 25);
        }
    });

    // Potential initial scrolling to hash
    navigateToRouteHash();
});

async function setScrollPositionResult(to: Route, from: Route, savedPosition: Position | void): Promise<PositionResult> {
    // Make sure an unfinished waiting to scroll is canceled first.
    cleanupEnsures();

    if (to.path === from.path && (!to.hash || to.hash === from.hash)) {
        // Only query or params changed - don't scroll
        return Promise.resolve();
    }

    if (!savedPosition && !to.hash) {
        // New page with no hash - scroll to top
        return Promise.resolve({ x: 0, y: 0 });
    }

    // We have a saved position or a hash. Page is now loaded but components may not be rendered
    try {
        await pageRendered();
        if (to.hash) {
            return Promise.resolve({ selector: to.hash, behavior: 'smooth', offset: { x: 0, y: -1 * hashOffset } });
        } else {
            return Promise.resolve(savedPosition);
        }
    } catch (e) {
        // Timeout or cancelled - scroll to top
        return Promise.resolve({
            x: 0,
            y: 0
        });
    }

    function cleanupEnsures() {
        if (ensurePageReadyForScroll) {
            ensurePageReadyForScroll.cancel();
            ensurePageReadyForScroll = null;
        }
    }
}

function preventAutoScroll() {
    if ('scrollRestoration' in history) {
        try {
            history.scrollRestoration = 'manual';
        } catch (e) {
            // Ignore
        }
    }
}

async function pageRendered() {
    const deferred = new Deferred();
    try {
        ensurePageReadyForScroll = new Ensure(() => !hasOutstandingXHRRequests, -1);
        await ensurePageReadyForScroll.start();
        Vue.nextTick(() => deferred.resolve());
    } catch {
        deferred.reject();
    }
    return deferred.promise;
}

async function navigateToRouteHash() {
    const hash = window.location.hash;
    if (hash) {
        try {
            const elementId = hash.slice(1);
            const hashElementCheckerEnsure = new Ensure(() => {
                return !!document.getElementById(elementId);
            });
            await hashElementCheckerEnsure.start();
            await pageRendered();
            const hashElement = document.getElementById(elementId);
            scrollService.scrollToElement(hashElement!, hashOffset);
        } catch (e) {
            // No hash elements found, or we changed page before it appeared in dom
        }
    }
}
