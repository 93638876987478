










































import { Vue, Component, Prop } from 'vue-property-decorator';
import { MenuItem } from '@/types/apiServerContract';
import SimpleSlider from '@/core/ui-components/SimpleSlider.vue';
import serverContextService from '@/core/serverContext.service';
import CampaignIcon from '@/project/product/campaigns/CampaignIcon.vue';
import themeService from '@/project/app/themes/theme.service';

@Component({
    components: { SimpleSlider, CampaignIcon }
})
export default class MainNavigationSlider extends Vue {
    @Prop({ type: Array, required: true })
    menus!: MenuItem[];

    @Prop({ type: Boolean, required: true })
    megaMenuShown!: boolean;

    @Prop({ type: Object, required: false })
    activeMenu!: MenuItem;

    mouseEnter(menu: MenuItem) {
        this.$emit('activeMenuChanged', menu);
    }

    mouseLeave(menu: MenuItem) {
        this.$emit('cancelMegaMenuNavigation', menu);
    }

    get campaignCode() {
        return serverContextService?.settings?.menuCampaignIcon;
    }

    get campaignLink() {
        return serverContextService?.settings?.menuCampaignLink;
    }

    get prevNavSliderStyle(): null | Partial<CSSStyleDeclaration> {
        if (themeService.theme?.menuColor) {
            const backgroundGradient = `linear-gradient(90deg, ${themeService.theme.menuColor} 0%, ${themeService.theme.menuColor}00 100%)`;
            return {
                background: backgroundGradient,
                color: themeService.getColor(themeService.theme.menuColor)
            };
        }
        return null;
    }

    get nextNavSliderStyle(): null | Partial<CSSStyleDeclaration> {
        if (themeService.theme?.menuColor) {
            const backgroundGradient = `linear-gradient(90deg, ${themeService.theme.menuColor}00 0%, ${themeService.theme.menuColor} 100%)`;
            return {
                background: backgroundGradient,
                color: themeService.getColor(themeService.theme.menuColor)
            };
        }
        return null;
    }
}
