import Vue from 'vue';
import tokenService from './token.service';
import makeStoreDebugAccessible from '@/core/store/storeDebug';
import bus from '@/core/bus';
import GTMBasics from '@/project/tracking/gtm/GTM.basics';
import myAccountStore from '@/project/app/client-pages/my-account/my-account.store';
import { router } from '@/router/index';

export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OFF = 'LOGGED_OFF';
export const TOKEN_DATA_CHANGED = 'UserDataChanged';
export const TOKEN_DATA_CHANGED_MARKET = 'UserDataChangedMarket';

const _tokenWrapper: { token: string | null } = Vue.observable({ token: tokenService.getToken() });

makeStoreDebugAccessible('auth-service', _tokenWrapper);

function logIn(token: string): void {
    setToken(token);
    bus.emit(LOGGED_IN);
    myAccountStore.clear();
    myAccountStore.init();
    GTMBasics.trackUserLogin();
}

function logOff(reload: boolean = false): void {
    clearToken();
    bus.emit(LOGGED_OFF);
    myAccountStore.clear();
    if (reload) {
        router.push('/').catch(() => {}).finally(() => location.reload());
    }
}

function isLoggedIn(): boolean {
    return !!getToken();
}

function hasLoggedInBefore(): boolean {
    return !!tokenService.getPreviousToken();
}

function clearToken(): void {
    _tokenWrapper.token = null;
    tokenService.clearToken();
}

function replaceToken(token: string) {
    tokenService.setToken(token);
}

function setToken(token: string): void {
    const prevUserData = tokenService.getUserData();

    _tokenWrapper.token = token;
    tokenService.setToken(token);
    const userData = tokenService.getUserData();
    bus.emit(TOKEN_DATA_CHANGED, userData);

    if (!(prevUserData?.impersonatorEmail) && prevUserData?.contactKey !== userData?.contactKey) {
        myAccountStore.clear();
        myAccountStore.init();
        bus.emit(TOKEN_DATA_CHANGED_MARKET, userData);
    }
}

function getToken(): string | null {
    return _tokenWrapper.token;
}

export default {
    logIn,
    logOff,
    isLoggedIn,
    hasLoggedInBefore,
    setToken,
    getToken,
    replaceToken
};
