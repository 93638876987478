



























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import appStore from '@/core/app/app.store';
import { BreadcrumbItem } from '@/types/contentServerContract';

@Component
export default class Breadcrumb extends Vue {
    get breadcrumbs(): BreadcrumbItem[] {
        const crumbs = appStore?.breadcrumbPrettified ? [...appStore.breadcrumbPrettified] : [];
        return crumbs;
    }
}
