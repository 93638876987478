import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { EmployeeViewModel } from '@/types/apiServerContract';

export class EmployeeController {
    private readonly urlFactory = createUrlFactory('Employee');

    public async getEmployees(employeeNumbers: string[]): Promise<EmployeeViewModel> {
        return httpService.get(this.urlFactory('Get'), {
            ids: employeeNumbers
        });
    }
}
